import flowers from "assets/flowers.png";
import constants from "configs/constants";
import Login from "pages/auth/Login";
import { useNavigate } from "react-router-dom";

interface Props {
  reference?: React.RefObject<HTMLDivElement>;
}

const register = [
  {
    id: "1",
    label: "أنا شاب وأرغب في التسجيل",
    state: { gender: "male" },
    color: "btn-primary",
  },
  {
    id: "2",
    label: "أنا فتاة وأرغب في التسجيل",
    state: { gender: "female" },
    color: "btn-secondary",
  },
  {
    id: "3",
    label: "أنا وسيط وأرغب في التسجيل",
    state: { gender: "male|female", is_guardian: true },
    color: "btn-accent",
  },
];

const Auth = ({ reference }: Props) => {
  const navigate = useNavigate();

  return (
    <section ref={reference} className="mt-8 flex overflow-hidden">
      <img className="-mr-48 h-96 w-96 max-lg:absolute" src={flowers} />

      <div className="flex w-full flex-col lg:flex-row">
        {/* login section */}
        <div className="mx-auto w-[90%] md:max-w-[60%] lg:mx-20 lg:flex-grow-0">
          <p className="text-center text-3xl font-bold text-primary lg:mr-5 lg:text-right">
            {constants.LOGIN}
          </p>

          <div className="divider divider-vertical mx-auto my-0 w-20 before:bg-primary after:bg-primary lg:mx-5"></div>

          <Login isLandingPage />
        </div>

        <div className="divider divider-horizontal mx-6"></div>

        {/* register section */}
        <div className="mx-auto w-[90%] md:max-w-[60%] lg:mx-0 lg:flex-grow">
          <p className="text-center text-3xl font-bold text-primary max-lg:mt-10 lg:mr-5 lg:text-right">
            {constants.SING_FOR_FREE}
          </p>

          <div className="divider divider-vertical mx-auto my-0 w-20 before:bg-primary after:bg-primary lg:mx-5"></div>

          <div className="flex flex-col pr-4 lg:px-20 lg:pt-10">
            <div className="w-full">
              {register.map(({ state, label, id, color }) => {
                return (
                  <button
                    key={id}
                    onClick={() => navigate("/register", { state })}
                    className={`btn my-2 w-full rounded-full text-base font-normal text-white md:text-xl ${color}`}
                  >
                    <p className="flex items-center gap-2">{label}</p>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Auth;
