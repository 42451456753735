import useAuth from "hooks/useAuth";
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Main from "./layouts/Main";
import Public from "./layouts/Public";

import { wrapCreateBrowserRouter } from "@sentry/react";
import { setGlobalRouter } from "hooks/useGlobalNavigate";
import Error from "pages/404";
import ChatsPage from "pages/Chats";
import ThreadPage from "pages/Thread";
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import RegisterByToken from "pages/auth/RegisterByToken";
import ResetPassword from "pages/auth/Reset";
import BuildFinished from "pages/auth/build/BuildFinished";
import BuildPreferences from "pages/auth/build/Preferences";
import BuildProfile from "pages/auth/build/Profile";
import BuildError from "pages/auth/build/error";
import AuthError from "pages/auth/error";
import EngagementsPage from "pages/engage/Engagements";
import EngagedPage from "pages/engage/engaged";
import EngagedContainer from "pages/engage/engaged/Container";
import EngageError from "pages/engage/error";
import Payments from "pages/engage/payments";
import GuardianPage from "pages/guardian";
import GuardianContainer from "pages/guardian/Container";
import GuardianError from "pages/guardian/error";
import Home from "pages/home";
import LastUsersPage from "pages/home/LastUsers";
import ProposalUsersPage from "pages/home/ProposalUsers";
import Landing from "pages/landing";
import LandingError from "pages/landing/error";
import GuestSearchPage from "pages/landing/search";
import GuestProfilePage from "pages/landing/search/Profile";
import GuestSearchResult from "pages/landing/search/result";
import Pending from "pages/pending";
import PendingContainer from "pages/pending/Container";
import PendingError from "pages/pending/error";
import ProfilePage from "pages/profile";
import About from "pages/public/About";
import Contact from "pages/public/Contact";
import Guide from "pages/public/Guide";
import Privacy from "pages/public/Privacy";
import Terms from "pages/public/Terms";
import PublicError from "pages/public/error";
import SearchPage from "pages/search";
import SearchResult from "pages/search/result";
import SupportPage from "pages/support";
import BlockedPage from "pages/users/Blocks";
import FavoritesPage from "pages/users/Favorites";
import FormePage from "pages/users/Forme";
import LikesPage from "pages/users/Likes";
import MatchedPage from "pages/users/Matched";
import VisitsPage from "pages/users/Visits";
import EditGeneral from "pages/users/edit/General";
import EditPassword from "pages/users/edit/Password";
import EditPreferences from "pages/users/edit/Preferences";
import EditProfile from "pages/users/edit/Profile";
import storage from "utils/storage";

const Router = () => {
  const { isAuth } = useAuth();

  // global routes
  const routes: RouteObject[] = [
    { path: "contacts", element: <Contact />, errorElement: <PublicError /> },
    { path: "privacy", element: <Privacy />, errorElement: <PublicError /> },
    { path: "terms", element: <Terms />, errorElement: <PublicError /> },
    { path: "about", element: <About />, errorElement: <PublicError /> },
    { path: "guide", element: <Guide />, errorElement: <PublicError /> },

    { path: "login", element: <Login />, errorElement: <AuthError /> },
    {
      path: "register/token",
      element: <RegisterByToken />,
      errorElement: <AuthError />,
    },
    { path: "reset", element: <ResetPassword />, errorElement: <AuthError /> },
    {
      path: "build/profile",
      element: <BuildProfile />,
      errorElement: <BuildError />,
      loader: () => JSON.parse(storage.get("build") as string),
    },
    {
      path: "build/preferences",
      element: <BuildPreferences />,
      errorElement: <BuildError />,
      loader: () => JSON.parse(storage.get("build") as string),
    },
    {
      path: "build/finished",
      element: <BuildFinished />,
      errorElement: <BuildError />,
      loader: () => JSON.parse(storage.get("build") as string),
    },
    {
      path: "register",
      element: <Register />,
      errorElement: <AuthError />,
    },
    {
      path: "engaged",
      element: <EngagedContainer />,
      errorElement: <EngageError />,
      children: [
        { index: true, element: <EngagedPage /> },
        { path: "support", element: <SupportPage /> },
        { path: "profile/:uid", element: <ProfilePage /> },
        { path: "thread/:receiverId/:chatId", element: <ThreadPage /> },
      ],
    },
    {
      path: "/pay",
      element: <Payments />,
      errorElement: <EngageError />,
    },
    {
      path: "pending",
      element: <PendingContainer />,
      errorElement: <PendingError />,
      children: [{ index: true, element: <Pending /> }],
    },
    {
      path: "guardian",
      element: <GuardianContainer />,
      errorElement: <GuardianError />,
      children: [
        { index: true, element: <GuardianPage /> },
        { path: "edit/password", element: <EditPassword /> },
        { path: "support", element: <SupportPage /> },
      ],
    },
  ];

  // landing when not auth
  if (!isAuth) {
    routes.push({
      path: "/",
      element: <Public />,
      errorElement: <LandingError />,
      children: [
        { index: true, element: <Landing /> },
        { path: "guest/search", element: <GuestSearchPage /> },
        { path: "guest/users/:uid", element: <GuestProfilePage /> },
        { path: "guest/search/result", element: <GuestSearchResult /> },
      ],
    });
  }

  // main app
  routes.push({
    path: "/",
    element: <Main />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Home /> },

      { path: "search", element: <SearchPage /> },
      { path: "search/result", element: <SearchResult /> },

      { path: ":section?/profile/:uid", element: <ProfilePage /> },
      { path: "chats", element: <ChatsPage /> },
      { path: "thread/:receiverId/:chatId", element: <ThreadPage /> },

      { path: "visits", element: <VisitsPage /> },
      { path: "likes", element: <LikesPage /> },
      { path: "engagements", element: <EngagementsPage /> },
      { path: "favorites", element: <FavoritesPage /> },
      { path: "blocks", element: <BlockedPage /> },

      { path: "matched", element: <MatchedPage /> },
      { path: "forme", element: <FormePage /> },

      { path: "edit/general", element: <EditGeneral /> },
      { path: "edit/password", element: <EditPassword /> },
      { path: "edit/profile", element: <EditProfile /> },
      { path: "edit/preferences", element: <EditPreferences /> },

      { path: "support", element: <SupportPage /> },

      // { path: "users/new", element: <NewUsersPage /> },
      { path: "users/last", element: <LastUsersPage /> },
      { path: "users/proposal", element: <ProposalUsersPage /> },
    ],
  });

  const router = wrapCreateBrowserRouter(createBrowserRouter)(routes);
  setGlobalRouter(router);

  return <RouterProvider router={router} />;
};

export default Router;
