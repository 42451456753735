import Button from "components/core/Button";
import SelectPicker from "components/picker/Select";
import RangSlider from "components/slider/Rang";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import { PropsType } from "pages/search/props";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const genders = [
  { label: "ابحث عن زوج", value: "male" },
  { label: "ابحث عن زوجة", value: "female" },
];

const Search = () => {
  const navigate = useNavigate();
  const { options, countries } = useStorage();
  const [gender, setGender] = useState<"male" | "female">("female");
  const nationality_options = options.nationality_options[gender];
  const [data, setData] = useState<PropsType>({});
  const [age, setAge] = useState<number[]>();

  const countrySelected = (v: string[]) => {
    setData({ ...data, country: v, city: null });
  };

  const onSubmit = () => {
    const state: any = { ...data, gender };
    for (const key in state) {
      if (state[key] === null) delete state[key];
    }
    if (age && age.length) (state as any).age = (age as any).join("_");
    navigate("/guest/search/result?page=1", { state });
  };

  return (
    <section className="mt-12 flex items-center">
      <div className="w-full">
        <p className="text-center text-3xl font-bold text-primary">
          {constants.SEARCH}
        </p>

        <div className="divider divider-vertical mx-auto my-0 w-14 before:bg-primary after:bg-primary"></div>

        <div className="pt-5 max-md:px-5 md:mx-auto md:w-[60%]">
          <div className="relative z-10 flex flex-col gap-5 rounded-xl border-2 bg-base-100 p-4 pt-8 opacity-90 md:px-12">
            <div className="flex w-full flex-row flex-wrap justify-between gap-5 gap-y-10 max-md:flex-col">
              <div className="w-[45%] max-sm:w-full">
                <SelectPicker
                  withSearch
                  options={genders.map((e) => e.label)}
                  label={constants.ACCOUNT_TYPE}
                  value={genders.find((e) => e.value === gender)!.label}
                  error={false}
                  setValue={(v) =>
                    setGender(genders.find((e) => e.label === v)?.value as any)
                  }
                />
              </div>
              <div className="w-[45%] max-sm:w-full">
                <SelectPicker
                  withSearch
                  options={nationality_options}
                  label={constants.NATIONALITY}
                  value={data.nationality || null}
                  error={false}
                  setValue={(v) => {
                    setData({ ...data, nationality: v as string[] });
                  }}
                />
              </div>

              <div className="w-[45%] max-sm:w-full">
                <SelectPicker
                  withSearch
                  options={countries.map((e) => e.label)}
                  label={constants.COUNTRY}
                  value={data.country || null}
                  error={false}
                  setValue={(v) => countrySelected(v as string[])}
                />
              </div>

              <div className="mt-2 w-[48%] self-center max-sm:w-[90%]">
                <RangSlider category="age" value={age} setValue={setAge} />
              </div>
            </div>

            <div className="flex justify-center gap-x-3">
              <Button
                size="btn-sm"
                className="mt-8 h-9 w-28 min-w-fit"
                color="btn-primary"
                onClick={onSubmit}
              >
                {constants.SEARCH}
              </Button>

              <Button
                size="btn-sm"
                className="mt-8 h-9 w-28 min-w-fit"
                color="btn-accent"
                onClick={() => navigate("/guest/search")}
              >
                {constants.ADVANCE_SEARCH}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Search;
