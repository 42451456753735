import useAuth from "hooks/useAuth";
import useStorage from "hooks/useStorage";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import married from "../assets/flower.jpg";
import LandingNavbar from "pages/landing/Navbar";

interface Props {
  children: ReactNode;
}

const Auth = ({ children }: Props) => {
  const { isAuth } = useAuth();
  const { build } = useStorage();

  if (build) return <Navigate to={`${build?.route}`} state={build} />;
  if (isAuth) return <Navigate to="/" />;

  return (
    <div className="h-screen overflow-hidden">
      <LandingNavbar />
      <div className="relative mx-auto h-screen rounded-3xl border-gray-200 bg-white md:mt-[5vh] md:h-[90vh] md:w-[60%] md:overflow-hidden md:border-[0.1rem] lg:w-[40%]">
        <div
          style={{ backgroundImage: `url(${married})` }}
          className="absolute left-0 top-0 z-0 h-1/2 w-full bg-white bg-contain bg-no-repeat"
        />
        <div className="relative z-10">{children}</div>
      </div>
    </div>
  );
};

export default Auth;
