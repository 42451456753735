import authApi from "api/auth";
import { injectToken } from "api/client-v2";
import { GuardianParams, RegisterParams } from "api/types/register";
import PhoneAuth, { PhoneAuthRef } from "components/auth/phone";
import Genders from "components/Genders";
import constants from "configs/constants";
import Auth from "layouts/Auth";
import { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import hasKeys from "utils/hasKeys";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";
import RegisterGuardianForm from "./components/RegisterGuardianForm";
import RegisterUserForm from "./components/RegisterUserForm";

const Register = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const hasState = hasKeys(state || {});

  const [is_guardian, setGuardian] = useState(!!state?.is_guardian);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [genderError, setGenderError] = useState(false);
  const [gender, setGender] = useState(state?.gender || "");
  const phoneRef = useRef<PhoneAuthRef>();
  const containerRef = useRef<HTMLDivElement>(null);

  const { to: phone = "", token } = state || {};

  const registerGuardian = async (values: GuardianParams, token: string) => {
    try {
      setLoading(true);

      injectToken(token);
      delete values.confirmPassword;
      const res = await authApi.registerGuardian(values);

      if (res.ok) {
        storage.storeToken(res.data!.token, res.data!.refresh_token);
        storage.store("guardian", true);
        refreshPage("/");
      } else setError(constants.ERRORS.UNEXPECTED_ERROR);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(constants.ERRORS.UNEXPECTED_ERROR);
      setLoading(false);
    }
  };

  const registerUser = async (values: RegisterParams, token: string) => {
    const { name, email, phone, password } = values;
    try {
      setLoading(true);

      const data: RegisterParams = { name, email, phone, password, gender };

      injectToken(token);
      const res = await authApi.register(data);

      if (res.ok) {
        const token = res.data?.token;
        const build = { route: "/build/profile", gender, is_guardian, token };
        storage.store("build", JSON.stringify(build));
        navigate("/build/profile");
      } else setError(constants.ERRORS.UNEXPECTED_ERROR);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(constants.ERRORS.UNEXPECTED_ERROR);
      setLoading(false);
    }
  };

  const onSubmit = async (values: RegisterParams | GuardianParams) => {
    setError("");
    const props: RegisterParams | GuardianParams = {
      ...values,
      name: values.name.trim(),
      phone: values.phone,
    };

    if (!gender) {
      containerRef.current?.scrollTo(0, 0);
      setGenderError(true);
      return setTimeout(() => setGenderError(false), 5000);
    }

    setLoading(true);

    const isValidCredential = await authApi.checkUserExistence({
      username: props.name,
      email: props.email,
      phone: props.phone,
      guardian: is_guardian,
    });

    if (isValidCredential.ok) {
      if (phone) {
        if (is_guardian)
          return registerGuardian(props as GuardianParams, token);
        else return registerUser(props as RegisterParams, token);
      }

      if (phoneRef.current) {
        phoneRef.current.setState({
          phone: props.phone,
          onSuccess: is_guardian
            ? (token: string) =>
                registerGuardian(props as GuardianParams, token)
            : (token: string) => registerUser(props as RegisterParams, token),
        });
      }
    } else {
      if (isValidCredential.data && isValidCredential.data.message)
        setError(isValidCredential.data.message);
      else setError(constants.ERRORS.UNEXPECTED_ERROR);
    }

    setLoading(false);
  };

  return (
    <Auth>
      <PhoneAuth ref={phoneRef} />
      <div className="flex h-screen flex-col items-center justify-end">
        <div
          ref={containerRef}
          className="no-scrollbar flex h-[85%] w-[98%] flex-col gap-y-5 overflow-y-auto rounded-tl-2xl rounded-tr-2xl border-[1px] border-b-0 border-primary bg-base-100 px-5 pb-24 pt-5 md:h-[90%] md:w-[90%] md:pb-32"
        >
          {(!hasState || state?.token) && (
            <Genders
              gender={gender}
              error={genderError}
              is_guardian={is_guardian}
              onClick={(item) => {
                if (genderError) setGenderError(false);
                setGuardian(item.is_guardian);
                setGender(item.gender);
              }}
            />
          )}

          {!is_guardian ? (
            <RegisterUserForm
              phone={phone}
              error={error}
              loading={loading}
              onSubmit={onSubmit}
            />
          ) : (
            <RegisterGuardianForm
              phone={phone}
              error={error}
              loading={loading}
              onSubmit={onSubmit}
            />
          )}

          <div className="flex items-center justify-center">
            <Link
              className="link-hover link px-1 text-xs"
              target="_blank"
              to="/privacy"
            >
              السياسة
            </Link>
            <span className="mx-[2px]">·</span>
            <Link
              target="_blank"
              className="link-hover link px-1 text-xs"
              to="/terms"
            >
              الشروط
            </Link>
          </div>
        </div>
      </div>
    </Auth>
  );
};

export default Register;
