import Button from "components/core/Button";
import SelectPicker from "components/picker/Select";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import props, { PropsType } from "pages/search/props";
import { useState } from "react";
import { RxReload } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

import Genders from "components/Genders";

const BasicGuestSearch = () => {
  const { options, countries } = useStorage();
  const navigate = useNavigate();

  const stringPersist = localStorage.getItem("landing-basic-search-props");
  const persistedProps = stringPersist ? JSON.parse(stringPersist) : {};
  const initData = { ...props, ...persistedProps };

  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<PropsType>(initData);
  const [gender, setGender] = useState<"male" | "female">(
    persistedProps.gender || "male",
  );

  const relationship_options = options.relationship_status_options[gender];
  const occupations_options = options.occupations_options[gender];
  const beauty_options = options.beauty_options[gender];
  const clan_options = options.clan_options[gender];

  const shapeOptions = (array: Array<string>) => {
    return array;
  };

  const countrySelected = (v: string[]) => {
    setData({ ...data, country: v });
    validate("country");
  };

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  const onSubmit = async () => {
    const values = { ...data };

    localStorage.setItem(
      "landing-basic-search-props",
      JSON.stringify({ ...values, gender }),
    );

    delete values.ageFrom;
    delete values.ageTo;
    delete values.weightFrom;
    delete values.weightTo;
    delete values.heightFrom;
    delete values.heightTo;

    type Key = keyof typeof values;
    for (const key in values) {
      if (!values[key as Key] || values[key as Key] === constants.NOT_REQUIRE)
        delete values[key as Key];
    }

    navigate("/guest/search/result?page=1", {
      state: { ...values, gender: gender },
    });
  };

  const onReload = () => {
    localStorage.setItem("landing-basic-search-props", "");
    setData(props);
  };

  const changeGender = (gender: "male" | "female") => {
    setGender(gender);
    setData(props);
    localStorage.setItem("landing-basic-search-props", "");
  };

  return (
    <div className="px-4">
      <div className="relative my-5 flex w-full items-center justify-between">
        <div className="absolute -top-5 left-0 flex flex-col items-center justify-center gap-y-1">
          <RxReload
            onClick={onReload}
            className="btn btn-circle btn-sm p-1 text-primary"
          />
          <span className="text-xs">{constants.RESET}</span>
        </div>
      </div>

      <p className="mb-4 text-center text-xl font-bold">أبحث عن</p>
      <Genders
        is_guardian={false}
        withoutGuardian
        gender={gender}
        onClick={(item) => changeGender(item.gender as any)}
      />

      <div className="mb-22 mt-10 flex w-full max-md:flex-col max-md:items-center md:mb-5">
        <div className="flex w-1/2 flex-col gap-5 max-md:w-[85%]">
          <SelectPicker
            withSearch
            options={countries.map((e) => e.label)}
            label={constants.COUNTRY}
            value={data.country!}
            error={errors.includes("country")}
            setValue={(v) => countrySelected(v as string[])}
          />

          <SelectPicker
            options={shapeOptions([clan_options[0], clan_options[1]])}
            label={constants.CLAN}
            value={data.clan!}
            error={errors.includes("clan")}
            setValue={(v) => {
              validate("clan");
              setData({ ...data, clan: v as string[] });
            }}
          />

          <SelectPicker
            options={relationship_options}
            label={constants.RELATIONSHIP_STATUS}
            value={data.relationship_status!}
            error={errors.includes("relationship_status")}
            setValue={(v) => {
              validate("relationship_status");
              setData({ ...data, relationship_status: v as string[] });
            }}
          />
        </div>

        <div className="divider divider-horizontal mx-10 mt-5 max-md:hidden"></div>

        <div className="flex w-1/2 flex-col gap-5 max-md:mt-5 max-md:w-[85%]">
          <SelectPicker
            options={shapeOptions(beauty_options)}
            label={constants.BEAUTY}
            value={data.beauty!}
            error={errors.includes("beauty")}
            setValue={(v) => {
              validate("beauty");
              setData({ ...data, beauty: v as string[] });
            }}
          />

          <SelectPicker
            options={occupations_options}
            label={constants.OCCUPATION}
            value={data.occupation!}
            error={errors.includes("occupation")}
            setValue={(v) => {
              validate("occupation");
              setData({ ...data, occupation: v as string[] });
            }}
          />
        </div>

        <div className="my-5 mt-10 flex w-full justify-center md:hidden">
          <Button
            outline
            color="btn-primary"
            className="!px-20"
            onClick={onSubmit}
          >
            {constants.SEARCH}
          </Button>
        </div>
      </div>
      <div className="my-5 mt-10 hidden w-full justify-center md:flex">
        <Button
          outline
          color="btn-primary"
          className="!px-20"
          onClick={onSubmit}
        >
          {constants.SEARCH}
        </Button>
      </div>
    </div>
  );
};

export default BasicGuestSearch;
