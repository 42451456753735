import info from "configs/info";
import { BsGooglePlay } from "react-icons/bs";
import { FaAppStoreIos } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="mt-10 overflow-hidden bg-[#333] p-3 text-center text-xl text-white">
      <p className="">جميع الحقوق محفوظة © 2005</p>
      <div className="mt-4 flex w-full items-center justify-center gap-x-3 self-center">
        <a href={info.GOOGLE_PLAY} target="_blank" title="Google Play">
          <BsGooglePlay className="text-2xl text-white" />
        </a>
        <a href={info.APP_STORE} target="_blank" title="App Store">
          <FaAppStoreIos className="text-2xl text-white" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
