import constants from "configs/constants";
import profileProps from "pages/users/utils/profileProps";
import * as React from "react";
import { getTrackBackground, Range } from "react-range";
import { IRenderThumbParams, IRenderTrackParams } from "react-range/lib/types";
import { categories, SLIDER_STEP } from "./utils";
import classNames from "classnames";

export interface SliderRef {
  value: string;
}

type TSlider = {
  values: number[];
  cat: typeof categories.age;
  single?: boolean;
  disabled?: boolean;
};

export const Track: React.FC<IRenderTrackParams & TSlider> = (params) => {
  const { props, children, values, cat, single, disabled } = params;

  return (
    <div
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      className="flex w-full md:w-[75%]"
      style={props.style}
    >
      <div
        ref={props.ref}
        className="h-[5px] w-full self-center rounded-xl"
        style={{
          background: getTrackBackground({
            values,
            colors: single
              ? [disabled ? "#ccc" : "#FE5E9E", "#ccc"]
              : ["#ccc", disabled ? "#ccc" : "#FE5E9E", "#ccc"],
            min: cat.min,
            max: cat.max,
            rtl: true,
          }),
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const Thumb: React.FC<IRenderThumbParams & TSlider> = (params) => {
  const { props, isDragged, value, cat, disabled } = params;
  return (
    <div
      {...props}
      key={props.key}
      className={classNames({
        "flex h-5 w-5 items-center justify-center rounded-lg border-2 bg-white shadow-sm shadow-gray-400 outline-none":
          true,
        "!border-[#ccc]": disabled,
        "!border-primary": !disabled,
      })}
    >
      <div
        className={classNames({
          "absolute -top-7 flex w-fit flex-row gap-x-1 rounded-md px-1 py-1 text-[11px] font-bold text-white":
            true,
          "bg-[#ccc]": disabled,
          "bg-primary": !disabled,
        })}
      >
        {cat.label === constants.CHILDREN ? (
          <span className="truncate">{`${profileProps.children[value].label}`}</span>
        ) : (
          <>
            <span>{`${value}`}</span>
            <span>{`${cat.unit}`}</span>
          </>
        )}
      </div>
      <div
        className={`h-2 w-[2px] ${isDragged ? "bg-primary" : "bg-gray-400"}`}
      />
    </div>
  );
};

interface Props {
  value?: number;
  setValue: (value: number) => void;
  category: keyof typeof categories;
  showLabel?: boolean;
}

const Slider = ({ showLabel = false, value, setValue, category }: Props) => {
  const cat = categories[category];
  const [sliderValue, setSliderValue] = React.useState([value || cat.min]);

  return (
    <div
      className={`flow-row flex h-9 items-center ${showLabel ? "justify-between" : "justify-center"}`}
    >
      {showLabel && <span className="font-bold">{cat.label}:</span>}
      <Range
        rtl
        step={SLIDER_STEP}
        min={cat.min}
        max={cat.max}
        onFinalChange={(n) => setValue(n[0])}
        onChange={setSliderValue}
        values={sliderValue}
        renderTrack={(p) => (
          <Track key="track" {...p} single values={sliderValue} cat={cat} />
        )}
        renderThumb={(p) => (
          <Thumb {...p} key={p.props.key} values={sliderValue} cat={cat} />
        )}
      />
    </div>
  );
};

export default Slider;
