import Button from "components/core/Button";
import constants from "configs/constants";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import {
  AiFillHome,
  AiFillInfoCircle,
  AiFillPhone,
  AiOutlineClose,
} from "react-icons/ai";
import { MdTravelExplore } from "react-icons/md";
import { RiMenu3Line } from "react-icons/ri";
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";

interface Props {
  scrollToAuth?: () => void;
}

const links = [
  { Icon: AiFillHome, text: "الرئيسية", href: "/" },
  { Icon: AiFillInfoCircle, text: "من نحن", href: "/about" },
  { Icon: MdTravelExplore, text: "طريقة عمل الموقع", href: "/guide" },
  { Icon: AiFillPhone, text: "اتصل بنا", href: "/contacts" },
];

const LandingNavbar = ({ scrollToAuth }: Props) => {
  const [side, setShowSidebar] = useState(false);
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const imNotInLandingPage = pathname === "/" && !scrollToAuth;

  const onNavigateToAuth = () => {
    if (scrollToAuth) scrollToAuth();
    else navigate("/login");
  };

  if (isAuth) return <Navigate to="/" />;
  if (imNotInLandingPage) return null;
  return (
    <div className="navbar sticky top-0 z-50 m-0 grid grid-cols-[10%,90%] grid-rows-1 bg-white p-0 shadow-lg lg:grid-cols-[20%,80%]">
      <div className="flex items-center gap-2 text-2xl font-bold text-primary max-lg:order-1 max-lg:justify-between">
        <div className="flex items-center">
          <img src="/logo.svg" className="w-10" />
          <span>{`${constants.APP_NAME} .نت`}</span>
        </div>
        <Button
          size="btn-xs"
          className="flex h-10 min-w-fit !rounded-full !bg-white !bg-opacity-90 px-4 !text-primary lg:hidden"
          color="btn-primary"
          onClick={onNavigateToAuth}
        >
          {constants.LOGIN}
        </Button>
      </div>

      <label className="btn btn-circle swap swap-rotate justify-center border-0 bg-transparent lg:hidden">
        <input
          type="checkbox"
          checked={side}
          onChange={(e) => setShowSidebar(e.target.checked)}
        />
        <RiMenu3Line className="icon swap-off fill-current text-primary" />
        <AiOutlineClose className="icon swap-on fill-current text-primary" />
      </label>

      <nav
        className={`flex h-full w-full flex-1 animate-fade-up flex-col gap-2 overflow-hidden pr-2 animate-once max-lg:order-3 max-lg:col-span-3 max-lg:items-start max-lg:pb-2 lg:flex-1 lg:flex-row lg:justify-between ${
          !side && "hidden lg:flex"
        }`}
      >
        <div className="flex flex-1 flex-col gap-y-2 max-lg:mb-3 lg:flex-row lg:items-center lg:justify-center lg:gap-x-5">
          {links.map(({ Icon, href, text }, index) => {
            return (
              <NavLink
                target={index !== 0 ? "_blank" : "_self"}
                to={href}
                key={index.toString()}
                className={() =>
                  `link-hover link flex h-full items-center text-base font-bold text-primary hover:no-underline max-lg:py-1`
                }
              >
                <Icon className="mx-1" />
                <span className="truncate">{text}</span>
              </NavLink>
            );
          })}
        </div>
        <div className="mx-1 hidden h-full items-center gap-x-2 md:flex">
          <Button
            size="btn-xs"
            className="h-10 min-w-fit !rounded-full !bg-white !bg-opacity-90 px-4 !text-primary"
            color="btn-primary"
            onClick={onNavigateToAuth}
          >
            {constants.LOGIN}
          </Button>
          <Button
            size="btn-xs"
            outline
            className="h-10 min-w-fit !rounded-full px-4"
            color="btn-accent"
            onClick={() => navigate("/register")}
          >
            {constants.SING_FOR_FREE}
          </Button>
        </div>
      </nav>
    </div>
  );
};

export default LandingNavbar;
