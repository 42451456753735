import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ProfileSkeleton from "components/theme/skeleton/profile";

import useBlocked from "queries/blocked";
import useBlocks from "queries/blocks";
import useFavorites from "queries/favorites";
import useUser from "queries/user";
import useUsers from "queries/users";

import chatsApi from "api/chats";
import engageApi from "api/engage";
import reports from "api/reports";
import users from "api/users";
import classNames from "classnames";
import Button from "components/core/Button";
import Modal from "components/core/Modal";
import TextArea from "components/core/TextArea";
import constants from "configs/constants";
import useToast from "hooks/useToast";
import useChats, { addNewChat } from "queries/chats";
import { refreshEngageQuery } from "queries/engage";
import { FaClipboard } from "react-icons/fa";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import User from "types/User";
import isRemoved from "utils/isRemoved";
import Actions from "./components/Actions";
import ProfileAvatar from "./components/ProfileAvatar";
import ProfileLocation from "./components/ProfileLocation";
import ProfileStatus from "./components/ProfileStatus";
import Tabs, { TProfileDisplay } from "./components/Tabs";
import Info from "./Info";
import History from "./History";

const ProfilePage = () => {
  const { uid, section } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const isPaginate = state?.ids;
  const current = state?.ids && state?.ids.findIndex((e: string) => e === uid);
  const isPrevious = state?.ids && state.ids[current - 1];
  const isNext = state?.ids && state.ids[current + 1];

  const { user, isError, isLoading, error } = useUsers({ uid: uid! });
  const { user: me, setUser } = useUser();

  const { chats } = useChats();
  const { addFavorite, removeFavorite, favorites } = useFavorites();
  const isFavor = favorites.some((f) => f.uid === uid);
  const [isFavorite, setIsFavorite] = useState(isFavor);
  const [loading, setLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [displayAs, setDisplayAs] = useState<TProfileDisplay>("history");

  const { addToBlocks, removeFromBlocks, blocks } = useBlocks();
  const blockExist = blocks.some((b) => b.uid === uid);
  const [isBlocked, setIsBlocked] = useState(blockExist);
  const { isBlockedMe } = useBlocked(user?.account?._id || "");
  const barrier = useRef(true);

  const isMe = me._id === user?.account?._id;
  const isUserEngaged = ["engaged", "married"].includes(user?.account?.status);
  const [showReport, setShowReport] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (!isMe && user && barrier.current && !isUserEngaged) {
      barrier.current = false;
      users.addVisitor(user.account._id);
    }
  }, [user, isMe, isUserEngaged]);

  if (loading || isLoading) return <ProfileSkeleton />;
  if (isError) throw new Error(error?.message);

  const { profile, preferences, account } = user as User;
  const isFemale = me.gender === "female";
  const opGender = isFemale ? "male" : "female";
  const previewMode =
    me._id === user.account._id || ["engaged", "married"].includes(me.status);

  const { is_active, is_online } =
    (chats || []).find((c) => c?.receiver._id == account._id)?.receiver || {};
  const online =
    (is_active && is_online) || (account.is_active && account.is_online);

  const onNext = () => {
    navigate(`/${section}/profile/${state.ids[current + 1]}`, {
      state: { ids: state.ids },
    });
  };

  const onPrevious = () => {
    navigate(`/${section}/profile/${state.ids[current - 1]}`, {
      state: { ids: state.ids },
    });
  };

  const makeAsFavorite = async ({ notify = true }) => {
    if (isBlockedMe.who)
      return toast.error(`لقد قام العضو ${account.name} بحظر التواصل معك`);

    if (isFavorite) {
      removeFavorite(account._id);
      if (notify)
        toast.success(constants.TOAST.REMOVE_FROM_FAVORITE, "left", 20);
    } else if (!isFavor) {
      addFavorite(user);
      if (notify) toast.success(constants.TOAST.ADD_TO_FAVORITE, "left", 20);
    }

    setIsFavorite(!isFavorite);
  };

  const toggleBlock = async () => {
    if (isBlocked) {
      removeFromBlocks(account._id);
      toast.success(constants.TOAST.REMOVE_FROM_BLOCKED, "left", 20);
    } else if (!blockExist) {
      addToBlocks(user);
      toast.success(constants.TOAST.ADD_TO_BLOCKED, "left", 20);
    }

    setIsBlocked(!isBlocked);
  };

  const sendEngageRequest = async () => {
    if (isBlockedMe.who)
      return toast.error(`لقد قام العضو ${account.name} بحظرك`);

    if (me.status === "pending-engage")
      return toast.error(constants.TOAST.ONE_REQUEST_ALLOWED);
    else if (account.status === "engaged" || account.status === "married")
      return toast.error(constants.TOAST.GIRL_ENGAGED_PROFILE);

    // you can't send engage to removed account
    if (isRemoved(account)) return toast.error(isRemoved(account));

    setLoading(true);

    const response = await engageApi.send(account._id);
    if (response.ok) {
      const engage = await engageApi.get();
      if (engage.ok) {
        refreshEngageQuery();
        setUser({ status: "pending-engage" });
        toast.success(constants.TOAST.REQUEST_SENT);
      }
    } else
      toast.error(response.data?.message || constants.ERRORS.UNEXPECTED_ERROR);

    setLoading(false);
  };

  const contact = async () => {
    if (isBlockedMe.who)
      return toast.error(`لقد قام العضو ${account.name} بحظر التواصل معك`);

    const receiver = { ...user.profile, ...user.account };
    const th = chats.find((th) => th.receiver._id === user.account._id);
    let id;

    if (th) id = th._id;
    else {
      setContactLoading(true);

      const thread = await chatsApi.create(user.account._id);
      if (thread.ok) {
        id = thread.data!.id;
        addNewChat({ chatId: id, receiver });
      } else {
        setContactLoading(false);
        return toast.error(constants.ERRORS.UNEXPECTED_ERROR);
      }

      setContactLoading(false);
    }

    navigate(`/thread/${receiver._id}/${id}`);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(account.uid);
    toast.success(constants.TOAST.COPY_UID);
  };

  // check user in Favorite before Blocked
  const checkUserInFavBeforeBlocked = async () => {
    if (isFavorite) makeAsFavorite({ notify: false });
    toggleBlock();
  };

  const ReportModal = () => {
    const [desc, setDesc] = useState("");
    const [loading, setLoading] = useState(false);

    const report = async () => {
      setLoading(true);
      const reportedUser = await reports({
        user: user.account._id,
        message: desc,
      });

      if (reportedUser.ok) toast.success(constants.TOAST.THANKS_FOR_REPORTING);
      else toast.error(constants.ERRORS.UNEXPECTED_ERROR);

      setLoading(false);
      setShowReport(false);
    };

    return (
      <Modal
        className="h-80"
        visible={showReport}
        setVisibility={setShowReport}
      >
        <div className="p-2">
          <p className="m-2 mb-4">
            {constants.VIOLATION_EXPLANATION + " "}
            <span className="text-primary">*{user.account.name}*</span>
          </p>
          <TextArea
            value={desc}
            error={false}
            setValue={setDesc}
            className="h-32 w-[90%]"
            placeholder={constants.VIOLATION_EXPLANATION}
          />

          <Button
            outline
            disable={!desc}
            onClick={report}
            loading={loading}
            className="mx-auto mt-3"
          >
            {constants.SEND}
          </Button>
        </div>
      </Modal>
    );
  };

  const ActionsButton = ({ className = "", iconClassName = "" }) => {
    return (
      <div
        className={`flex h-fit gap-x-8 justify-self-center max-md:order-1 md:gap-x-10 ${className}`}
      >
        <Actions
          iconClassName={iconClassName}
          checkUser={checkUserInFavBeforeBlocked}
          contactLoading={contactLoading}
          isBlocked={isBlocked}
          isFavorite={isFavorite}
          isFemale={isFemale}
          isMyProfile={previewMode}
          makeAsFavorite={makeAsFavorite}
          onContact={contact}
          sendEngage={sendEngageRequest}
          setShowReport={setShowReport}
          toggleBlock={toggleBlock}
        />
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "relative w-full pt-5",
        !isPaginate && "overflow-hidden",
      )}
    >
      {user.account.is_guardian && (
        <div className="absolute left-0 -ml-14 w-48 -rotate-45 bg-primary py-2 text-center text-lg font-bold text-base-100">
          {constants.GUARDIAN_USER}
        </div>
      )}

      {isPaginate && (
        <div className="sticky top-[40%] z-50 flex items-center justify-between px-2">
          <div
            onClick={onPrevious}
            className={`btn btn-circle p-0 text-4xl text-primary ${
              !isPrevious && "btn-disabled"
            }`}
          >
            <FaCircleArrowRight />
          </div>

          <div
            onClick={onNext}
            className={`btn btn-circle p-0 text-4xl text-primary ${
              !isNext && "btn-disabled"
            }`}
          >
            <FaCircleArrowLeft />
          </div>
        </div>
      )}

      <div className="grid-cols-[60%, 40%] mb-5 grid grid-rows-[2fr,1fr] gap-3 md:mb-14 md:grid-cols-3 md:grid-rows-[60%,20%,15%] md:gap-6">
        <div className="flex flex-col justify-center gap-x-3  max-md:gap-y-4">
          <div className="flex flex-col items-center gap-y-2 truncate font-bold text-primary max-md:gap-x-2">
            <span className="md:text-xl">{`${constants.UID}`}</span>
            <span className="flex items-center justify-center gap-x-2 truncate text-accent max-md:text-xs">
              {`${account.uid}`}
              <FaClipboard
                onClick={copyToClipboard}
                className="cursor-pointer text-xs hover:text-accent-focus"
              />
            </span>
          </div>
          <div className="md:hidden">
            <ProfileStatus online={online} last_seen={account.last_seen} />
          </div>
        </div>
        {/*  Profile avatar */}
        <div className="flex h-fit items-center justify-center max-md:-order-1 md:px-12">
          <ProfileAvatar
            online={online}
            preview_me={previewMode}
            gender={opGender}
            preferences={me.preferences}
            account={account}
            profile={profile}
          />
        </div>

        {/* status or (on/off)line, last seen */}
        <div className="hidden items-center justify-center gap-x-3 md:flex">
          <ProfileStatus online={online} last_seen={account.last_seen} />
        </div>

        {/* actions 
        <div className="h-fit gap-x-10 justify-self-center max-md:col-span-2 max-md:self-center md:col-span-3">
          <ActionsButton />
        </div> */}

        {/* country and city */}
        <div className="flex h-fit items-start justify-self-center text-lg font-bold max-md:col-span-2 max-md:self-center md:col-span-3">
          <ProfileLocation profile={profile} />
        </div>
      </div>

      {/*  tabs info */}
      <div className="mb-36 flex flex-col md:mb-8">
        <div className="flex justify-end px-2 md:mt-0">
          <Tabs tab={displayAs} setTab={setDisplayAs} />
        </div>
        {displayAs === "history" ? (
          <History
            gender={account.gender}
            profile={{ ...profile, age: `${account.age}` }}
            preferences={preferences}
          />
        ) : (
          <>
            <div>
              <Info
                isProfile
                user={{ ...profile, age: `${account.age}` }}
                titleOne={constants.MY_INFO}
                titleTow={constants.MY_INFO_IN_DETAILS}
              />
            </div>

            <div className="mt-6">
              <Info
                user={preferences}
                titleOne={constants.MY_CONDITIONS}
                titleTow={constants.MY_CONDITIONS_IN_DETAILS}
              />
            </div>
          </>
        )}

        <ActionsButton
          className="mt-10 items-center justify-center"
          iconClassName="btn-lg text-xl"
        />
      </div>

      {/* report modal */}
      <ReportModal />
    </div>
  );
};

export default ProfilePage;
