import constants from "configs/constants";

export const SLIDER_STEP = 1;

export const categories = {
  children: {
    label: constants.CHILDREN,
    min: 0,
    max: 20,
    unit: "أطفال",
    default: [0],
  },
  age: {
    label: constants.AGE,
    min: 18,
    max: 70,
    unit: "سنة",
    default: [18, 70],
  },
  weight: {
    label: constants.WEIGHT,
    min: 40,
    max: 150,
    unit: "كغ",
    default: [40, 150],
  },
  height: {
    label: constants.HEIGHT,
    min: 150,
    max: 250,
    unit: "سم",
    default: [150, 250],
  },
};
