import prefApi from "api/preferences";
import AccountBuilder from "components/builder/Account";
import constants from "configs/constants";
import useToast from "hooks/useToast";
import useUser from "queries/user";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TPreference from "types/TPreference";
import cleanPreferences from "utils/cleanPreferences";
import hasKeys from "utils/hasKeys";

const EditPreferences = () => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  const gender = user.gender === "male" ? "female" : "male";
  const preferences = {
    ...user.preferences,
    age: user.preferences.age?.split("_").map(Number),
    weight: user.preferences.weight?.split("_").map(Number),
    height: user.preferences.height?.split("_").map(Number),
    description:
      user.preferences.description || user.preferences.pending_description,
  };

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmit = async (values: TPreference) => {
    setLoading(true);

    const props = cleanPreferences(preferences as any, values)

    if (hasKeys(props)) {
      const hasDesc = !!props.description;
      const update = await prefApi.update(props);
      if (hasDesc) {
        props.pending_description = props.description;
        delete props.description;
      }

      if (update.ok) {
        setUser({ preferences: { ...user.preferences, ...props } });
        if (hasDesc) toast.success(constants.TOAST.PENDING_RESPONSE);
        else toast.success(constants.TOAST.UPDATE_PREF_SUCCESSFUL);
        navigate("/");
      } else toast.error(constants.ERRORS.UNEXPECTED_ERROR);
    }

    setLoading(false);
  };

  return (
    <AccountBuilder
      context="preferences"
      gender={gender}
      initUser={preferences as any}
      loading={loading}
      mode="update"
      onSubmit={onSubmit as any}
    />
  );
};

export default EditPreferences;
