import classNames from "classnames";
import constants from "configs/constants";
import TPreference from "types/TPreference";
import TProfile from "types/TProfile";
import { BuildContext, BuildMode } from "./Steps";

type Profile = TProfile & { age: number };

interface IParagraph {
  step: number;
  setStep: (step: number) => void;
  gender: string;
  user: Profile | TPreference;
  as: BuildContext;
  mode: BuildMode;
}

interface IValue {
  step: number;
  setStep: (step: number) => void;
  info: Profile | TPreference;
  as: BuildContext;
  mode: BuildMode;
}

const labels = {
  profile: {
    relationship_status: "",
    nationality: "جنسيتي",
    country: "أقيم في",
    city: "في مدينة",
    age: "ابلغ من العمر",
    clan: "بالنسبة للقبيلة",
    origin: "مرجع أصلي",
    children: "لدي من الاطفال",
    education: "مستواي التعليمي",
    beauty: "مستوى الجمال",
    occupation: "طبيعة عملي",
    financial_status: "حالتي المادية",
    prayers: "بالنسبة للصلاة",
    health: "صحتي",
    skin_color: "لون بشرتي",
    height: "طولي",
    weight: "وزني",
  },
  preferences: {
    relationship_status: "الحالة الاجتماعية",
    nationality: "الجنسية",
    country: "الاقامة",
    city: "المدينة",
    age: "العمر",
    clan: "القبيلة",
    origin: "الاصل",
    children: "الاطفال",
    education: "المستوى التعليمي",
    beauty: "مستوى الجمال",
    occupation: "طبيعة العمل",
    financial_status: "الحالة المادية",
    prayers: "بالنسبة للصلاة",
    health: "الصحة",
    skin_color: "ولون البشرة",
    height: "الطول",
    weight: "الوزن",
  },
};

const refactorInfo = (user: Profile | TPreference): any => {
  const PROP_WITH_TOW_VALUES = ["age", "weight", "height"];

  return Object.entries(user)
    .map(([key, value]) => {
      if (PROP_WITH_TOW_VALUES.includes(key)) {
        const unit = `${key === "weight" ? "كغ" : key === "height" ? "سم" : "سنة"}`;
        if (typeof value === "string" && !value.includes(constants.NOT_REQUIRE))
          return {
            [key]: `من ${value.replace(/_/g, " الى ").replace(/cm/g, "").replace(/kg/g, "")} ${unit}`,
          };
        else if (typeof value === "number")
          return { [key]: `${value} ${unit}` };
      }

      if (Array.isArray(value) && !!value.join(" - "))
        return { [key]: value.join(" - ") };
      else if (!Array.isArray(value)) return { [key]: value };
      else return {};
    })
    .reduce((acc, obj) => ({ ...acc, ...obj }), {});
};

const PIC_STEP = 11;

const renderValues = ({ step, setStep, info, as, mode }: IValue) => {
  type Key = keyof typeof info;
  let prefix = false;
  const updateProfile = as === "profile" && mode === "update";
  const updatePreferences = as === "preferences" && mode === "update";
  const buildProfile = as === "profile" && mode === "build";
  const isPictureStep = buildProfile && PIC_STEP === step;

  const goTo = (step: number) => {
    return () => setStep(step);
  };

  const ignore = (label: [string, string][]) => {
    const key = label[0] as any;
    return !(
      (updateProfile && key === "age") ||
      (key === "children" && as === "preferences")
    );
  };

  return Object.entries(labels[as])
    .filter(ignore as any)
    .map(([key, label], i) => {
      if (!prefix) prefix = true;
      const value = info[key as Key];
      const isSelected = buildProfile
        ? step === (i + 1 < PIC_STEP ? i + 1 : i + 2)
        : step === i + 1;

      return (
        <span key={i.toString()}>
          {prefix && i !== 0 && " و "}
          {`${label} `}
          <span className="inline-block font-bold text-primary">
            {value !== undefined && value !== null ? (
              <span className="cursor-pointer" onClick={goTo(i + 1)}>
                {value}
              </span>
            ) : (
              <p
                onClick={updatePreferences ? goTo(i + 1) : undefined}
                className={classNames({
                  "mx-1 text-xs text-gray-300": true,
                  "animate-jump font-bold !text-primary animate-delay-500 animate-duration-[1500ms] animate-infinite":
                    isSelected && !isPictureStep,
                  "cursor-pointer !text-primary": updatePreferences,
                })}
              >
                {isSelected ? "●●●●" : "____"}
              </p>
            )}
          </span>
        </span>
      );
    });
};

const Paragraph = ({ step, setStep, gender, user, as, mode }: IParagraph) => {
  const info = refactorInfo(user) as Profile | TPreference;

  return as === "profile" ? (
    <div>
      <span>{`أنا ${gender === "male" ? "شاب" : "فتاة"} `}</span>
      {renderValues({ step, info, setStep, mode, as })}
    </div>
  ) : (
    <div>
      <span>{`أشترط أن ${gender === "male" ? "تكون" : "يكون"}،  `}</span>
      {renderValues({ step, info, setStep, as, mode })}
    </div>
  );
};

export default Paragraph;
