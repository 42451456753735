import RowListItem from "components/lists/RowListItem";
import Tabs from "components/Tabs";
import HorizontalSkeletons from "components/theme/skeleton/horizontal";
import constants from "configs/constants";
import useSearch from "queries/search";
import { FC, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import UserItem from "types/UserItem";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1280 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1280, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

interface UserCarouselProps {
  users: UserItem[];
  isFetching: boolean;
}

const UserCarousel: FC<UserCarouselProps> = ({ users, isFetching }) => {
  return (
    <div className="rounded-box mx-auto w-[90%] overflow-hidden p-1 px-4 transition-all">
      {isFetching ? (
        <HorizontalSkeletons />
      ) : (
        <Carousel
          rtl
          infinite
          autoPlay
          autoPlaySpeed={4000}
          responsive={responsive}
          containerClass="carousel-container"
          itemClass="carousel-item-padding-40-px"
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots={false}
          customRightArrow={
            <div className="text-primary-400 btn btn-circle btn-neutral absolute left-0 top-1/3 opacity-10 max-sm:btn-sm hover:opacity-80">
              <IoIosArrowBack className="text-xl" />
            </div>
          }
          customLeftArrow={
            <div className="text-primary-400 btn btn-circle btn-neutral absolute right-0 top-1/3 opacity-10 max-sm:btn-sm hover:opacity-80">
              <IoIosArrowForward className="text-xl" />
            </div>
          }
        >
          {users.map((user) => (
            <div key={user._id} className="!h-[14rem]">
              <RowListItem
                online
                age={user.age}
                city={user.city}
                username={user.name}
                country={user.country}
                guardian={user.is_guardian}
                image={user.profile_picture}
                status={user.relationship_status}
                to={`/guest/users/${user.uid}`}
                target="_blank"
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

const Online = () => {
  const [tab, setTab] = useState("female");
  const mResults = useSearch({
    guest: true,
    page: 1,
    params: { gender: "male", status: true },
  });

  const fResults = useSearch({
    guest: true,
    page: 1,
    params: { gender: "female", status: true },
  });

  const males = mResults.data?.results || [];
  const females = fResults.data?.results || [];

  const hasUsers = males.length > 0 || females.length > 0;

  return (
    <div className="mx-auto my-16 flex max-w-6xl flex-col overflow-hidden">
      {!!hasUsers && (
        <div className="mb-5 self-center">
          <Tabs
            tabs={["female", "male"]}
            activeTab={tab}
            onTabChange={setTab}
            tabLabels={{
              female: constants.ONLINE_FEMALE_USERS,
              male: constants.ONLINE_MALE_USERS,
            }}
          />
        </div>
      )}
      <div className="flex w-full flex-col gap-y-5">
        <UserCarousel
          users={tab === "female" ? females : males}
          isFetching={(tab === "female" ? fResults : mResults).isFetching}
        />
      </div>
    </div>
  );
};

export default Online;
