import constants from "configs/constants";
import { useState } from "react";

import { injectToken } from "api/client-v2";
import profiles from "api/profiles";
import users from "api/users";
import AccountBuilder from "components/builder/Account";
import { TUserBuilder } from "components/builder/utils/Steps";
import useToast from "hooks/useToast";
import TProps from "pages/users/utils/TProps";
import { AiOutlinePoweroff } from "react-icons/ai";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";
import TProfile from "types/TProfile";
import expiredToken from "utils/expiredToken";
import hasKeys from "utils/hasKeys";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";

interface Build {
  gender?: string;
  token?: string;
  is_guardian?: boolean;
  user?: TProps;
  route?: string;
  activeStep?: number;
}

const storeValue = (key: string, value: any) =>
  storage.store(key, JSON.stringify(value));

function BuildProfile() {
  const navigate = useNavigate();
  const state = useLoaderData() as Build;
  const isPropsPassed = state && Object.keys(state).length;

  const { gender, token, is_guardian, user: initUser, activeStep } = state;
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmit = async (values: TUserBuilder) => {
    setLoading(true);

    const { age, profile_picture, ...restValues } = values as any;
    const profile = { ...restValues };

    let store = {};
    injectToken(token!);

    const user: any = {};
    if (age) user.age = +age;
    if (profile_picture) user.profile_picture = profile_picture;

    if (hasKeys(user)) {
      const response = await users.update(user);
      if (response.ok) store = { gender, is_guardian, token };
      else if (!response.ok) {
        toast.error(constants.ERRORS.INFO_UPLOAD);
        return setLoading(false);
      }
    }

    const response = await profiles.create(profile as TProfile);
    if (response.ok) {
      setLoading(false);
      storeValue("build", {
        route: "/build/preferences",
        activeStep: 0,
        ...store,
      });
      navigate("/build/preferences");
    } else {
      toast.error(constants.ERRORS.INFO_UPLOAD + ".");
      return setLoading(false);
    }
  };

  async function onPersist(user: TUserBuilder, activeStep: number) {
    try {
      const isExpiry = expiredToken(token || "");
      if (isExpiry) {
        storage.remove("build");
        refreshPage("/login");
      } else {
        const store = { user, gender, token, is_guardian };
        storeValue("build", {
          route: "/build/profile",
          activeStep,
          ...store,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function logout() {
    storage.remove("build");
    refreshPage("/");
  }

  if (!isPropsPassed) return <Navigate to="/login" />;
  if (state.route !== "/build/profile")
    return <Navigate to="/build/preferences" />;

  return (
    <div className="mt-16">
      <div
        onClick={logout}
        className="absolute left-5 top-5 z-10 flex cursor-pointer flex-col items-center font-semibold text-primary"
      >
        <AiOutlinePoweroff className="text-xl" />
        <p className="text-xs">{constants.OUT}</p>
      </div>
      <AccountBuilder
        initStep={activeStep}
        onPersist={onPersist}
        initUser={initUser as any}
        context="profile"
        gender={gender!}
        mode="build"
        onSubmit={onSubmit}
        loading={loading}
      />
    </div>
  );
}

export default BuildProfile;
