import Button from "components/core/Button";
import Input from "components/core/Input";
import TextArea from "components/core/TextArea";
import constants from "configs/constants";
import { useState } from "react";

interface Props {
  forceOpen?: boolean;
  onSubmit: (title: string, content: string) => void;
}

const AddSubjectModal = ({ forceOpen, onSubmit }: Props) => {
  const [visible, setVisibility] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [error, setError] = useState({ title: false, content: false });

  function submit() {
    setError({ title: false, content: false });

    if (!title && !content)
      return setError(() => ({ content: true, title: true }));
    else if (!title) return setError((e) => ({ ...e, title: true }));
    else if (!content) return setError((e) => ({ ...e, content: true }));

    onSubmit(title, content);
    setVisibility(false);
  }

  return (
    <>
      <div
        className={`collapse collapse-arrow border-2 border-accent bg-base-200 ${forceOpen && "collapse-open"}`}
      >
        <input type="checkbox" className="peer" />
        <div className="collapse-title bg-white font-bold text-accent">
          {constants.SEND_NEW_SUPPORT_MESSAGE}
        </div>
        <div className="collapse-content bg-white">
          <div className="mt-8 h-[60vh] gap-12">
            <Input
              value={title}
              setValue={setTitle}
              error={error.title}
              placeholder={constants.MESSAGE_TITLE}
            />

            <div className="mb-10 mt-6">
              <TextArea
                className="h-32 w-full border-gray-300"
                value={content}
                setValue={setContent}
                error={error.content}
                placeholder={constants.MESSAGE_CONTENT}
              />
            </div>

            <Button className="mx-auto" onClick={submit}>
              {constants.SEND}
            </Button>
          </div>
        </div>
      </div>

      <dialog className={`modal ${!!visible && "modal-open"}`}>
        <div className="no-scrollbar modal-box h-[65vh]">
          <form method="dialog">
            <button
              onClick={() => setVisibility(false)}
              className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2"
            >
              ✕
            </button>
          </form>

          <p className="my-2 text-center text-lg font-bold">
            {constants.SEND_NEW_SUPPORT_MESSAGE}
          </p>
          <div className="mt-8 gap-12">
            <Input
              value={title}
              setValue={setTitle}
              error={error.title}
              placeholder={constants.MESSAGE_TITLE}
            />

            <div className="mb-10 mt-6">
              <TextArea
                className="h-32 w-full border-gray-300"
                value={content}
                setValue={setContent}
                error={error.content}
                placeholder={constants.MESSAGE_CONTENT}
              />
            </div>

            <Button className="mx-auto" onClick={submit}>
              {constants.SEND}
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default AddSubjectModal;
