import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import auth from "api/auth";
import Button from "components/core/Button";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import constants from "configs/constants";
import useForm from "hooks/useForm";
import useStorage from "hooks/useStorage";
import Auth from "layouts/Auth";
import { useState } from "react";
import { TfiIdBadge } from "react-icons/tfi";
import { Link, useNavigate } from "react-router-dom";
import decodeToken from "utils/decodeToken";

type TValue = Yup.InferType<typeof schema.token>;

const RegisterByToken = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { options } = useStorage();
  const { whatsapp } = options.social;

  const { control, errors, handleSubmit, setError } = useForm<TValue>({
    resolver: yupResolver(schema.token),
    defaultValues: { token: "" },
  });

  async function onSubmit({ token }: TValue) {
    setLoading(true);
    const response = await auth.checkToken(token);
    if (response.ok) {
      const decode = decodeToken<{ to: string }>(response.data!.token);
      setTimeout(() => {
        setLoading(false);
        navigate("/register", {
          state: { to: decode.data!.to, token: response.data?.token },
        });
      }, 1500);
    } else {
      setError("token", { message: constants.ERRORS.TOKEN_EXPIRED });
      setLoading(false);
    }
  }

  return (
    <Auth>
      <div className="flex h-screen flex-col items-center justify-end">
        <div className="no-scrollbar flex h-[70%] w-[98%] flex-col gap-y-5 overflow-y-auto rounded-tl-2xl rounded-tr-2xl border-[1px] border-b-0 border-primary bg-base-100 px-5 pb-20 pt-5 md:h-[70%] md:w-[90%] md:pb-32">
          <div className="my-5 text-center font-semibold md:text-lg">
            {`يمكنك الحصول على المعرف من فريق الدعم لتخطي خطوة التحقق من رقم الهاتف. يرجى التواصل مع `}
            <Link
              target="_blank"
              to={"https://wa.me/" + whatsapp}
              className="link-hover link-primary link mx-1 animate-pulse underline"
            >
              {"ادارة الموقع"}
            </Link>
            {` لتأكيد رقم هاتفك`}
          </div>

          <div>
            <FormFiled
              type="text"
              name="token"
              maxLength={10}
              Icon={TfiIdBadge}
              control={control}
              error={errors.token}
              placeholder={constants.ENTER_TOKEN_ID}
              containerClassName="border-primary"
            />
          </div>
          <span
            onClick={() => navigate("/login")}
            className="link-hover link mx-auto text-xs hover:text-primary"
          >
            {constants.HAVE_ACCOUNT}
          </span>

          <Button
            outline
            loading={loading}
            className="w-[80%]"
            color="btn-primary"
            onClick={handleSubmit(onSubmit)}
          >
            {constants.VERIFY}
          </Button>
        </div>
      </div>
    </Auth>
  );
};

export default RegisterByToken;
