import Button from "components/core/Button";
import SelectPicker from "components/picker/Select";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import useUser from "queries/user";
import { useState } from "react";
import { RxReload } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import props, { PropsType } from "./props";

const BasicSearch = () => {
  const { user } = useUser();
  const { options, countries } = useStorage();
  const navigate = useNavigate();
  const stringPersist = localStorage.getItem("basic-search");
  const persistedProps = stringPersist ? JSON.parse(stringPersist) : {};
  const initData = { ...props, ...persistedProps };

  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<PropsType>(initData);

  const gender = user.gender;
  const opGender = gender === "male" ? "female" : "male";

  const relationship_options = options.relationship_status_options[opGender];
  const occupations_options = options.occupations_options[opGender];
  const beauty_options = options.beauty_options[opGender];
  const clan_options = options.clan_options[opGender];

  const shapeOptions = (array: Array<string>) => {
    return array;
  };

  const countrySelected = (v: string[]) => {
    setData({ ...data, country: v });
    validate("country");
  };

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  const onSubmit = async () => {
    const values = { ...data };

    localStorage.setItem("basic-search", JSON.stringify(values));

    delete values.ageFrom;
    delete values.ageTo;
    delete values.weightFrom;
    delete values.weightTo;
    delete values.heightFrom;
    delete values.heightTo;

    type Key = keyof typeof values;
    for (const key in values) {
      if (
        !values[key as Key] ||
        values[key as Key] === constants.NOT_REQUIRE ||
        (Array.isArray(values[key as Key]) &&
          !(values[key as Key] as string[]).length)
      )
        delete values[key as Key];
    }

    navigate("/search/result?page=1", { state: values });
  };

  const onReload = () => {
    localStorage.setItem("basic-search", "");
    setData(props);
  };

  return (
    <div className="px-4">
      <div className="relative my-10 flex w-full items-center justify-between">
        <div className="absolute -top-5 left-0 flex flex-col items-center justify-center gap-y-1">
          <RxReload
            onClick={onReload}
            className="btn btn-circle btn-sm p-1 text-primary"
          />
          <span className="text-xs">{constants.RESET}</span>
        </div>
      </div>
      <div className="mt-5 flex w-full max-md:flex-col max-md:items-center md:mb-5">
        <div className="flex w-1/2 flex-col gap-5 max-md:w-[85%]">
          <SelectPicker
            multiple
            withSearch
            options={countries.map((e) => e.label)}
            label={constants.COUNTRY}
            value={data.country!}
            error={errors.includes("country")}
            setValue={(v) => countrySelected(v as string[])}
          />

          <SelectPicker
            multiple
            options={shapeOptions([clan_options[0], clan_options[1]])}
            label={constants.CLAN}
            value={data.clan!}
            error={errors.includes("clan")}
            setValue={(v) => {
              validate("clan");
              setData({ ...data, clan: v as string[] });
            }}
          />

          <SelectPicker
            multiple
            options={relationship_options}
            label={constants.RELATIONSHIP_STATUS}
            value={data.relationship_status!}
            error={errors.includes("relationship_status")}
            setValue={(v) => {
              validate("relationship_status");
              setData({ ...data, relationship_status: v as string[] });
            }}
          />
        </div>

        <div className="divider divider-horizontal mx-10 mt-2 max-md:hidden"></div>

        <div className="flex w-1/2 flex-col gap-5 max-md:mt-5 max-md:w-[85%]">
          <SelectPicker
            multiple
            options={shapeOptions(beauty_options)}
            label={constants.BEAUTY}
            value={data.beauty!}
            error={errors.includes("beauty")}
            setValue={(v) => {
              validate("beauty");
              setData({ ...data, beauty: v as string[] });
            }}
          />

          <SelectPicker
            multiple
            options={occupations_options}
            label={constants.OCCUPATION}
            value={data.occupation!}
            error={errors.includes("occupation")}
            setValue={(v) => {
              validate("occupation");
              setData({ ...data, occupation: v as string[] });
            }}
          />
        </div>

        <div className="my-5 mt-10  flex w-full justify-center md:hidden">
          <Button
            color="btn-primary"
            className="!w-48"
            outline
            onClick={onSubmit}
          >
            {constants.SEARCH}
          </Button>
        </div>
      </div>
      <div className="my-5 mt-10 hidden w-full justify-center md:flex">
        <Button
          color="btn-primary"
          className="!w-48"
          outline
          onClick={onSubmit}
        >
          {constants.SEARCH}
        </Button>
      </div>
    </div>
  );
};

export default BasicSearch;
