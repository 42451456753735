import constants from "configs/constants";
import TPreference from "types/TPreference";
import TProfile from "types/TProfile";
import hasKeys from "utils/hasKeys";

type IAs = "profile" | "preference";
type Profile = TProfile & { age: string };

interface Props {
  profile: Profile;
  preferences: TPreference;
  gender: string;
}

interface IParagraph {
  gender: string;
  user: Profile | TPreference;
  as: IAs;
}

interface IInfo {
  user: Profile | TPreference;
  titleOne: string;
  titleTow: string;
  as: IAs;
  gender: string;
}

const labels = {
  profile: {
    clan: "بالنسبة للقبيلة",
    origin: "مرجع أصلي",
    children: "لدي من الاطفال",
    education: "مستواي التعليمي",
    beauty: "مستوى الجمال",
    occupation: "طبيعة عملي",
    financial_status: "حالتي المادية",
    prayers: "بالنسبة للصلاة",
    health: "صحتي",
    skin_color: "لون بشرتي",
    height: "طولي",
    weight: "وزني",
  },
  preference: {
    relationship_status: "الحالة",
    nationality: "الجنسية",
    country: "الاقامة",
    city: "المدينة",
    clan: "القبيلة",
    origin: "الاصل",
    children: "الاطفال",
    education: "المستوى التعليمي",
    beauty: "مستوى الجمال",
    occupation: "طبيعة العمل",
    financial_status: "الحالة المادية",
    prayers: "بالنسبة للصلاة",
    health: "الصحة",
    skin_color: "ولون البشرة",
    age: "العمر",
    height: "الطول",
    weight: "الوزن",
  },
};

const refactorInfo = (user: Profile | TPreference): any => {
  const PROP_WITH_TOW_VALUES = ["age", "weight", "height"];
  const ignore = ["لا تنطبق علي هذه الصفة", "أخرى"];

  return Object.entries(user)
    .filter((e) => !ignore.includes(Array.isArray(e[1]) ? e[1].join("") : e[1]))
    .map(([key, value]) => {
      if (PROP_WITH_TOW_VALUES.includes(key)) {
        const unit = `${key === "weight" ? "كغ" : key === "height" ? "سم" : ""}`;
        if (typeof value === "string" && !value.includes(constants.NOT_REQUIRE))
          return {
            [key]: `من ${value.replace(/_/g, " الى ").replace(/cm/g, "").replace(/kg/g, "")} ${unit}`,
          };
        else if (typeof value === "number")
          return { [key]: `${value} ${unit}` };
      }

      if (Array.isArray(value) && !!value.join(" - "))
        return { [key]: value.join(" - ") };
      else if (!Array.isArray(value)) return { [key]: value };
      else return {};
    })
    .reduce((acc, obj) => ({ ...acc, ...obj }), {});
};

const renderValues = (info: TPreference | Profile, as: IAs) => {
  type Key = keyof typeof info;
  let prefix = false;

  return Object.entries(labels[as])
    .filter(([key]) => info[key as Key])
    .map(([key, value], i) => {
      if (!prefix) prefix = true;

      return (
        <span key={i.toString()}>
          {prefix && i !== 0 && " و "}
          {`${value} `}
          <span className="font-bold text-primary">{info[key as Key]}</span>
        </span>
      );
    });
};

const Paragraph = ({ gender, user, as }: IParagraph) => {
  const info = refactorInfo(user) as Profile | TPreference;

  return as === "profile" ? (
    <div>
      {hasKeys(info) && (
        <span>{`أنا ${gender === "male" ? "شاب" : "فتاة"} `}</span>
      )}
      {renderValues(info, "profile")}
    </div>
  ) : (
    <div>
      {hasKeys(info) && (
        <span>{`أشترط أن ${gender === "male" ? "تكون" : "يكون"}،  `}</span>
      )}
      {renderValues(info, "preference")}
    </div>
  );
};

const Info = ({ as, user, gender, titleOne, titleTow }: IInfo) => {
  const description = (user as Profile).bio
    ? (user as Profile).bio
    : (user as TPreference).description;

  return (
    <div>
      <span className="mb-5 pr-5 text-lg font-bold text-primary">
        {titleOne}
      </span>
      <div className="m-5 mt-2 px-2">
        <Paragraph user={user} as={as} gender={gender} />
      </div>

      {!!description && (
        <>
          <span className="mb-5 pr-5 text-lg font-bold text-primary">
            {titleTow}
          </span>
          <div className="m-5 mt-2 px-2">
            <span className="">{description}</span>
          </div>
        </>
      )}
    </div>
  );
};

const History = ({ profile, preferences, gender }: Props) => {
  return (
    <>
      <Info
        as="profile"
        user={profile}
        gender={gender}
        titleOne={constants.MY_INFO}
        titleTow={constants.MY_INFO_IN_DETAILS_HISTORY}
      />
      <Info
        as="preference"
        gender={gender}
        user={preferences}
        titleOne={constants.MY_CONDITIONS}
        titleTow={constants.MY_CONDITIONS_IN_DETAILS_HISTORY}
      />
    </>
  );
};

export default History;
