import { Country } from "types/TCountry";
import TOptions from "types/TOptions";
import TUser from "types/TUser";
import storage from "utils/storage";

interface Build {
  gender?: string;
  token?: string;
  is_guardian?: boolean;
  user?: TUser;
  route?: string;
}

export default function useStorage() {
  const options: TOptions = JSON.parse(
    localStorage.getItem("options") as string,
  );
  const images: { male: []; female: [] } = JSON.parse(
    localStorage.getItem("avatars") as string,
  );
  const countries: Country[] = JSON.parse(
    localStorage.getItem("countries") as string,
  );

  const privacy: string = localStorage.getItem("privacy") as string;
  const terms: string = localStorage.getItem("terms") as string;
  const about: string = localStorage.getItem("about") as string;
  const contact: string = localStorage.getItem("contacts") as string;
  const guide: string = localStorage.getItem("guide") as string;

  const build: Build = JSON.parse(storage.get("build") as string);

  return {
    build,
    options,
    countries,
    images,
    privacy,
    contact,
    terms,
    about,
    guide,
  };
}
