import React, { ReactNode, useEffect, useState } from "react";
import Header from "./Header";

interface ChatUiProps {
  visible: boolean;
  seen: boolean;
  color?: "primary" | "secondary";
  user: { name: string; profile_picture: string };
  close: () => void;
  children: ReactNode;
  badge?: boolean;
}

const Container: React.FC<ChatUiProps> = ({
  children,
  visible,
  close,
  color,
  seen,
  user,
  badge,
}) => {
  const [isCollapse, setIsCollapse] = useState(true);

  useEffect(() => {
    if (!isCollapse) setIsCollapse(true);
  }, [user]);

  return (
    <div
      className={`${(!visible || "") && "hidden"} ${
        isCollapse
          ? "collapse h-[90vh] min-h-[65vh] rounded-none rounded-t-xl border border-base-300 bg-base-200 sm:max-h-[65vh] sm:w-96"
          : "bottom-16 h-20 w-20 rounded-full p-2 sm:bottom-5"
      } fixed bottom-0 left-0 z-[99999]`}
    >
      {isCollapse && (
        <input
          className="bg-black"
          type="checkbox"
          checked={isCollapse}
          onChange={(e) => setIsCollapse(e.target.checked)}
        />
      )}

      <Header
        user={user}
        seen={seen}
        color={color}
        close={close}
        isCollapsed={isCollapse}
        setIsCollapse={setIsCollapse}
        badge={badge}
      />

      {children}
    </div>
  );
};

export default Container;
