import { yupResolver } from "@hookform/resolvers/yup";
import auth from "api/auth";
import { injectToken } from "api/client-v2";
import Button from "components/core/Button";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import constants from "configs/constants";
import useForm from "hooks/useForm";
import useToast from "hooks/useToast";
import jwtDecode from "jwt-decode";
import Auth from "layouts/Auth";
import { useEffect, useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import expiredToken from "utils/expiredToken";
import { onLoginDone } from "./Login";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";

interface Reset {
  email: string;
  password: string;
  confirmPassword: string;
}

const isValidJWT = (token: string) => {
  const jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+$/;
  return jwtRegex.test(token);
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const token = params.get("t");
  const expiryToken = !!token && expiredToken(token);
  const hasToken = !!token;

  const getEmail = () => {
    if (hasToken) {
      try {
        return jwtDecode<any>(token).email;
      } catch (error) {
        return "";
      }
    }
  };

  const restForm = useForm<Reset>({
    resolver: yupResolver(schema.reset),
    defaultValues: {
      email: getEmail(),
      password: "",
      confirmPassword: "",
    },
  });

  const emailForm = useForm<{ email: string }>({
    resolver: yupResolver(schema.email),
    defaultValues: { email: "" },
  });

  const form = hasToken ? restForm : emailForm;

  const onBack = () => {
    setParams([]);
  };

  const done = (token: string, rToken: string) => {
    storage.storeToken(token, rToken);
    refreshPage("/");
  };

  const onResetPass = async ({ password = "" }) => {
    try {
      injectToken(token || "");
      setLoading(true);
      const user = await auth.resetPassword({ password });
      if (user.ok) {
        onLoginDone({ user: user.data!, navigate, done });
      } else {
        if (user.data?.message) setError(user.data.message);
        else setError(constants.ERRORS.UNEXPECTED_ERROR);
        return setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onRequestRest = async ({ email = "" }) => {
    try {
      setLoading(true);
      const res = await auth.reqResetPassword({ email });
      if (res.ok) {
        toast.success(constants.SUCCESS.EMAIL_REQUEST);
      } else {
        if (res.data?.message) setError(res.data.message);
        else setError(constants.ERRORS.UNEXPECTED_ERROR);
        setTimeout(() => setError(""), 2000);
        return setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onReset = async ({ email, password }: Partial<Reset>) => {
    if (hasToken) return onResetPass({ password });
    onRequestRest({ email });
  };

  useEffect(() => {
    if (!!token && !isValidJWT(token)) {
      onBack();
    }
  }, []);

  return (
    <Auth>
      <div className="flex h-screen flex-col items-center justify-end">
        <div className="no-scrollbar z-10 flex h-[70%] w-[98%] flex-col gap-y-5 overflow-y-auto rounded-tl-3xl rounded-tr-3xl border-[0.1rem] border-primary bg-white p-5 pt-10 md:h-[85%] md:w-[90%]">
          <p className="mb-5 text-center font-bold">
            {hasToken ? constants.RESET_PASS : constants.REQUEST_RESET_PASS}
          </p>
          {!hasToken && (
            <div>
              <FormFiled
                key={`${token}`}
                type="email"
                name="email"
                disabled={hasToken}
                Icon={HiOutlineMail}
                control={form.control}
                placeholder={constants.EMAIL}
                error={form.errors.email}
                containerClassName=" border-primary"
              />
            </div>
          )}
          {hasToken && (
            <>
              <div>
                <FormFiled
                  type="password"
                  name="password"
                  control={form.control}
                  Icon={RiLockPasswordLine}
                  error={(form.errors as any).password}
                  placeholder={constants.NEW_PASSWORD}
                  containerClassName="border-primary"
                />
              </div>

              <div>
                <FormFiled
                  type="password"
                  control={form.control}
                  name="confirmPassword"
                  Icon={RiLockPasswordLine}
                  error={(form.errors as any).confirmPassword}
                  containerClassName="border-primary"
                  placeholder={constants.CONFIRM_NEW_PASSWORD}
                />
              </div>
            </>
          )}
          {!!error && <p className="mr-3 text-xs text-error">{error}</p>}
          {!!expiryToken && (
            <p className="mr-3 text-sm font-bold text-error">
              {`${constants.EXPIRY_URL}، `}
              <span className="link-hover link-info link" onClick={onBack}>
                {constants.REQUEST_NEW_URL}
              </span>
            </p>
          )}

          <Button
            outline
            disable={!!expiryToken}
            loading={loading}
            className="mt-5 w-[60%]"
            color="btn-primary"
            onClick={form.handleSubmit(onReset)}
          >
            {hasToken ? constants.RESET_PASSWORD : constants.SEND_REQUEST}
          </Button>
        </div>
      </div>
    </Auth>
  );
};

export default ResetPassword;
