import auth from "api/auth";
import classNames from "classnames";
import { FC, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { PiCheckFatFill } from "react-icons/pi";

interface Props {
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  value?: string | number | readonly string[] | undefined;
  setValue?: (v: string) => void;
  inputClassName?: string;
  containerClassName?: string;
  error?: boolean;
  errorMessage?: string;
  Icon?: FC<{ className?: string }>;
  alphabetic?: boolean;
  withNameChecker?: boolean;
  is_guardian?: boolean;
}

const Input = ({
  type = "text",
  placeholder = "",
  value,
  setValue,
  containerClassName,
  inputClassName,
  error,
  errorMessage,
  Icon,
  alphabetic,
  withNameChecker,
  is_guardian,
  ...rest
}: Props) => {
  const [inputType, setInputType] = useState(type);
  const EyeIcon = inputType === "password" ? BsEye : BsEyeSlash;
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState<boolean | null>(null);

  const onSetValue = (text: string) => {
    setValue!(text);

    if (withNameChecker && text.length > 2) {
      setDone(null);
      clearTimeout(timer);

      const newTimer = setTimeout(async () => {
        setLoading(true);
        const response = await auth.checkUserExistence({
          username: text.trim(),
          guardian: is_guardian,
        });
        setLoading(false);
        if (response.ok) setDone(false);
        else setDone(true);
      }, 800);

      setTimer(newTimer);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      alphabetic &&
      /^(?![0-9\u0660-\u0669 ])[0-9\u0660-\u0669\u0621-\u064A ]*$/.test(
        e.target.value,
      )
    )
      onSetValue(e.target.value);
    else if (!alphabetic) onSetValue(e.target.value);
  };

  return (
    <>
      <div
        className={classNames({
          "mb-0 flex w-full flex-row items-center overflow-hidden rounded-lg border-[0.1rem] border-gray-300 px-0 focus:outline-none":
            true,
          // [size]: Boolean(size),
          "border-error": Boolean(error),
          [`${containerClassName}`]: Boolean(containerClassName),
        })}
      >
        {Icon && <Icon className="mr-2 text-xl" />}
        <input
          type={inputType}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          {...rest}
          className={`input h-9 w-full text-sm placeholder:text-gray-400 focus:outline-none ${inputClassName} ${Icon ? "pr-2" : "pr-4"}`}
        />
        {type === "password" && (
          <EyeIcon
            className="ml-3 cursor-pointer text-xl"
            onClick={() =>
              setInputType(inputType === "password" ? "text" : "password")
            }
          />
        )}
        {withNameChecker && (
          <>
            {loading && (
              <span className="loading loading-bars loading-xs mx-2 bg-primary animate-once" />
            )}
            {done !== null && (
              <>
                {!done ? (
                  <PiCheckFatFill className="mx-2 animate-jump-in text-lg text-success animate-once" />
                ) : (
                  <IoClose className="mx-2 animate-jump-in text-2xl text-error animate-once" />
                )}
              </>
            )}
          </>
        )}
      </div>
      {errorMessage && (
        <span className={`mr-2 text-sm text-error`}>{errorMessage}</span>
      )}
    </>
  );
};

export default Input;
