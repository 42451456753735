import Button from "components/core/Button";
import SelectPicker from "components/picker/Select";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import props, { PropsType } from "pages/search/props";
import profileProps from "pages/users/utils/profileProps";
import { useState } from "react";
import { RxReload } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

import Genders from "components/Genders";

const AdvancedGuestSearch = () => {
  const { options, countries } = useStorage();
  const navigate = useNavigate();

  const stringPersist = localStorage.getItem("landing-search-props");
  const persistedProps = stringPersist ? JSON.parse(stringPersist) : {};
  const initData = { ...props, ...persistedProps };

  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<PropsType>(initData);
  const [citiesList, SetCitiesList] = useState<string[]>([]);
  const [gender, setGender] = useState<"male" | "female">(
    persistedProps.gender || "male",
  );
  const isMale = gender === "male";

  const generateList = (start: number, end: number, label: string) =>
    Array.from({ length: end - start + 1 }, (_, index) => ({
      value: index,
      label: `${index + start} ${label}`,
    }));

  const childrenList = profileProps.children;
  const relationship_options = options.relationship_status_options[gender];
  const financial_status_options = options.financial_status_options[gender];
  const nationality_options = options.nationality_options[gender];
  const occupations_options = options.occupations_options[gender];
  const skin_color_options = options.skin_color_options[gender];
  const education_options = options.education_options[gender];
  const prayers_options = options.prayers_options[gender];
  const health_options = options.health_options[gender];
  const beauty_options = options.beauty_options[gender];
  const clan_options = options.clan_options[gender];
  const origin_options = options.origin_options[gender];
  const weightList = generateList(40, 150, "kg");
  const heightList = generateList(150, 250, "cm");

  const shapeOptions = (array: Array<string>) => {
    return array;
  };

  const countrySelected = (v: string[]) => {
    setData({ ...data, country: v, city: null });
    SetCitiesList(countries.find((e) => e.label === (v as any))?.cities || []);
    validate("country");
  };

  const ageList = () => Array.from({ length: 53 }, (_, i) => i + 18);

  const validate = (propName: string) => {
    if (errors.includes(propName))
      setErrors(errors.filter((e) => e !== propName));
  };

  const onSubmit = async () => {
    const values = { ...data };

    localStorage.setItem(
      "landing-search-props",
      JSON.stringify({ ...values, gender }),
    );

    const fromAge = +values.ageFrom! || "";
    const toAge = +values.ageTo! || "";
    if (!fromAge && toAge) values.age! = toAge + "_" + toAge;
    else if (!toAge && fromAge) values.age! = fromAge + "_" + fromAge;
    else if (toAge && fromAge) {
      if (toAge > fromAge) values.age = fromAge + "_" + toAge;
      else values.age = toAge + "_" + fromAge;
    }

    const fromHeight = (values.heightFrom! || "")
      .toString()
      .replace(/ cm/g, "");
    const toHeight = (values.heightTo! || "").toString().replace(/ cm/g, "");
    if (!fromHeight && toHeight) values.height = toHeight + "_" + toHeight;
    else if (!toHeight && fromHeight)
      values.height = fromHeight + "_" + fromHeight;
    else if (toHeight && fromHeight) {
      if (toHeight > fromHeight) values.height = fromHeight + "_" + toHeight;
      else values.height = toHeight + "_" + fromHeight;
    }

    const fromWeight = (values.weightFrom! || "")
      .toString()
      .replace(/ kg/g, "");
    const toWeight = (values.weightTo! || "").toString().replace(/ kg/g, "");
    if (!fromWeight && toWeight) values.weight = toWeight + "_" + toWeight;
    else if (!toWeight && fromWeight)
      values.weight = fromWeight + "_" + fromWeight;
    else if (toWeight && fromWeight) {
      if (toWeight > fromWeight) values.weight = fromWeight + "_" + toWeight;
      else values.weight = toWeight + "_" + fromWeight;
    }

    delete values.ageFrom;
    delete values.ageTo;
    delete values.weightFrom;
    delete values.weightTo;
    delete values.heightFrom;
    delete values.heightTo;

    type Key = keyof typeof values;
    for (const key in values) {
      if (!values[key as Key] || values[key as Key] === constants.NOT_REQUIRE)
        delete values[key as Key];
    }

    if (values.children)
      (values.children as number[]) = values.children.map(
        (v) => childrenList.find((e) => e.label === v)!.value,
      );

    if (["فتيات", "شباب"].includes(data.is_guardian! as string))
      values.is_guardian = false;
    else if (data.is_guardian && data.is_guardian.toString().includes("وكيل"))
      values.is_guardian = true;

    if (data.status === "نشط") values.status = true;
    else if (data.status === "غير نشط") values.status = false;
    else delete data.status;

    navigate("/guest/search/result?page=1", {
      state: { ...values, gender },
    });
  };

  const onReload = () => {
    localStorage.setItem("landing-search-props", "");
    setData(props);
    SetCitiesList([]);
  };

  const changeGender = (gender: "male" | "female") => {
    setGender(gender);
    setData(props);
    localStorage.setItem("landing-search-props", "");
  };

  return (
    <div className="px-4">
      <div className="relative my-5 flex w-full items-center justify-between">
        <div className="absolute -top-5 left-0 flex flex-col items-center justify-center gap-y-1">
          <RxReload
            onClick={onReload}
            className="btn btn-circle btn-sm p-1 text-primary"
          />
          <span className="text-xs">{constants.RESET}</span>
        </div>
      </div>

      <p className="mb-4 text-center text-xl font-bold">أبحث عن</p>
      <Genders
        is_guardian={false}
        withoutGuardian
        gender={gender}
        onClick={(item) => changeGender(item.gender as any)}
      />

      <div className="mb-22 mt-10 flex w-full max-md:flex-col max-md:items-center md:mb-5">
        <div className="flex w-1/2 flex-col gap-5 max-md:w-[85%]">
          <SelectPicker
            withSearch
            options={nationality_options}
            label={constants.NATIONALITY}
            value={data.nationality!}
            error={errors.includes("nationality")}
            setValue={(v) => {
              validate("nationality");
              setData({ ...data, nationality: v as string[] });
            }}
          />

          <SelectPicker
            withSearch
            options={countries.map((e) => e.label)}
            label={constants.COUNTRY}
            value={data.country!}
            error={errors.includes("country")}
            setValue={(v) => countrySelected(v as string[])}
          />

          <SelectPicker
            withSearch
            options={citiesList}
            label={constants.CITY}
            value={data.city!}
            error={errors.includes("city")}
            setValue={(v) => {
              validate("city");
              setData({ ...data, city: v as string[] });
            }}
          />

          <div>
            <p className="text-lg font-bold">{constants.AGE}</p>
            <div className="flex w-full justify-between gap-x-10">
              <SelectPicker
                options={shapeOptions(ageList().map((e) => e.toString()))}
                label={constants.FROM}
                value={data.ageFrom! as string}
                error={errors.includes("ageFrom")}
                setValue={(v) => {
                  validate("ageFrom");
                  if (v === constants.NOT_REQUIRE) {
                    validate("ageTo");
                    setData({ ...data, ageFrom: v, ageTo: v });
                  } else setData({ ...data, ageFrom: v as string });
                }}
              />
              <SelectPicker
                options={shapeOptions(ageList().map((e) => e.toString()))}
                label={constants.TO}
                value={data.ageTo! as string}
                error={errors.includes("ageTo")}
                setValue={(v) => {
                  validate("ageTo");
                  if (v === constants.NOT_REQUIRE) {
                    validate("ageFrom");
                    setData({ ...data, ageFrom: v, ageTo: v });
                  } else setData({ ...data, ageTo: v as string });
                }}
              />
            </div>
          </div>

          <div>
            <p className="text-lg font-bold">{constants.WEIGHT}</p>
            <div className="flex w-full justify-between gap-x-10">
              <SelectPicker
                options={shapeOptions(weightList.map((e) => e.label))}
                label={constants.FROM}
                value={data.weightFrom! as string}
                error={errors.includes("weightFrom")}
                setValue={(v) => {
                  validate("weightFrom");
                  if (v === constants.NOT_REQUIRE) {
                    validate("weightTo");
                    setData({ ...data, weightFrom: v, weightTo: v });
                  } else setData({ ...data, weightFrom: v as string });
                }}
              />
              <SelectPicker
                options={shapeOptions(weightList.map((e) => e.label))}
                label={constants.TO}
                value={data.weightTo! as string}
                error={errors.includes("weightTo")}
                setValue={(v) => {
                  validate("weightTo");
                  if (v === constants.NOT_REQUIRE) {
                    validate("weightFrom");
                    setData({ ...data, weightFrom: v, weightTo: v });
                  } else setData({ ...data, weightTo: v as string });
                }}
              />
            </div>
          </div>

          <div>
            <p className="text-lg font-bold">{constants.HEIGHT}</p>
            <div className="flex w-full justify-between gap-x-10">
              <SelectPicker
                options={shapeOptions(heightList.map((e) => e.label))}
                label={constants.FROM}
                value={data.heightFrom! as string}
                error={errors.includes("heightFrom")}
                setValue={(v) => {
                  validate("heightFrom");
                  if (v === constants.NOT_REQUIRE) {
                    validate("heightTo");
                    setData({ ...data, heightFrom: v, heightTo: v });
                  } else setData({ ...data, heightFrom: v as string });
                }}
              />
              <SelectPicker
                options={shapeOptions(heightList.map((e) => e.label))}
                label={constants.TO}
                value={data.heightTo! as string}
                error={errors.includes("heightTo")}
                setValue={(v) => {
                  validate("heightTo");
                  if (v === constants.NOT_REQUIRE) {
                    validate("heightFrom");
                    setData({ ...data, heightFrom: v, heightTo: v });
                  } else setData({ ...data, heightTo: v as string });
                }}
              />
            </div>
          </div>

          <SelectPicker
            multiple
            options={childrenList.map((e) => e.label.toString())}
            label={constants.CHILDREN}
            value={data.children! as string[]}
            error={errors.includes("children")}
            setValue={(v) => {
              validate("children");
              setData({ ...data, children: v as string[] });
            }}
          />

          <SelectPicker
            options={financial_status_options}
            label={constants.FINANCIAL_STATUS}
            value={data.financial_status!}
            error={errors.includes("financial_status")}
            setValue={(v) => {
              validate("financial_status");
              setData({ ...data, financial_status: v as string[] });
            }}
          />

          <SelectPicker
            options={shapeOptions([clan_options[0], clan_options[1]])}
            label={constants.CLAN}
            value={data.clan!}
            error={errors.includes("clan")}
            setValue={(v) => {
              validate("clan");
              setData({ ...data, clan: v as string[] });
            }}
          />
        </div>

        <div className="divider divider-horizontal mx-10 mt-2 max-md:hidden"></div>

        <div className="flex w-1/2 flex-col gap-5 max-md:mt-5 max-md:w-[85%]">
          <SelectPicker
            options={shapeOptions([
              isMale ? "فتيات" : "شباب",
              isMale ? "وكيل عن الفتاه" : "وكيل عن الشاب",
            ])}
            label={constants.GUARDIAN}
            value={data.is_guardian! as string}
            error={errors.includes("is_guardian")}
            setValue={(v) => {
              validate("is_guardian");
              setData({ ...data, is_guardian: v as string });
            }}
          />

          <SelectPicker
            options={shapeOptions(["نشط", "غير نشط"])}
            label={constants.STATUS}
            value={data.status! as string}
            error={errors.includes("status")}
            setValue={(v) => {
              validate("status");
              setData({ ...data, status: v as string });
            }}
          />

          <SelectPicker
            options={relationship_options}
            label={constants.RELATIONSHIP_STATUS}
            value={data.relationship_status!}
            error={errors.includes("relationship_status")}
            setValue={(v) => {
              validate("relationship_status");
              setData({ ...data, relationship_status: v as string[] });
            }}
          />

          <SelectPicker
            options={shapeOptions(origin_options)}
            label={constants.ORIGIN}
            value={data.origin!}
            error={errors.includes("origin")}
            setValue={(v) => {
              validate("origin");
              setData({ ...data, origin: v as string[] });
            }}
          />

          <SelectPicker
            options={health_options}
            label={constants.HEALTH}
            value={data.health!}
            error={errors.includes("health")}
            setValue={(v) => {
              validate("health");
              setData({ ...data, health: v as string[] });
            }}
          />

          <SelectPicker
            options={occupations_options}
            label={constants.OCCUPATION}
            value={data.occupation!}
            error={errors.includes("occupation")}
            setValue={(v) => {
              validate("occupation");
              setData({ ...data, occupation: v as string[] });
            }}
          />

          <SelectPicker
            options={shapeOptions(prayers_options)}
            label={constants.PRAYERS}
            value={data.prayers!}
            error={errors.includes("prayers")}
            setValue={(v) => {
              validate("prayers");
              setData({ ...data, prayers: v as string[] });
            }}
          />

          <SelectPicker
            options={shapeOptions(education_options)}
            label={constants.EDUCATION}
            value={data.education!}
            error={errors.includes("education")}
            setValue={(v) => {
              validate("education");
              setData({ ...data, education: v as string[] });
            }}
          />

          <SelectPicker
            options={shapeOptions(beauty_options)}
            label={constants.BEAUTY}
            value={data.beauty!}
            error={errors.includes("beauty")}
            setValue={(v) => {
              validate("beauty");
              setData({ ...data, beauty: v as string[] });
            }}
          />

          <SelectPicker
            options={shapeOptions(skin_color_options)}
            label={constants.SKIN_COLOR}
            value={data.skin_color!}
            error={errors.includes("skin_color")}
            setValue={(v) => {
              validate("skin_color");
              setData({ ...data, skin_color: v as string[] });
            }}
          />
        </div>

        <div className="my-5 mt-10 flex w-full justify-center md:hidden">
          <Button
            outline
            color="btn-primary"
            className="!px-20"
            onClick={onSubmit}
          >
            {constants.SEARCH}
          </Button>
        </div>
      </div>
      <div className="my-5 mt-10 hidden w-full justify-center md:flex">
        <Button
          outline
          color="btn-primary"
          className="!px-20"
          onClick={onSubmit}
        >
          {constants.SEARCH}
        </Button>
      </div>
    </div>
  );
};

export default AdvancedGuestSearch;
