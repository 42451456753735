const constants = {
  APP_NAME: "العفاف",
  APP_EMAIL: "",
  USERNAME: "إسم المستخدم",
  EMAIL: "البريد الإلكتروني",
  PHONE: "رقم الجوال",
  NEW_PHONE: "رقم الجوال الجديد",
  UPDATE_PHONE: "تحديث رقم الجوال",
  FAKE_NAME: "الاسم المستعار",
  NAME: "الاسم الكامل",
  PASSWORD: "كلمة المرور",
  CONFIRM_PASSWORD: "تأكيد كلمة المرور",

  ENTER_TOKEN_ID: "ادخل المعرف هنا",
  TOKEN_ID: "المعرف",
  REGISTER_BY_TOKEN: "تسجيل عن طريق معرف",

  ACCOUNT_TYPE: "نوع العضوية",
  ROUTE_NOT_EXIST: "هذه الصفحة غير موجودة",

  AUTO_INCREMENT: "التقدم التلقائي",

  CASH_PAY: "التحويل البنكي",
  TAP_PAY: "الدفع اونلاين",
  HAS_REQUESTED_PAYMENT: "طلب الدفع الخاص بك قيد المراجعة...",

  SING_FOR_FREE: "تسجيل عضوية جديدة",
  SING_NEW_TIME: "سجل مرة اخرى",
  CREATE_ACCOUNT: "إنشاء حساب",
  LOGOUT: "تسجيل الخروج",
  OUT: "خروج",
  LOGIN: "تسجيل الدخول",
  LOGIN_WITH_PHONE: "تسجيل عن طريق رقم الهاتف",
  LOGIN_WITH_EMAIL: "تسجيل عن طريق رقم البريد الالكتروني",
  GET_BACK: "إستعادة",
  GO_BACK: "العودة",

  DO_NOT_HAVE_ACCOUNY: "ليس لديك حساب ؟",
  HAVE_ACCOUNT: "لديك حساب ؟",
  FORGOT_PASS:
    "يرجى اخال رقم الجوال. ستتلقى بعدها رسالة تحتوي على رمز لإنشاء كلمة مرور جديدة.",
  RESET_PASS: "يرجى ادخال كلمة المرور الجديدة",
  RESET: "اعادة",
  SEND_REQUEST: "ارسال الطلب",
  SEND_PAY_REQUEST: "نعم، رفعت الحوالة",
  RESET_PASSWORD: "اعادة تعين كلمة المرور",
  REQUEST_RESET_PASS:
    "من فضلك أدخل بريدك الإلكتروني، ستستقبل عليه رابطاً لإعادة تعيين كلمة المرور",

  SPAM: "قد يصلك الرابط على البريد المهمل",
  VERIFY_YOUR_EMAIL: "أكد بريدك الالكتروني",

  SUCCESS: {
    CREATE: "تم الاضافة بنجاح",
    UPDATE: "تم التحديث بنجاح",
    REQUEST: "تم ارسال الطلب بنجاح",
    EMAIL_REQUEST: "تم ارسال رابط على بريد الالكتروني بنجاح",
  },
  ERRORS: {
    TOKEN_EXPIRED: "انتهت صلاحية المعرف",
    SESSION_EXPIRED: "انتهت صلاحية الجلسة",
    USER_NOT_EXIST: "هذا الحساب غير موجود",
    INTERNET_CONNECTION: "لايوجد اتصال بالسيرفر",
    REUPLOAD_YOUR_INFO: "تم رفض حسابك، يرجي اعادة رفع البيانات التالية بعناية",
    PHONE_EXIST: "للاسف هذا الجوال مسجل، ادخل رقما آخر.",
    INFO_UPLOAD: "حدث مشكل اثناء تحميل البيانات",
    PICTURE_UPLOAD: "حدث مشكل اثناء تحميل الصورة الشخصية.",
    CONDITIONS_AGREE: "المرجو الموافقة على جميع الشروط و الأحكام.",
    INVALID_INPUTS: "المرجو إخال كامل البيانات صحيحة",
    EMAIL_NOT_EXSIST: "هذا الرقم غير موجود.",
    EMAIL_EXSIST: "هذا الرقم موجود قم بتسجيل الدخول.",
    UNEXPECTED_ERROR: "حدث خطأ غير متوقع.",
    ADD_EMAIL: "اضف بريد الكتروني اولا",
    USER_NOT_FOUND: "تحقق من بريد الالكتروني وكلمة المرور أو قم بإنشاء حساب.",
    RESET_PASSWORD: "تحقق من بريد الالكتروني أو قم بإنشاء حساب.",
    WRONG_PASSWORD: "كلمة مرور خاطئة. حاول مرة أخرى أو قم بإعادة تعيينها.",
    FORGOT_PASSWORD: "نسيت كلمة المرور ؟",
    USER_REMOVED: "حسابك موقوف حاليا، يرجي مراسلة الادارة",
  },

  ALERT: {
    STORY_TITLE: "اكتب لنا عن تجربتك مع تطبيق العفاف",

    REMOVE_ACCOUNT: `سيتم حذف حسابك نهائيا بعد شهر من الان، كما يمكنك العود في اي وقت`,
    LIMIT_MESSAGES: `لقد تجاوزت العدد اليومي المسموح به من الرسائل`,
    ACCEPT_ENGAGE: `هل انت متاكدة من قبول هذه الخطبة`,
    CANCEL_ENGAGE: `هل انت متاكد من رفض هذه الخطبة`,
    DELETE_ENGAGE: `هل انت متاكد من حذف هذه الخطبة`,
    SEND_ENGAGE: `هل انت متاكد من ارسال هذه الخطبة`,
    SUBSCRIBE: `نفدت طلبات الخطبة لديك، جدد اشتراك الان`,
    REMOVE_ALL_NOTIFICATIONS: `هل انت متاكد من حذف كل الاشعارات`,
    SUBSCRIBE_FIRST: `يجب عليك الاشتراك حتي تتمكن من ارسال طلب خطبه`,
    REMOVE_ALL_CONVERSATION: `هل انت متاكد من حذف كل المحادثات`,
  },

  GUARDIAN_NAME: "اسم ولي اللامر",
  GUARDIAN_PHONE: "جوال ولي الامر",

  I_SWEAR: "اقسم",
  SWEAR: "اقسم بالله العظيم بأنني جاد وأن هدفي الزواج.",
  PLEDGE: "اتعهد بأن عمري أكبر من 18 عام وانني قرأت الشروط والاحكام.",
  TERMS: "الشروط والاحكام",
  PRIVACY: "سياسة الخصوصية",

  EMAIL_MATCH: "يجب إدخال البريد الالكتروني صحيح",
  EMAIL_REQUIRED: "يجب إدخال البريد الالكتروني",

  UID: "رقم العضوية",

  PHONE_MATCH: "يجب إدخال رقم هاتف صحيح (بدون رمز الدولة)",
  PHONE_REQUIRED: "يجب ادخال رقم الهاتف",

  TOKEN_REQUIRED: "يجب ادخال المعرف",

  PRE_PASSWORD_REQUIRED: "يجب ادخال كلمة المرور الحالية",
  NEW_PASSWORD_REQUIRED: "يجب ادخال كلمة المرور الجديدة",
  PASSWORD_REQUIRED: "يجب ادخال كلمة المرور",
  PASSWORD_LENGTH: "كلمة المرور تحتوي على 8 رموز على الأقل",

  CONFIRM_PASSWORD_MATCH: "كلامات المرور غير متطابقة",
  CONFIRM_PASSWORD_REQUIRED: "يجب إدخال تأكيد كلمة المرور",
  PREV_NEW_PASSWORDs: "كلامات المرور القديمة والجديدة متطابقة",

  REAL_NAME_REQUIRED: "يجب إدخال الاسم الحقيقي",
  RESIDENCE_REQUIRED: "يجب إدخال مكان الاقامة",

  NATIONAL_NUM: "رقم الهوية",
  NATIONAL_NUM_REQUIRED: "يجب إدخال رقم الهوية",
  NATIONAL_NUM_REQUIRED_LENGTH: "رقم الهوية يتكون على الاقل من 10 ارقام",

  BIRTHDAY: "تاريخ الميلاد",
  BIRTHDAY_REQUIRED: "يجب إدخال تاريخ الميلاد",

  WRITE_DESC: "اكتب وصف عن شريكك (يظهر للاعضاء في ملفك)",
  WRITE_BIO: "اكتب وصف عن نفسك (يظهر للاعضاء في ملفك)",
  DESCRIPTION_REQUIRED: "يجب إدخال الوصف",

  FOURTH_NAME: "الاسم الرباعي",
  FOURTH_NAME_REQUIRED: "يجب إدخال الاسم الرباعي",
  FOURTH_NAME_LENGTH: "الاسم الرباعي يتكون على الاقل من 10 حروف",

  NAME_REQUIRED: "يجب إدخال الاسم",
  NAME_LENGTH: "الاسم يتكون من 3 حروف الى 20",

  ACCOUNT_TYPE_REQUIRED: "يرجى تحديد النوع",
  AGE_REQUIRED: "يجب إدخال العمر",
  AGE_LENGTH: "العمر بين 18 و 53",

  PLEASE_FILL_INFO: "يرجى ملء البيانات التالية",

  // USERNAME_REQUIRED: "يجب إدخال الاسم المستخدم",
  // USERNAME_LENGTH: "الاسم المستخدم يتكون على الأقل من 3 حروف الى 8",
  ADULT_REQUIRE: "يجب موافقة على البنود",
  GENDER_REQUIRE: "يجب تحديد النوع",

  VERIFICATION_CODE: "من فضلك، أدخل رمز التأكيد",
  VERIFICATION_CODE_PHONE: "من فضلك، أدخل رمز التأكيد الذي وصلك على الهاتف",
  VERIFICATION_CODE_EMAIL:
    "من فضلك، أدخل رمز التأكيد الذي وصلك على البريد الالكتروني",

  //profile setps
  YOUR_PREFERENCES: "حدد مواصفاتك",
  YOUR_GUARDIAN_PREFERENCES: "حدد مواصفاتك موكلك",
  EDIT_YOUR_PREFERENCES: "تعديل مواصفاتك",
  COMPLETE_YOUR_SIGNUP: "(اكمال المعلومات الخاصة بالتسجيل)",
  PARTNER_PREFERENCES: "حدد مواصفات شريكك",
  YOUR_GUARDIAN_PARTNER_PREFERENCES: "حدد مواصفات شريك موكلك",
  EDIT_PARTNER_PREFERENCES: "تعديل مواصفات شريكك",
  SELECT_GENDER: "حدد جنسك",
  SELECET_IMG_NAME: "حدد صورة والاسم",
  BIRTH_AFFILIATION: "الميلاد و الانتماء",
  STATUS: "الحالة",
  GUARDIAN: "فئة",
  GUARDIAN_USER: "وسيط",
  NOT_GUARDIAN: "غير الوكلاء",
  SEMBLANCE: "المظهر",
  BIO: "الوصف",
  PARTNER_DES: "أكتب عن مواصفات شريكك",
  YOUR_BIO: "أكتب عن نفسك",
  NATIONALITY: "الجنسية",
  CHOOSE: "إختر",
  COUNTRY: "الإقامة",
  RELATIONSHIP_STATUS: "الحالة الإجتماعية",
  FINANCIAL_STATUS: "الحالة المادية",
  HEALTH: "الحالة الصحية",
  PRAYERS: "الصلاة",
  EDUCATION: "المستوى التعليمي",
  OCCUPATION: "طبيعة العمل",
  BEAUTY: "الجمال",
  SKIN_COLOR: "لون البشرة",
  AGE: "العمر",
  WEIGHT: "الوزن",
  HEIGHT: "الطول",
  FROM: "من",
  TO: "إلى",
  NOT_REQUIRE: "لا يشترط",
  NOT_FOUND: "لا يوجد",
  CITY: "المدينة",
  CLAN: "القبيلة",
  ORIGIN: "الأصل",
  CHILDREN: "عدد الأطفال",
  MULTI_MARRIAGE: "تقبلين التعدد",
  MULTI_MARRIAGE_ASK: "تقبل التعدد",

  MALE: "ذكر",
  FEMALE: "أنثى",

  NEXT: "التالي",
  PREVIOUS: "السابق",
  CONFIRM: "تأكيد",
  CLEAR: "مسح",
  CLOSE: "الغاء",
  OK: "حسنا",
  SAVE: "حفظ",
  EDIT: "تعديل",
  UPDATE: "تحديث",

  EDIT_EMAIL: "تحديث البريد",

  THANKS: "شكرا لك",
  WILL_CONTACT_YOU_AFTER_12H:
    "لقد تم حفظ معلوماتك وسوف يتم الرد عليك خلال 6 ساعات على الأكثر هنا. نسأل الله ان يرزقكم حسن الاختيار ونفرح لكم بالزواج المبارك إن شاء الله.",
  WHATSAPP_CONTACT: "يمكنك مراسلتنا من خلال الواتس اب عبر الرقم",
  UNDER_PROCESSING: "حسابك قيد المراجعة، سيتم معالجة طلبك في غضون 6 ساعات.",

  MULTI_SELECT: "يمكنك اختيار أكثر من خيار",

  PENDING_DESCRIPTION: "الوصف الجديد قيد المراجعة",
  EDIT_INFO_BY_PRESS: "حدث أي معلومة بالنقر عليها",

  PENDING_ACCOUNT:
    "حسابك قيد المراجعة، سيتم معالجة طلبك في غضون 6 ساعات. في حالة رفض الطلب سيتعذر عليك اعادة رفع معلوماتك بحيث تكون صحيحة",

  BECAREFULL:
    "المرجو الإنتباه إلى عدم إرسال أي كلمات غير لائقة أو وسائل تواصل خارج الموقع",

  MANGMENT_MESS: "رسائل الادارة",
  MEMBERS_MESS: "رسائل الاعضاء",

  PRE_PASSWORD: "كلمة المرور الحالية",
  NEW_PASSWORD: "كلمة المرور الجديدة",
  CONFIRM_NEW_PASSWORD: "تأكيد كلمة المرور الجديدة",

  REQUEST_NEW_URL: "اطلب رابط اخر",
  EXPIRY_URL: "انتهت صلاحية الرابط",

  PREF_DESC_INFO:
    "نص الوصف الخاص بشريكك تحت المراجعة, سوف يتم إعتماده بمجرد الموافقة عليه",
  YOUR_DESC_INFO:
    "نص الوصف الخاص بك تحت المراجعة, سوف يتم إعتماده بمجرد الموافقة عليه",

  REAL_INFO: "المعلومات الحقيقية",
  UPLOAD_REAL_INFO: "يرجى رفع المعلومات الحقيقية الخاصة بك قبل الدفع",
  PAYMENT: "الدفع",
  CASH_PAYMENT_INFO: "بيانات الدفع اليدوي",

  FILL_INFO: "ملء البيانات",
  UNBLOCK: "فك الحظر",
  MY_INFO: "مواصفاتي",
  MY_INFO_IN_DETAILS: "هذه معلومات أخرى تفصيلية عن شخصيتي",
  MY_INFO_IN_DETAILS_HISTORY: "وهذه نبذة تفصيلية عن مواصفاتى",
  MY_CONDITIONS: "شروطي",
  MY_CONDITIONS_IN_DETAILS: "هذه معلومات أخرى تفصيلية عن شخصيتي",
  MY_CONDITIONS_IN_DETAILS_HISTORY: "وهذه نبذة تفصيلة عن شروطي",
  BLOCK: "حظر",
  REPORT_VIOLATION: "أبلغ عن العضو",

  OTP_NOT_SEND: "لم يصل؟",
  OTP_RESENT: "تم ارسال الرمز مجددا.",
  ENTER_CODE: "ادخل الرمز",

  SEND: "ارسال",
  SEND_LINK: "ارسال رابط",
  SEND_MESSAGE: "ارسال رسالة",
  SEND_NEW_MESSAGE: "ارسال رسالة جديدة",
  SEND_NEW_SUPPORT_MESSAGE: "ارسال رسالة جديدة الى دعم الفني",
  RESEND: "اعد الارسال",
  VIOLATION_EXPLANATION: "شرح المخالفة التي تسبب بها العضو",

  SAVE_AS_FAVORITE: "تم إضافة إلى القائمة المفضلة.",
  UNSAVE_FROM_FAVORITE: "تم إلغاء من القائمة المفضلة.",

  SEND_TO: "أنت على وشك إرسال طلب خطبة للعضو",
  NOTIFY_FOR_BLOCK:
    "يمكنك مراسلة باقي أعضاء الموقع ولكن، بمجرد قبول الفتاة لطلب خطبتك فسوف يتم تعليق عضويتكما و لن يمكنكما مراسلة سوى بعضكما البعض.",

  NOTIFICATIONS: "قائمة المحادثات",
  MATCHED_USERS: "الاعضاء المطابقين لي",
  FORME_USERS: "الاعضاء المناسبين لي",
  FAVORITES_USERS: "الاعضاء المفضلين",
  BLOCKED_USERS: "الاعضاء المحظورين",
  ENGAGED_USERS: "قائمة الخطبة",
  VISITED_USERS: "الاعضاء الزائرين",
  LIKED_USERS: "الاعضاء المعجبين بي",
  HISTORY_USERS: "القصص الناجحة",
  FEATURES: "مميزاتنا",

  STORY: "حكاية",
  MENU: "نقاط",

  ENGAGE: "خطبه",
  ENGAGE_COUNTER: "عداد الخطبه",

  ENGAGE_REQUEST: "اطلب خطبه",
  LAST_VISIT: "آخر ظهور",

  EMPTY_VISITS_LIST: "لم يزر اي شخص ملفك بعد.",
  EMPTY_LIKES_LIST: "قائمة المعجبين الخاص بك فارغة.",
  EMPTY_MATCHED_LIST: "قائمة الاعضاء المطابقين الخاص بك فارغة.",
  EMPTY_FORME_LIST: "قائمة الاعضاء المناسبين الخاص بك فارغة.",

  SORRY: "للاسف",
  EMPTY_HISTORY_LIST: "للاسف لايوجد قصص ناجحة الان.",
  EMPTY_BLOCK_LIST: "قائمة الحظر الخاص بك فارغة.",
  EMPTY_FAVORITE_LIST: "لم تقم بإضافة أي شخص لمفضلتك بعد.",
  EMPTY_EMAIL_LIST: "لا يوجد لديك أي رسائل",
  EMPTY_NOTIFICATION_LIST: "لا يوجد لديك أي اشعارات",
  EMPTY_ENGAGEMENT_LIST: "قائمة الخطبة الخاصة بك فارغة.",
  EMPTY_SEARCH_LIST: "لايوجد نتائج للبحث.",
  EMPTY_USERS: "لايوجد اعضاء.",

  WHO_VIEW_MYPROFILE: "شاهد ملفي",
  WHO_LIKE_ME: "أعجب بي",

  BASIC_SEARCH: "البحث السريع",
  ADVANCE_SEARCH: "البحث المتقدم",
  SEARCH: "البحث",
  SEARCH_BY_NAME: "ابحث بواسطة الاسم",
  SEARCH_RESULT: "نتائج البحث",

  DETAIL: "تفاصيل",
  PLUS: "المزيد",
  FILTER: "فلترة",
  SUBSCRIBE_NOW: "اشترك الان",
  SUBSCRIBER: "موثق",
  ONLINE_NOW: "متصل الان",
  ONLINE_FEMALE_USERS: "فتيات تواجدو اليوم",
  ONLINE_MALE_USERS: "شباب تواجدو اليوم",
  ONLINE: "حالتك متصل الان",
  OFFLINE: "حالتك غير متصل الان",
  CHANGE_STATUS: "تغير الحالة",

  CONNECTED: "متصل",
  UNCONNECTED: "غير متصل",

  RECEIVE_NOTIFICATION: "تلقي الاشعارات",
  ENABLE: "فتح",
  DISABLE: "غلق",

  MESSAGE_REMOVED: "رسالة محذوفة",
  IMAGE_VISIBILITY:
    "الصور المرفقه من جهازك لا يستطيع أحد من الاعضاء رؤيتها إلا في حالة الخطبه فقط ولا تظهر إلا الصور المرفقه من الموقع",
  BACK_FROM_REMOVE: {
    male: "اهلا بعودتك، نسأل الله أن يرزقك الزوجة الصالحة",
    female: "اهلا بعودتك، نسأل الله أن يرزقك الزوج الصالح",
  },
  EMAIL_VERIFICATION: "يرجى اكمال التحقق من البريد الالكتروني",
  VERIFY: "تحقق",
  VERIFY_NOW: "تحقق الآن!",
  VERIFY_FIRST:
    "يرجى اكمال التحقق من البريد الالكتروني حتى تتمكن من استعمال باقي ميزات الموقع",

  GO_TO_HOME: "ادخل الى حسابك",
  CONGRATULATION: "تهانينا",
  WELCOME: "مرحبا",
  WELCOMING:
    "تهانينا تم قبول حسابك، نسأل الله ان يرزقكم الشريك المناسب ونفرح لكم قريبا",
  PROFILE_PIC: "الصورة الشخصية",
  PROFILE_PIC_NOT:
    "الصور المرفوعه من جهازك لا تظهر للاعضاء الا في حالة الخطبه فقط",
  REMOVED_USER: "تم رفض حسابك",
  REMOVED_USER_DESC:
    "للاسف تم رفض حسابك من قبل الادارة نرجو منك ان تعيد التسجيل من جديد بمعلومات ادق وان تاخذ الامر بجدية اكثر. لمعرفة سبب الحذف يرجي مراجعة بريدك الالكتروني.",
  PAY_THROUGH_THE_STORE: "دفع عن طريق المتجر",
  PAY_THROUGH_STRIPE: "دفع عن طريق stripe",

  HOLD_TO_RECORD: " لبدء التسجيل، اضغط مطولا.",
  START_RECORDING: "اضغط للتسجيل.",
  PERMANENT_DELETION: "حذف نهائي للحساب",
  REMOVE: "حذف",
  CANCEL: "الغاء",
  VOICE_MESSAGE: "رسالة صوتية",

  REMEMBER_ME: "تذكرني",
  REMINDER: "تذكير",

  BAR_HOME: "الرئيسية",
  BAR_SEARCH: "البحث",
  BAR_MESSAGES: "الرسائل",
  BAR_NOTIFICATIONS: "الاشعارات",
  BAR_VISITS: "شاهد ملفي",
  BAR_LIKES: "اعجب بي",
  BAR_SUPPORT: "الدعم الفني",
  BAR_ENGAGE: "الخطبة",
  BAR_MENU: "القائمة",
  PROFILE: "الملف",
  BAR_CONVERSATIONS: "قائمة الرسائل",

  THEME_SWITCH: "تغير الوضع",
  QUICK_REPLIES: "الرسائل المحفوظة",
  WRITE_MESSAGE: "اكتب رسالة",
  IN_ARABIC: "بالعربية",

  ARE_YOU_SURE: "هل انت متاكد من هذا الاجراء؟",
  REMOVE_REASON: "اكتب سبب الحذف",
  WRITE_STORY: "اكتب لنا عن تجربتك مع تطبيق العفاف",

  FIND_SUGGESTION: "ماذا يقول الخاطب للاهل عن طريقة التعارف",

  COMPLETE_NAME_REQUIRED: "يجب إدخال الاسم الكامل",
  COMPLETE_NAME_LEGTH: "الاسم الكامل يتكون على الاقل من 10 حروف",

  GUARDIAN_NAME_REQUIRED: "يجب إدخال اسم ولي الامر",
  GUARDIAN_NAME_LENGTH: "اسم ولي الامر يتكون على الاقل من 3 حروف",

  FIND_SUGGESTION_REQUIRED: "يجب إدخال اقتراح التعارف",

  REMOVE_ALL: "حذف الكل",

  LAST_MEMBERS: "الاعضاء الاحدث تواجد",
  NEW_MEMBERS: "الاعضاء الجدد",
  PROPOSAL_MEMBERS: "الاعضاء المقترحين",
  MORE: "المزيد",

  MESSAGE_TITLE: "عنوان الرسالة",
  MESSAGE_CONTENT: "محتوى الرسالة",

  ADD_MEMBER: "اضافة عضو",
  ADD_MESSAGE: "اضافة رسالة",
  ADD_TO_FAVORITE: "اضف للمفضله",
  REMOVE_FROM_FAVORITE: "الغاء من المفضله",

  CHANGE_PASSWORD: "تغير كلمة المرور",
  GENERAL_UPDATE: "تعديل الحساب",
  CHOOSE_RIGHT_PICTURE: "اختر صورة قريبة لك",

  AFTER: "بعد",
  NAME_IN_ARABIC_ONLY: "الاسم بالاحرف العربية فقط",
  GOT_NOTIFY_TO_IT: "يصلك رابط تأكيد عليه",

  AGGRY_FROM_DSHBOARD: "تم تأكيد الرقم من قبل الادارة",

  YOUNG: "شاب",
  GIRL: "فتاة",
  YOUNG_GUARDIAN: "وكيل عن شاب",
  GIRL_GUARDIAN: "وكيل عن فتاة",

  TOAST: {
    COPY_UID: "تم نسخ الرابط",
    EMAIL_SENT_SUCCESSFULLY: "تم ارسال الرابط التحقق",

    ACCOUNT_CANCELED: "تم رفض حسابك",
    ACCOUNT_REMOVED: "تم حذف حسابك",
    ACCOUNT_RESET: "تم استعادة حسابك",

    REMOVE_FROM_FAVORITE: "تم الحذف من المفضلة",
    ADD_TO_FAVORITE: "تم الاضافة الى المفضلة",

    ADD_TO_BLOCKED: "تم الحظر",
    REMOVE_FROM_BLOCKED: "تم الغاء الحظر",
    NOTIFICATIONS_REMOVED: "تم حذف الكل الاشعارات",

    THANKS_FOR_REPORTING: "تم ابلاغ بنجاح، شكرا لك",

    PENDING_RESPONSE: "في انتظار موافقة الادارة...",
    UPLOAD_SUCCESSFUL: "شكرا، تم رفع معلومات",
    UPDATE_SUCCESSFUL: "تم تحديث معلوماتك...",
    UPDATE_PREF_SUCCESSFUL: "تم تحديث معلومات شريكك...",
    UPDATE_PASS_SUCCESSFUL: "تم تحديث كلمة سر بنجاح",

    REQUEST_SENT: "تم ارسال الطلب بنجاح",
    GIRL_ENGAGED: "الفتاة في حالة خطبة",
    PENDING_GIRL_ACCEPTING: "طلبك في الانتظار موافقة الفتاه",
    GIRL_ENGAGED_PROFILE: `عفوا العضو في حالة خطبة يمكنك التقدم لاحقا اذا تم الغاء الحالة`,
    ONE_REQUEST_ALLOWED: `عفوا لايمكنك التقدم بأكثر من طلب خطبه`,

    STORY_SAVED: "تم حفظ قصة النجاح",
    INFO_SENT: "تم ارسال البيانات بنجاح",
    PAY_SUCCESSFUL: "تم الاشتراك بنجاح تهانينا",

    USER_BLOCKED_YOU: `عفوا لقد قمت بحظر هذا العضو`,
    REPORT_SUCCESSFUL: "تم ابلاغ الادارة، شكرا",
    YOU_HAVE_REPORT_BEFORE: "تم ابلاغ عنها سابقا",

    SEND_OTP: "حدث خطأ اثناء ارسال رمز التحقق",
  },

  REMOVE_BECAUSE: "حذفت حسابي بسبب: ",
  WHY_REMOVE_YOUR_ACCOUNT: "اكتب لنا سبب حذف حسابك",

  REMOVED_CONTENT: "تم حذف رسالتك",
  CONTACT: "مراسلة",
  NEW_MESSAGE: "رسالة جديدة",
  SUPPORT_NEW_MESSAGE: "رسالة من الدعم الفني",
  LOAD_MORE: "تحميل اكثر",

  MESSAGE_SENT: "تم ارسال الرسالة",
  CONVERSATION_DELETED: "تم مسح المحادثة",
  ALL_CONVERSATION_DELETED: "تم حذف الكل المحادثات",

  ARABIC_COUNTRY_CODE: [
    "sa",
    "eg",
    "bh",
    "dz",
    "ma",
    "tn",
    "iq",
    "jo",
    "kw",
    "lb",
    "ps",
    "ly",
    "ae",
    "mr",
    "om",
    "qa",
    "sd",
    "sy",
    "ye",
  ],
};
export default constants;
