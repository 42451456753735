import Button from "components/core/Button";
import Input from "components/core/Input";
import constants from "configs/constants";
import AdvancedSearch from "pages/search/Advanced";
import BasicSearch from "pages/search/Basic";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type SearchType = "username" | "basics" | "advanced";

const SearchPage = () => {
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<SearchType>(
    (params.get("type") as any) || "username",
  );

  const username = useRef(params.get("name") || "");
  const onSearchByName = () => {
    const name = username.current.trim();
    if (name) {
      setSearchParams({ type: currentStep, name });
      navigate("/search/result?page=1", {
        state: { name: name.trim() },
      });
    }
  };

  const onChangeSearchType = (type: SearchType) => {
    return () => {
      setSearchParams({ type });
      setCurrentStep(type);
    };
  };

  const UsernameInput = () => {
    const [name, setName] = useState(params.get("name") || "");

    return (
      <Input
        value={name}
        setValue={(v) => {
          setName(v);
          username.current = v;
        }}
        placeholder={constants.SEARCH_BY_NAME}
      />
    );
  };

  const steps = {
    username: {
      label: constants.SEARCH_BY_NAME,
      Step: () => (
        <div className="flex flex-col items-center justify-center gap-y-10 px-4 pt-5 md:px-16">
          <UsernameInput />
          <Button
            outline
            size="btn-sm"
            onClick={onSearchByName}
            color="btn-primary"
            className="!min-w-[6rem] !rounded-full"
          >
            {constants.SEARCH}
          </Button>
        </div>
      ),
    },
    basics: {
      label: constants.BASIC_SEARCH,
      Step: () => <BasicSearch />,
    },
    advanced: {
      label: constants.ADVANCE_SEARCH,
      Step: () => <AdvancedSearch />,
    },
  };

  useEffect(() => {
    const value = params.get("type") as SearchType;
    if (value && value !== currentStep) setCurrentStep(value as SearchType);
  }, [params]);

  return (
    <div className="mx-auto max-w-3xl p-4 max-sm:mb-24">
      {Object.entries(steps).map(([key, { label, Step }], i) => {
        const rounded = ["rounded-b-none", "rounded-none", "rounded-t-none"];

        return (
          <div
            key={key}
            className={`collapse collapse-plus bg-primary bg-opacity-10 ${rounded[i]}`}
          >
            <input
              type="radio"
              name="my-accordion"
              defaultChecked={key === currentStep}
              onChange={onChangeSearchType(key as SearchType)}
            />
            <div className="collapse-title text-xl font-medium">{label}</div>
            <div className="collapse-content bg-white p-0">
              <Step />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SearchPage;
