import Avatar from "components/Avatar";
import HorizontalSkeletons from "components/theme/skeleton/horizontal";
import constants from "configs/constants";
import { useLandingStories } from "queries/stories";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1280 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1280, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

const stories = [
  {
    user: {
      name: "لبن بريدة",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/male/17",
    },
    content:
      "بفضل الله تعالى ثم بفضلكم تم الزواج من ماريه. اسأل الله التوفيق والسداد وأن يجمع بينا بخير. جزاكم الله خير.",
    createdAt: new Date(),
  },
  {
    user: {
      name: "محمد111",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/male/19",
    },
    content:
      "الحمدالله تم الزواج .. حولونى الى الحالات الناجحة الله يبارك فيكم.",
    createdAt: new Date(),
  },
  {
    user: {
      name: "زوجة لرجل عظيم",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/female/9",
    },
    content:
      "تم الارتباط ولله الحمد والشكر. نشكر لكم جهودكم الكريمه واسأل الله أن يجعلها في ميزان حسناتكم.",
    createdAt: new Date(),
  },
  {
    user: {
      name: "ياسمين الورد و الفل",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/female/5",
    },
    content:
      "السلام عليكم لقد تم عقد قرائي علي العضو عبدالله4. الله يباركم فيكم ويجعله فى موازين حسناتكم.",
    createdAt: new Date(),
  },
  {
    user: {
      name: "هشام وهبه",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/male/7",
    },
    content: "تم عقد الزواج مع فاطنة.",
    createdAt: new Date(),
  },
  {
    user: {
      name: "الحربي33",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/male/15",
    },
    content:
      "من اعماق قلبنا نقول لكم شكرًا. لقد جمعني الله بيني وبين من اخترتها شريكة لي.",
    createdAt: new Date(),
  },
  {
    user: {
      name: "طالب من الرياض",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/male/12",
    },
    content:
      "أسأل الله أن يبارك عملكم وبجزاكم خير الجزاء. تم زواجي على من كتبها الله زوجة لي.",
    createdAt: new Date(),
  },
  {
    user: {
      name: "أبحث عن فتاة دينه ملتزمة",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/male/20",
    },
    content:
      "الحمدلله تم بعون الله عقد قراني على زوجتى. سنقوم بإغلاق الحساببن .. شكرا جزيلا لكم.",
    createdAt: new Date(),
  },
  {
    user: {
      name: "رجل استثنائي",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/male/13",
    },
    content:
      "الله يجزاكم خير على الموقع الجميل هذا. تمت خطوبتي الى فتاة صالحة.",
    createdAt: new Date(),
  },
  {
    user: {
      name: "1445",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/male/8",
    },
    content: "تم بحمد الله الزواج بيني وبينها.",
    createdAt: new Date(),
  },
  {
    user: {
      name: "للبوشري",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/male/10",
    },
    content:
      "لقد تم بحمد الله ومنه زواجي على العضو الذي اتواصل معه الأن وزواجنا قريب ..",
    createdAt: new Date(),
  },
  {
    user: {
      name: "عرييس",
      profile_picture:
        "https://www.khotoba.net/api/v2/configs/profile_pictures/male/15",
    },
    content: "بحمد الله تم عقد قراني من خلال الموقع.",
    createdAt: new Date(),
  },
];

interface IDot {
  onClick: () => void;
  active: boolean;
}

const Stories = () => {
  const { isLoading, isError } = useLandingStories();

  const CustomDot = ({ onClick, active }: Partial<IDot>) => {
    return (
      <button
        onClick={onClick}
        className={`mx-[2px] mb-4 h-3 w-3 rounded-full transition-all ${
          active ? `bg-primary` : "bg-gray-300"
        }`}
      />
    );
  };

  if (isError || (stories && !stories.length)) return null;
  return (
    <section className="mx-auto mb-20 mt-12 max-w-5xl rounded-3xl">
      <p className="mr-5 text-center text-3xl font-bold text-primary">
        {constants.HISTORY_USERS}
      </p>

      <div className="divider divider-vertical mx-auto my-0 w-20 before:bg-primary after:bg-primary"></div>

      <div className="rounded-box mx-auto mt-5 w-[90%] overflow-hidden p-1 px-4 transition-all">
        {isLoading ? (
          <HorizontalSkeletons />
        ) : (
          <Carousel
            rtl
            infinite
            autoPlay
            showDots
            customDot={<CustomDot />}
            arrows={false}
            autoPlaySpeed={4000}
            responsive={responsive}
          >
            {stories.map((story, i) => (
              <div
                key={i.toString()}
                className="m-2  flex-col items-center rounded-3xl border-[0.1rem] border-gray-100 bg-white p-1 shadow-lg md:mx-auto md:max-h-96 md:w-80"
              >
                <div className="overflow-hidden">
                  <div className="relative flex w-full items-center justify-center p-2">
                    <Avatar size="md" src={story.user.profile_picture} />
                  </div>

                  <p className="truncate text-center text-xl font-bold text-primary">
                    {story.user.name}
                  </p>
                </div>

                <div className="mt-8 flex flex-col px-2">
                  <FaQuoteRight className="self-start text-xl text-primary" />
                  <p className="mx-6 line-clamp-6 text-justify font-medium">
                    {story.content}
                  </p>
                  <FaQuoteLeft className="self-end text-xl text-primary" />
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </section>
  );
};

export default Stories;
