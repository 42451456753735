import { useRef, useState } from "react";
import * as Yup from "yup";

import Button from "components/core/Button";
import CheckBox from "components/core/CheckBox";
import schema from "components/forms/schema";
import constants from "configs/constants";
import { useNavigate } from "react-router-dom";

import Captcha, { CaptchaRef } from "components/Captcha";
import { UseFormHandleSubmit } from "react-hook-form";

interface Props {
  loading: boolean;
  onSubmit: (values: any) => void;
  handleSubmit: UseFormHandleSubmit<any>;
}

const RegisterFooter = ({ loading, onSubmit, handleSubmit }: Props) => {
  const navigate = useNavigate();

  const captchaRef = useRef<CaptchaRef>(null);
  const [errorAdult, setErrorAdult] = useState(false);
  const [approved, setApproved] = useState(false);
  const [isAdult, setIsAdult] = useState(false);

  function onFirstCheckBoxClick() {
    if (!approved && isAdult && errorAdult) setErrorAdult(false);
    setApproved(!approved);
  }

  function onSecondCheckBoxClick() {
    if (approved && !isAdult && errorAdult) setErrorAdult(false);
    setIsAdult(!isAdult);
  }

  function onFormSubmit(values: Yup.InferType<typeof schema.register>) {
    if (!approved || !isAdult) {
      if (!errorAdult) {
        setErrorAdult(true);
        setTimeout(() => setErrorAdult(false), 2000);
      }
      return null;
    }
    if (captchaRef.current?.isValid) {
      captchaRef.current?.refreshCaptcha();
      onSubmit(values);
    } else captchaRef.current?.setError(true);
  }

  return (
    <>
      <div className="flex flex-col gap-x-2">
        <CheckBox
          color="checkbox-primary"
          size="checkbox-sm"
          title={constants.SWEAR}
          checked={approved}
          error={errorAdult}
          toggle={onFirstCheckBoxClick}
        />
        <CheckBox
          color="checkbox-primary"
          size="checkbox-sm"
          title={constants.PLEDGE}
          checked={isAdult}
          error={errorAdult}
          toggle={onSecondCheckBoxClick}
        />
      </div>

      <Captcha ref={captchaRef} />

      <div className="flex flex-row justify-center gap-x-4">
        <span
          onClick={() => navigate("/login")}
          className="link-hover link text-xs hover:text-primary"
        >
          {constants.HAVE_ACCOUNT}
        </span>

        <span
          onClick={() => navigate("/register/token")}
          className="link-hover link text-xs hover:text-primary"
        >
          {constants.REGISTER_BY_TOKEN}
        </span>
      </div>

      <Button
        outline
        loading={loading}
        className="w-[80%]"
        color="btn-primary"
        onClick={handleSubmit(onFormSubmit)}
      >
        {constants.CREATE_ACCOUNT}
      </Button>
    </>
  );
};

export default RegisterFooter;
