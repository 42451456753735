import ring from "assets/ring.png";
import useStatistics from "queries/statistics";

function formatNumber(number: number) {
  if (number < 1000) {
    return number.toString();
  } else if (number < 1000000) {
    const thousands = number / 1000;
    return thousands % 1 === 0
      ? thousands.toFixed(0) + "K"
      : thousands.toFixed(1) + "K";
  } else {
    const millions = number / 1000000;
    return millions % 1 === 0
      ? millions.toFixed(0) + "M"
      : millions.toFixed(1) + "M";
  }
}

const Statistics = () => {
  const { statistics, isLoading, isError } = useStatistics();

  if (isLoading || isError || !statistics) return null;
  return (
    <section>
      <p className="mt-12 text-center text-3xl font-bold text-primary">
        احصائيات عن الموقع
      </p>
      <div className="divider divider-vertical mx-auto my-0 w-20 before:bg-primary after:bg-primary"></div>
      <div className="relative mt-5 flex h-96 max-w-4xl overflow-hidden sm:mx-auto sm:h-[26rem]">
        <div className="overflow-hidden sm:h-96">
          <img src={ring} className="h-56 px-2 sm:h-auto sm:px-0" />
        </div>
        <div className="absolute left-0 top-[5%] w-full sm:top-[48%]">
          <div className="mx-auto flex w-[80%] flex-col gap-2 sm:w-full sm:flex-row sm:justify-end sm:px-5">
            <div className="rounded-3xl border-4 p-1 md:w-1/4">
              <div className="flex items-center justify-between gap-2 rounded-2xl border-2 border-primary bg-base-100 p-4 sm:h-48 sm:flex-col-reverse sm:justify-evenly">
                <p className="text-center text-base">عدد المسجلين من الذكور</p>
                <p
                  style={{ direction: "ltr" }}
                  className="text-xl font-bold text-primary sm:text-3xl"
                >
                  {formatNumber(statistics.male)}
                </p>
              </div>
            </div>

            <div className="rounded-3xl border-4 p-1 md:w-1/4">
              <div className="flex items-center justify-between gap-2 rounded-2xl border-2 border-primary bg-base-100 p-4 sm:h-48 sm:flex-col-reverse sm:justify-evenly">
                <p className="text-center text-base">عدد المسجلين من الإناث</p>
                <p
                  style={{ direction: "ltr" }}
                  className="text-xl font-bold text-primary sm:text-3xl"
                >
                  {formatNumber(statistics.female)}
                </p>
              </div>
            </div>

            <div className="rounded-3xl border-4 p-1 md:w-1/4">
              <div className="flex items-center justify-between gap-2 rounded-2xl border-2 border-primary bg-base-100 p-4 sm:h-48 sm:flex-col-reverse sm:justify-evenly">
                <p className="text-center text-base">عدد المتواجدين اليوم</p>
                <p
                  style={{ direction: "ltr" }}
                  className="text-xl font-bold text-primary sm:text-3xl"
                >
                  {formatNumber(statistics.male_online + statistics.female)}
                </p>
              </div>
            </div>

            <div className="rounded-3xl border-4 p-1 md:w-1/4">
              <div className="flex items-center justify-between gap-2 rounded-2xl border-2 border-primary bg-base-100 p-4 sm:h-48 sm:flex-col-reverse sm:justify-evenly">
                <p className="text-center text-base">عدد حالات ناجحة</p>
                <p
                  style={{ direction: "ltr" }}
                  className="text-xl font-bold text-primary sm:text-3xl"
                >
                  {formatNumber(statistics.stories)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics;
