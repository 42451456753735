import constants from "configs/constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import TProfile from "types/TProfile";

import profiles from "api/profiles";
import { UpdateProfile } from "api/types/users";
import AccountBuilder from "components/builder/Account";
import useToast from "hooks/useToast";
import useUser from "queries/user";
import hasKeys from "utils/hasKeys";

function EditProfile() {
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  const profile: TProfile = user.profile || {};
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmit = async (values: TProfile) => {
    setLoading(true);

    const props: Partial<TProfile> = { ...values };

    Object.keys(profile).forEach((key) => {
      const previous = profile[key as keyof typeof values];
      const current = values[key as keyof typeof values];

      if (current !== undefined && current === previous)
        delete props[key as keyof typeof props];
    });

    if (hasKeys(props)) {
      const hasBio = !!props.bio;
      const update = await profiles.update(props as UpdateProfile);
      if (hasBio) {
        props.pending_bio = props.bio;
        delete props.bio;
      }

      if (update.ok) {
        setUser({ profile: { ...profile, ...props } });
        if (hasBio) toast.success(constants.TOAST.PENDING_RESPONSE);
        else toast.success(constants.TOAST.UPDATE_SUCCESSFUL);
        navigate("/");
      } else toast.error(constants.ERRORS.UNEXPECTED_ERROR);
    }

    setLoading(false);
  };

  return (
    <AccountBuilder
      initUser={{ ...profile, bio: profile.bio || profile.pending_bio } as any}
      context="profile"
      gender={user.gender}
      mode="update"
      onSubmit={onSubmit as any}
      loading={loading}
    />
  );
}

export default EditProfile;
