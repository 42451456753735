import apiConfig from "configs/api-v2";
import TPreference from "types/TPreference";
import TProfile from "types/TProfile";
import TUser from "types/TUser";
import UserItem from "types/UserItem";
import apiClient from "./client-v2";
import { UpdateUser } from "./types/users";
import User from "types/User";

const endpoints = apiConfig.endpoints;

type UserInfo = TUser & {
  profile: TProfile;
  preferences: TPreference;
  //
  national_number?: string;
  birthday?: string;
  users?: string[];
  createErrors?: string[];
  pending_name?: string;
};

const getUserCredentials = (id: string) =>
  apiClient.post<{
    token: string;
    refresh_token: string;
  }>(`${endpoints.guardians_credentials}/${id}`, {});

const get = () => apiClient.get<UserInfo>(endpoints.guardians);

const createUser = (data: User) =>
  apiClient.post<UserItem>(endpoints.guardians_users, data);

const getUsers = () => apiClient.get<UserItem[]>(endpoints.guardians_users);

const update = (props: UpdateUser) =>
  apiClient.patch(endpoints.guardians, props);

export default {
  createUser,
  update,
  get,
  getUsers,
  getUserCredentials,
};
