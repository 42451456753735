import { yupResolver } from "@hookform/resolvers/yup";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { RiLockPasswordLine, RiUser3Line } from "react-icons/ri";
import * as Yup from "yup";

import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import constants from "configs/constants";
import useForm from "hooks/useForm";

import { GuardianParams } from "api/types/register";
import { FaRegAddressCard } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import RegisterFooter from "./Footer";

interface Props {
  phone?: string;
  loading: boolean;
  error: string;
  onSubmit: (values: GuardianParams) => void;
}

type TValue = Yup.InferType<typeof schema.guardianRegister>;

const RegisterGuardianForm = ({ phone, loading, error, onSubmit }: Props) => {
  const { control, errors, handleSubmit } = useForm<TValue>({
    resolver: yupResolver(schema.guardianRegister),
    defaultValues: {
      name: "",
      email: "",
      phone: phone || "",
      national_number: "",
      birthday: undefined,
      password: "",
      confirmPassword: "",
    },
  });

  return (
    <>
      <div>
        <FormFiled
          type="text"
          name="name"
          alphabetic
          withNameChecker
          is_guardian
          maxLength={20}
          Icon={RiUser3Line}
          control={control}
          error={errors.name}
          placeholder={constants.NAME}
          containerClassName="border-primary"
        />
        {!errors.name && (
          <span className="mr-2 text-xs font-semibold text-yellow-500">
            {constants.NAME_IN_ARABIC_ONLY}
          </span>
        )}
      </div>

      <div>
        <FormFiled
          type="text"
          name="email"
          Icon={HiOutlineMail}
          control={control}
          error={errors.email}
          placeholder={constants.EMAIL}
          containerClassName="border-primary"
        />
      </div>

      <div>
        <FormFiled
          type="date"
          name="birthday"
          control={control}
          error={errors.birthday}
          placeholder={constants.BIRTHDAY}
          containerClassName="border-primary"
        />
      </div>

      <div>
        <FormFiled
          type="number"
          name="national_number"
          Icon={FaRegAddressCard}
          control={control}
          error={errors.national_number}
          placeholder={constants.NATIONAL_NUM}
          containerClassName="border-primary"
        />
      </div>

      <div>
        <FormFiled
          type="tel"
          name="phone"
          disabled={!!phone}
          control={control}
          Icon={HiOutlineDevicePhoneMobile}
          containerClassName="border-primary"
          error={errors.phone}
          placeholder={constants.PHONE}
        />
      </div>

      <div>
        <FormFiled
          type="password"
          name="password"
          control={control}
          Icon={RiLockPasswordLine}
          error={errors.password}
          placeholder={constants.PASSWORD}
          containerClassName="border-primary"
        />
      </div>

      <div>
        <FormFiled
          type="password"
          control={control}
          name="confirmPassword"
          Icon={RiLockPasswordLine}
          error={errors.confirmPassword}
          containerClassName="border-primary"
          placeholder={constants.CONFIRM_PASSWORD}
        />
      </div>

      {!!error && <p className="text-error">{error}</p>}

      <RegisterFooter
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        loading={loading}
      />
    </>
  );
};

export default RegisterGuardianForm;
