import apiV2 from "configs/api-v2";
import str from "configs/constants";
import events from "configs/events";
import useAuth from "hooks/useAuth";
import useGlobalNavigate from "hooks/useGlobalNavigate";
import useToast from "hooks/useToast";
import { refreshAdsQuery } from "queries/ads";
import useBadges, { refreshBadgesQuery } from "queries/badges";
import { refreshBlockedQuery } from "queries/blocked";
import {
  addChatMessage,
  hideMessage,
  readMessage,
  refreshChatsQuery,
  updateChatUser,
} from "queries/chats";
import { refreshEngageQuery } from "queries/engage";
import { refreshLikesQuery } from "queries/likes";
import useNotifications from "queries/notifications";
import { addSupportMessage, refreshSupportQuery } from "queries/support";
import { addSupportThreadMessage } from "queries/supportThread";
import {
  addThreadMessage,
  readThMessages,
  refreshThreadQuery,
  removeThMessage,
} from "queries/thread";
import useUser, { refreshUserQuery } from "queries/user";
import { refreshVisitsQuery } from "queries/visits";
import { refreshWalletQuery } from "queries/wallets";
import { ReactNode, createContext, useEffect, useRef, useState } from "react";
import { Socket as TSocket, io } from "socket.io-client";
import GotMessage from "types/GotMessage";
import Message from "types/Message";
import TNotification from "types/TNotification";
import playAudio from "utils/audio";
import notify from "utils/notify";
import refreshPage from "utils/refreshPage";

const ATTEMPTS = 30;

export const socketContext = createContext<{ socket: TSocket | null }>({
  socket: null,
});

interface Sub {
  notify: TNotification;
  type: "free" | "paid";
  redirect?: boolean;
}

interface Status {
  userId: string;
  status: string | boolean;
}

interface Hide {
  sender: string;
  messageId: string;
  chatId: string;
  message?: Message;
}

interface Read {
  chatId: string;
  userId: string;
}

const Socket = ({ children }: { children: ReactNode }) => {
  const { setBadges } = useBadges();
  const { engaged, setUser } = useUser();
  const { addNotification } = useNotifications();
  const { isAuth, token } = useAuth();
  const defTitle = document.title;
  const barrier = useRef(true);
  const toast = useToast();
  const navigate = useGlobalNavigate();

  const [socket, setSocket] = useState<TSocket | null>(null);

  const addNotifyForUnengagedUser = (notify: TNotification) => {
    if (!engaged) addNotification(notify);
  };

  const handleEngage = async (notify: TNotification) => {
    addNotifyForUnengagedUser(notify);
    refreshUserQuery();
    refreshChatsQuery();
    refreshEngageQuery();
    refreshBadgesQuery();
  };

  function startingSocket(socket: TSocket) {
    socket.on(events.CONNECT, () => socket.emit(events.LOGIN));

    socket.on(events.USER_STATUS, ({ userId, status }: Status) => {
      updateChatUser(
        userId,
        typeof status === "string" && status === "engaged"
          ? { status }
          : {
              is_active: status as boolean,
              is_online: status as boolean,
              last_seen: Date.now().toString(),
            },
      );
    });

    socket.on(events.ONLINE, (userId: string) => {
      updateChatUser(userId, { is_online: true });
    });

    socket.on(events.OFFLINE, (userId: string) => {
      updateChatUser(userId, {
        is_online: false,
        last_seen: Date.now().toString(),
      });
    });

    // accepted or canceled
    socket.on(events.MESSAGE_UPDATED, ({ chatId }: Hide) => {
      playAudio("SENT");
      refreshThreadQuery({ threadId: chatId });
      refreshChatsQuery();
    });

    socket.on(
      events.MESSAGE_REMOVE,
      ({ chatId, sender, message, messageId }: Hide) => {
        playAudio("SENT");
        removeThMessage({ messageId, chatId });
        hideMessage({ userId: sender, messageId, message, fromAll: true });
      },
    );

    socket.on(events.GOT_MESSAGE, (props: GotMessage) => {
      addThreadMessage(props);
      addChatMessage(props);

      const isChatTab = window.location.pathname.includes("chat");
      const isTabVisible = document.visibilityState === "visible";

      if (!isTabVisible) {
        document.title = `(1) ${defTitle}`;
      } else if (!isChatTab)
        notify({ title: str.NEW_MESSAGE, message: props.message });
      else playAudio("GOT");
    });

    socket.on(events.MESSAGE_READ, ({ userId, chatId }: Read) => {
      readThMessages({ chatId });
      readMessage(userId);
    });

    socket.on(events.ADD_STORY, async () => {
      notify({ title: "الزواج", message: "تم الزواج بينكما" });
      refreshEngageQuery();
    });

    socket.on(events.YOUNG_ENGAGE, async (notify: TNotification) => {
      toast.success(notify.content);
      addNotification(notify);
      refreshEngageQuery();
    });

    socket.on(events.GUARDIAN_ENGAGE, async (notify: TNotification) => {
      toast.success(notify.content);
      addNotification(notify);
      refreshEngageQuery();
    });

    socket.on(events.SEND_ENGAGE, handleEngage);

    socket.on(events.ACCEPT_ENGAGE, handleEngage);

    socket.on(events.CANCEL_ENGAGE, handleEngage);

    socket.on(events.REMOVE_ENGAGE, handleEngage);

    socket.on(events.CHAT, (notify: TNotification) => {
      addNotifyForUnengagedUser(notify);
      refreshChatsQuery();
    });

    socket.on(
      events.NEW_SUPPORT_MESSAGE,
      async (props: { id: string; subject?: string; message: string }) => {
        addSupportMessage(props);
        addSupportThreadMessage(props);

        const isSupportTab = window.location.pathname.includes("support");
        const isTabVisible = document.visibilityState === "visible";

        if (!isTabVisible) {
          document.title = `(1) ${defTitle}`;
        } else if (!isSupportTab)
          notify({
            title: str.SUPPORT_NEW_MESSAGE,
            message: props.message,
          });
        else playAudio("GOT");
      },
    );

    socket.on(events.ACCOUNT_UPDATE, () => {
      refreshUserQuery();
    });

    socket.on(events.UPDATE_ACCEPT, async (notify: TNotification) => {
      addNotifyForUnengagedUser(notify);
      refreshUserQuery();
    });

    socket.on(events.ACCOUNT_ACCEPT, () => {
      toast.success(str.WELCOMING, "bottom");
      setTimeout(() => refreshPage("/"), 1500);
    });

    socket.on(events.RESET_ACCOUNT, () => {
      toast.success(str.TOAST.ACCOUNT_RESET, "bottom");
      setTimeout(() => refreshPage("/"), 1500);
    });

    socket.on(events.REMOVE_ACCOUNT, () => {
      toast.error(str.TOAST.ACCOUNT_REMOVED, "bottom");
      refreshUserQuery();
      refreshSupportQuery();
    });

    socket.on(events.ACCOUNT_CANCEL, () => {
      toast.error(str.TOAST.ACCOUNT_CANCELED, "bottom");
      refreshUserQuery();
      refreshSupportQuery();
    });

    socket.on(events.SUBSCRIPTION, async ({ notify, redirect }: Sub) => {
      const content = notify.content;
      addNotification(notify);
      refreshWalletQuery();
      setUser({ has_requested_payment: false });

      if (content.includes(str.SORRY) || content.includes(str.CANCEL))
        toast.error(content);
      else toast.success(content);

      if (redirect) navigate("/");
    });

    socket.on(
      events.BLOCK_USERS,
      async ({ id }: { status: string; id: string }) => {
        refreshBlockedQuery(id);
      },
    );

    socket.on(events.LIKE, async (n: TNotification) => {
      refreshLikesQuery();
      setBadges((b) => ({ ...b, likes: b.likes + 1 }));
      notify({ title: n.title, message: n.content, sound: "NOTIFY" });
    });

    socket.on(events.VISIT, async (n: TNotification) => {
      refreshVisitsQuery();
      setBadges((b) => ({ ...b, visits: b.visits + 1 }));
      notify({ title: n.title, message: n.content, sound: "NOTIFY" });
    });

    socket.on(events.ADS, refreshAdsQuery);

    socket.on(events.UPDATE_CANCEL, addNotifyForUnengagedUser);

    socket.on(events.MAIL, addNotification);

    socket.on(events.EMAIL_VERIFIED, () => {
      setUser({ is_email_verified: true });
    });
  }

  useEffect(() => {
    function handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        if (defTitle !== document.title) {
          document.title = defTitle;
          playAudio("GOT");
        }
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (isAuth && barrier.current) {
      barrier.current = false;
      const newSocket = io(apiV2.ioURL, {
        auth: { token, role: "user" },
        reconnectionAttempts: ATTEMPTS,
      });

      setSocket(newSocket);
      startingSocket(newSocket);
      // newSocket.on("disconnect", () => handleDisconnect(newSocket));
    }
    return () => {
      socket?.disconnect();
    };
  }, [isAuth, token, socket]);

  return (
    <socketContext.Provider value={{ socket }}>
      {children}
    </socketContext.Provider>
  );
};

export default Socket;
