import supports from "api/support";
import supportImage from "assets/support.png";
import ChatUi from "components/chat";
import events from "configs/events";
import useEvent from "hooks/useEvent";
import useBadges from "queries/badges";
import useSupport, { addSupportMessage } from "queries/support";
import useSupportThread, {
  addSupportThreadMessage,
} from "queries/supportThread";
import useUser from "queries/user";
import { useRef } from "react";
import useThread from "stores/thread";
import { alafaf } from ".";

const Thread = () => {
  const { user } = useUser();
  const { chats, readMessage } = useSupport();

  const th = useThread((store) => store.thread);
  const resetThread = useThread((store) => store.resetThread);

  const chat = chats.find((s) => s._id === th.id)!;
  const id = chat?._id;
  const messages = chat?.messages || [];
  const seen = messages[messages.length - 1]?.is_from_user ? true : chat?.seen;

  const threadQuery = useSupportThread(th.id || "");
  const { setBadges } = useBadges();
  const isFocus = useRef(false);

  const onSend = (message: string) => {
    if (!message) return;

    addSupportMessage({ id, message, is_from_user: true });

    addSupportThreadMessage({ id, message, user });

    supports.append({ id, message });
  };

  const makeMessageAsRead = () => {
    isFocus.current = true;
    if (!seen) {
      readMessage(id);
      setBadges((b) => ({ ...b, support: Math.max(0, b.support - 1) }));
    }
  };

  useEvent({
    event: events.NEW_SUPPORT_MESSAGE,
    listener: () => {
      if (isFocus.current) makeMessageAsRead();
    },
  });

  const Empty = () => {};

  return (
    <div className="z-50">
      <ChatUi
        visible
        defaultPicture={supportImage}
        showMessageStatus={false}
        showBubbleActions={false}
        color={th.fromSupport ? "secondary" : "primary"}
        isTheadSeen={seen!}
        messages={threadQuery.thread || []}
        onSendMessage={onSend}
        receiver={{ ...alafaf, name: chat.subject, uid: user.uid }}
        reportMessage={Empty}
        sender={user}
        setVisibility={resetThread}
        onFocus={makeMessageAsRead}
        onBlur={() => (isFocus.current = false)}
        //
        hideRemovedMessage={Empty}
        removeMessageFromMe={Empty}
        removeTargetMessage={Empty}
        removeMessageFromAll={Empty}
        //
        fetchNextPage={threadQuery.fetchNextPage}
        hasNextPage={threadQuery.hasNextPage}
      />
    </div>
  );
};

export default Thread;
