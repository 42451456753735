import constants from "configs/constants";

import { Type } from "pages/users/utils/preferencesProps";
import { useState } from "react";

import { injectToken } from "api/client-v2";
import preferences from "api/preferences";
import AccountBuilder from "components/builder/Account";
import { TUserBuilder } from "components/builder/utils/Steps";
import useToast from "hooks/useToast";
import { AiOutlinePoweroff } from "react-icons/ai";
import { Navigate, useLoaderData } from "react-router-dom";
import TPreference from "types/TPreference";
import cleanPreferences from "utils/cleanPreferences";
import expiredToken from "utils/expiredToken";
import refreshPage from "utils/refreshPage";
import storage from "utils/storage";

interface Build {
  gender?: string;
  token?: string;
  is_guardian?: boolean;
  prefs?: Type;
  route?: string;
  activeStep?: number;
}

const storeValue = (key: string, value: any) =>
  storage.store(key, JSON.stringify(value));

const BuildPreferences = () => {
  const state = useLoaderData() as Build;
  const isPropsPassed = state && Object.keys(state).length;

  const { token, is_guardian, prefs: initUser, activeStep } = state;
  const gender = state.gender === "male" ? "female" : "male";
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmit = async (values: TPreference) => {
    setLoading(true);

    const props = cleanPreferences({} as any, values) as TPreference;
    try {
      injectToken(token!);
      const response = await preferences.create(props);

      if (response.ok) {
        storage.remove("build");
        storage.store("guardian", false);
        storage.storeToken(response.data!.token, response.data!.refresh_token);
        refreshPage("/");
      } else {
        toast.error(constants.ERRORS.INFO_UPLOAD + ".");
        return setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  async function onPersist(prefs: TUserBuilder, step: number) {
    try {
      const isExpiry = expiredToken(token || "");
      if (isExpiry) {
        storage.remove("build");
        refreshPage("/login");
      } else {
        const store = { prefs, gender: state.gender, is_guardian, token, activeStep: step };
        storeValue("build", { ...store, route: "/build/preferences" });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function logout() {
    storage.remove("build");
    refreshPage("/");
  }

  if (!isPropsPassed) return <Navigate to="/login" />;
  if (state.route !== "/build/preferences")
    return <Navigate to="/build/profile" />;

  return (
    <div className="mt-10">
      <div
        onClick={logout}
        className="absolute left-5 top-5 z-10 flex cursor-pointer flex-col items-center font-semibold text-primary"
      >
        <AiOutlinePoweroff className="text-xl" />
        <p className="text-xs">{constants.OUT}</p>
      </div>

      <AccountBuilder
        initStep={activeStep}
        onPersist={onPersist}
        initUser={initUser as any}
        context="preferences"
        gender={gender!}
        mode="build"
        onSubmit={onSubmit as any}
        loading={loading}
      />
    </div>
  );
};

export default BuildPreferences;
