import React, { useEffect, useRef } from "react";

import constants from "configs/constants";
import Message from "types/Message";
import Bubble from "./Bubble";
import Container from "./Container";
import Input from "./Input";

interface User {
  _id: string;
  profile_picture: string;
  name: string;
  uid: string;
}

interface ChatUiProps {
  visible: boolean;
  isTheadSeen: boolean;
  messages: Message[];
  alphabeticNumber?: boolean;
  showMessageStatus?: boolean;
  showBubbleActions?: boolean;
  color?: "primary" | "secondary";
  onSendMessage: (message: string, fromQuick: boolean) => void;
  removeMessageFromAll: (messageId: string) => void;
  removeMessageFromMe: (messageId: string) => void;
  removeTargetMessage: (messageId: string) => void;
  hideRemovedMessage: (messageId: string) => void;
  reportMessage: (messageId: string) => void;
  setVisibility: () => void;
  sender: User;
  receiver: User;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  defaultPicture?: string;
}

// const MAX_INPUT_LENGTH = 140;
const UNSENT_MESSAGE = "رسالة محذوفة";
// const LIMIT = 10;

const ChatUi: React.FC<ChatUiProps> = ({
  messages,
  onSendMessage,
  receiver,
  hideRemovedMessage,
  removeMessageFromMe,
  removeTargetMessage,
  removeMessageFromAll,
  reportMessage,
  color,
  showBubbleActions = true,
  showMessageStatus = true,
  sender,
  setVisibility,
  visible,
  isTheadSeen,
  onFocus,
  onBlur,
  fetchNextPage,
  hasNextPage,
  alphabeticNumber,
  defaultPicture,
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const isLoadMore = useRef(false);
  const mappedMessages = messages
    .filter((m) => !m.unsent)
    .map((m) => (m.visible ? m : { ...m, text: UNSENT_MESSAGE }));

  const calculatePrevAndNext = (messages: Message[], currentIndex: number) => {
    const totalMessages = messages.length;
    const normalizeIndex = (index: number) =>
      index < 0 || index >= totalMessages ? 0 : index;

    const prevIndex = normalizeIndex(currentIndex - 1);
    const nextIndex = normalizeIndex(currentIndex + 1);

    return {
      prevMessage: {
        index: !currentIndex ? -1 : prevIndex,
        time: messages[prevIndex].createdAt,
        id: messages[prevIndex].user._id,
      },
      nextMessage: {
        index: nextIndex,
        time: messages[nextIndex || currentIndex].createdAt,
        id: messages[nextIndex || currentIndex].user._id,
      },
    };
  };

  // isLoadMore: i add it to prevent scroll to bottom when load more message
  useEffect(() => {
    if (chatContainerRef.current && !isLoadMore.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    } else if (isLoadMore.current) {
      isLoadMore.current = false;
    }
  }, [messages]);

  return (
    <Container
      color={color}
      seen={isTheadSeen}
      visible={visible}
      user={receiver}
      close={setVisibility}
    >
      <div
        ref={chatContainerRef}
        className="no-scrollbar collapse-content h-[90vh] bg-base-300 px-0 sm:h-[65vh]"
      >
        <div className="chat-ui no-scrollbar z-[99999999] flex h-full w-full flex-col-reverse overflow-scroll pb-28">
          {mappedMessages.map((message, index) => {
            const { prevMessage, nextMessage } = calculatePrevAndNext(
              mappedMessages,
              index,
            );

            return (
              <Bubble
                color={color}
                defaultPicture={defaultPicture}
                hideRemovedMessage={hideRemovedMessage}
                key={message._id}
                message={message}
                next={nextMessage}
                pathToProfile={`profile/${receiver.uid}`}
                previous={prevMessage}
                receiver={receiver}
                removeMessageFromAll={removeMessageFromAll}
                removeMessageFromMe={removeMessageFromMe}
                removeTargetMessage={removeTargetMessage}
                reportMessage={reportMessage}
                sender={sender}
                showBubbleActions={showBubbleActions}
                showMessageStatus={showMessageStatus}
              />
            );
          })}

          {hasNextPage && (
            <span
              onClick={() => {
                isLoadMore.current = true;
                fetchNextPage();
              }}
              className={`badge badge-sm my-2 cursor-pointer self-center p-4 text-white ${
                color === "primary" ? "badge-secondary" : "badge-accent"
              }`}
            >
              {constants.LOAD_MORE}
            </span>
          )}

          <Input
            alphabeticNumber={alphabeticNumber}
            color={color}
            onFocus={onFocus}
            onBlur={onBlur}
            onSend={onSendMessage}
          />
        </div>
      </div>
    </Container>
  );
};

export default ChatUi;
