import { useInfiniteQuery } from "@tanstack/react-query";
import search from "api/search";
import ms from "ms";
import queryClient from "./queryClient";

const LIMIT_USERS = 10;

export const refreshProposalUsersQuery = () =>
  queryClient.invalidateQueries({ queryKey: ["proposal-users"] });

const useProposalUsers = () => {
  const query = useInfiniteQuery({
    queryKey: ["proposal-users"],
    queryFn: ({ pageParam }) =>
      search.users(pageParam, "proposals").then((res) => res.data!),
    getNextPageParam: (lastPage, allPage) => {
      return lastPage.length === LIMIT_USERS ? allPage.length + 1 : undefined;
    },
    select: (data) => data.pages.flatMap((x) => x),
    initialPageParam: 1,
    staleTime: ms("2h"),
  });

  return {
    ...query,
    proposalUsers: query.data!,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useProposalUsers;
