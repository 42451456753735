import classNames from "classnames";
import constants from "configs/constants";
import info from "configs/info";
import { PhoneInput as PInput } from "react-international-phone";
import "./index.css";

interface Props {
  placeholder?: string;
  value?: string;
  setValue?: (v: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  containerClassName?: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

const PhoneInput = ({
  placeholder = "",
  value,
  setValue,
  onBlur,
  onFocus,
  containerClassName,
  error,
  errorMessage,
  disabled,
}: Props) => {
  const style = (c: string) => {
    return classNames({
      [`!rounded-lg !border-[0.1rem] !h-9 border-gray-300 ${c}`]: true,
      [`!${containerClassName}`]: Boolean(containerClassName),
      "!bg-gray-100 text-right": Boolean(disabled),
      "!border-error": Boolean(error),
    });
  };

  return (
    <>
      <div dir="ltr">
        <PInput
          hideDropdown={disabled}
          disabled={disabled}
          countrySelectorStyleProps={{
            flagClassName: disabled ? "!hidden" : "",
            className: style(
              disabled ? "!hidden" : "!border-r-0 !m-0 !p-0 !rounded-r-none",
            ),
          }}
          inputProps={{
            onBlur,
            onFocus: (e) => {
              if (onFocus) onFocus();
              setTimeout(() => {
                e.target.setSelectionRange(
                  e.target.value.length,
                  e.target.value.length,
                );
              }, 50);
            },
            className: style(
              "w-full !text-sm !outline-none px-2 ml-2 outline-none placeholder:text-gray-400",
            ),
          }}
          placeholder={placeholder}
          value={value?.trim()}
          onChange={setValue}
          defaultCountry={(
            (globalThis as any).cCode || info.SA_CODE
          ).toLocaleLowerCase()}
          disableDialCodeAndPrefix={disabled ? undefined : true}
          showDisabledDialCodeAndPrefix={disabled ? undefined : true}
          charAfterDialCode={disabled ? "" : undefined}
          dialCodePreviewStyleProps={{
            className: style(
              "!font-arabic !border-l-0 !rounded-l-none !text-sm",
            ),
          }}
          preferredCountries={constants.ARABIC_COUNTRY_CODE}
        />
      </div>
      {errorMessage && (
        <span className={`mr-2 text-sm text-error`}>{errorMessage}</span>
      )}
    </>
  );
};

export default PhoneInput;
