import Button from "components/core/Button";
import TextArea from "components/core/TextArea";
import constants from "configs/constants";
import { IoIosArrowForward } from "react-icons/io";
import { BuildContext, BuildMode } from "./Steps";

interface Props {
  bio: string;
  setBio: (v: string) => void;
  loading?: boolean;
  pending?: boolean;
  goBack?: () => void;
  onSubmit: () => void;
  context: BuildContext;
  mode: BuildMode;
}

const Bio = (params: Props) => {
  const { bio, setBio, pending, loading, context, mode, goBack, onSubmit } =
    params;
  const isProfile = context === "profile";
  const isUpdate = mode === "update";

  return (
    <div className={`m-10 ${isUpdate ? "mx-2 mt-0" : "mx-8 mt-8"} md:mx-10`}>
      {!!goBack && (
        <button
          onClick={goBack}
          className="link-hover link-info link absolute right-5 top-3 flex items-center gap-x-[2px] font-semibold"
        >
          <IoIosArrowForward />
          {constants.GO_BACK}
        </button>
      )}

      <div className="mt-5 flex flex-col">
        <span className="text-lg font-bold text-primary">
          {isProfile
            ? constants.MY_INFO_IN_DETAILS_HISTORY
            : constants.MY_CONDITIONS_IN_DETAILS_HISTORY}
          {!!pending && (
            <span className="text-sm text-warning">
              {` (${constants.PENDING_DESCRIPTION})`}
            </span>
          )}
        </span>

        <TextArea
          error={false}
          value={bio}
          placeholder={isProfile ? constants.YOUR_BIO : constants.WRITE_DESC}
          className="mt-3 h-44 w-full border-primary placeholder:text-primary-content"
          setValue={setBio}
        />
      </div>

      <div className="mt-10 flex justify-center">
        <Button
          outline
          disable={!bio}
          size="btn-sm"
          loading={loading}
          onClick={onSubmit}
          color="btn-primary"
          className="h-10 !min-w-48"
        >
          {constants.SAVE}
        </Button>
      </div>
    </div>
  );
};

export default Bio;
