import { BsCameraFill } from "react-icons/bs";
import { FaCrown } from "react-icons/fa";
import defaultPic from "../assets/avatarPlaceholder.png";
import { useState } from "react";
import Modal from "./core/Modal";
import constants from "configs/constants";

interface Props {
  src?: string;
  online?: boolean;
  offline?: boolean;
  bordered?: boolean;
  subscriber?: boolean;
  size?: "sm" | "md" | "lg";
  badge?: number;
  images?: string[];
  onClick?: () => void;
  onEdit?: (image: string) => void;
  className?: string;
}

const Avatar = ({
  src,
  size = "md",
  online,
  offline,
  bordered,
  subscriber,
  badge,
  images,
  onClick,
  onEdit,
  className,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  const status = online ? "online" : offline ? "offline" : "";
  const width = size === "sm" ? "w-12" : size === "md" ? "w-16" : `w-24`;
  const clickable = onClick ? "hover:bg-base-100 cursor-pointer" : "";
  const border = bordered
    ? `ring ring-secondary ring-offset-base-100 ring-offset-2`
    : "";

  return (
    <div className={`avatar indicator ${status}`} onClick={onClick}>
      {!!badge && (
        <span className="badge indicator-item badge-primary indicator-start indicator-middle ml-1 px-1 py-2 text-xs font-bold text-base-100">
          {badge}%
        </span>
      )}
      <div
        className={`${width} rounded-full bg-base-200 ${clickable} ${border} ${
          subscriber && "ring ring-yellow-600"
        } ${className}`}
      >
        <img src={src || defaultPic} />
      </div>

      {!!subscriber && (
        <span className="indicator-item indicator-bottom mb-[2px] mr-[50%]">
          <FaCrown className="mx-auto text-xl font-extrabold text-yellow-500" />
        </span>
      )}

      {!!onEdit && (
        <>
          <span className="indicator-item indicator-start indicator-bottom mx-[50%] mb-1">
            <button
              onClick={() => setShowModal(true)}
              className="btn btn-circle btn-sm"
            >
              <BsCameraFill className="text-xl text-base-content" />
            </button>
          </span>

          <Modal visible={showModal} setVisibility={setShowModal}>
            <p className="text-center text-xl font-bold">
              {constants.CHOOSE_RIGHT_PICTURE}
            </p>
            <div className="no-scrollbar mt-8 grid h-[60vh] grid-cols-3 place-items-center gap-8 overflow-auto">
              {images?.map((image) => {
                return (
                  <div
                    key={image}
                    className="flex h-24 w-24 overflow-hidden rounded-xl bg-base-100"
                  >
                    <img
                      onClick={() => {
                        onEdit(image);
                        setShowModal(false);
                      }}
                      className="btn h-full w-full p-0"
                      src={image}
                    />
                  </div>
                );
              })}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default Avatar;
