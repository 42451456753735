import logo from "assets/logo.svg";
import supportImage from "assets/support.png";
import List from "components/lists/List";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useAlert from "hooks/useAlert";
import useAuth from "hooks/useAuth";
import useSupport from "queries/support";
import { useState } from "react";
import { CgClose } from "react-icons/cg";
import { useLocation } from "react-router-dom";
import useThread from "stores/thread";
import TSupport from "types/TSupport";
import sort from "utils/sort";
import { timeFormat } from "utils/time";
import AddSubjectModal from "./components/AddSubjectModal";
import SupportListItem from "./components/SupportListItem";

export const alafaf = {
  uid: "123456789",
  _id: "123456789",
  email: "info@elafaf.net",
  phone: "+123456789",
  age: 30,
  profile_picture: supportImage,
  name: constants.APP_NAME,
  city: "",
  country: "",
  relationship_status: "",
  completed_profile: 1,
  is_removed: false,
  status: "accepted",
  gender: "male",
  is_email_verified: true,
  is_guardian: false,
  is_active: true,
  is_online: true,
  notification_enable: true,
  last_seen: "2023-06-28T20:54:23.769Z",
  createdAt: "2023-06-28T20:54:23.769Z",
};

const SupportPage = () => {
  const { isGuardian } = useAuth();
  const { pathname } = useLocation();
  const th = useThread((store) => store.thread);
  const resetThread = useThread((store) => store.resetThread);
  const setThread = useThread((store) => store.setThread);
  const unShowTitle = isGuardian || pathname.includes("engaged");

  const { chats, isLoading, isError, error, ...support } = useSupport();
  const alert = useAlert();
  const [key, setKey] = useState(Date.now().toString());
  const onAddNewSubject = async (subject: string, message: string) => {
    support.create({ subject, message });
    setTimeout(() => setKey(Date.now().toString()), 500);
  };

  const onDelete = (id: string) => {
    if (th.visible && th.id === id) resetThread();
    support.removeConversation(id);
  };

  const goToThread = (id: string, subject: string, isNotRead: boolean) => {
    if (isNotRead) support.readMessage(id);

    setThread({
      id,
      receiver: { ...alafaf, name: subject },
      visible: true,
      fromSupport: true,
      showMessageStatus: false,
    });
  };

  const Header = chats.length ? (
    <div className="mt-5 flex justify-end p-2 px-5">
      <div
        className="btn btn-error btn-sm flex gap-x-2 rounded-2xl"
        onClick={() =>
          alert({
            title: constants.BAR_SUPPORT,
            content: constants.ALERT.REMOVE_ALL_CONVERSATION,
            onConfirm: () => {
              resetThread();
              support.removeAll();
            },
          })
        }
      >
        <CgClose className="text-base" />
        <span className="text-xs">{constants.REMOVE_ALL}</span>
      </div>

      <div className="" />
    </div>
  ) : null;

  if (isError) throw new Error(error?.name);

  return isLoading ? (
    <UserSkeletons />
  ) : (
    <div
      className={`h-[90vh] overflow-y-hidden md:h-[85vh] ${unShowTitle ? "mx-auto max-w-3xl" : ""}`}
    >
      <div key={key} className={`mt-5 px-3 ${unShowTitle && "md:mt-20"}`}>
        <AddSubjectModal forceOpen={!chats.length} onSubmit={onAddNewSubject} />
      </div>

      {Header}

      {!chats.length ? null : (
        <List<TSupport>
          infinite
          hasNextPage={support.hasNextPage}
          next={support.fetchNextPage}
          data={sort.supportMessage(chats)}
          fixedHeight={!unShowTitle}
          itemClassName="mb-36 md:mb-28"
          containerClassName={unShowTitle ? "pb-32" : "!mt-1"}
          keyExtractor={(item) => item._id.toString()}
          renderItem={(item) => {
            const { _id, subject, messages, seen } = item;
            const lastMessage = messages[messages.length - 1];
            const isSeen = lastMessage.is_from_user ? true : seen;

            return (
              <SupportListItem
                isRead={isSeen}
                title={subject}
                content={lastMessage.text}
                time={timeFormat(lastMessage.time)}
                image={logo}
                onDelete={() => onDelete(_id)}
                onClick={() =>
                  goToThread(_id, subject, !lastMessage.is_from_user && !isSeen)
                }
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default SupportPage;
