import React from "react";
import classNames from "classnames";

interface Props {
  label?: string;
  options: string[];
  multiSelect?: boolean;
  value: string | string[] | undefined;
  setValue: (value: string | string[] | null) => void;
}

const ButtonGroup: React.FC<Props> = ({
  label,
  options,
  multiSelect = false,
  value,
  setValue,
}) => {
  const handleSelect = (label: string) => {
    if (multiSelect) {
      if (!Array.isArray(value)) setValue([label]);
      else {
        const isSelected = value.includes(label);
        const updatedSelection = isSelected
          ? value.filter((item) => item !== label)
          : [...value, label];
        setValue(updatedSelection);
      }
    } else {
      const newSelection = value === label ? null : label;
      setValue(newSelection);
    }
  };

  return (
    <div className="flex flex-col">
      {!!label && <span className="mb-3 text-center font-bold">{label}</span>}
      <div className="join flex flex-wrap items-center justify-center gap-2">
        {options.map((label) => (
          <button
            key={label}
            className={classNames(
              "btn join-item btn-sm !rounded-xl text-xs !font-normal",
              {
                "btn-primary !font-bold text-white": multiSelect
                  ? Array.isArray(value) && value.includes(label)
                  : value === label,
              },
            )}
            onClick={() => handleSelect(label)}
            aria-label={label}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ButtonGroup;
