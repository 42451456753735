import * as React from "react";
import { Range } from "react-range";
import { Thumb, Track } from ".";
import { categories, SLIDER_STEP } from "./utils";

interface Props {
  value?: number[];
  setValue: (value: number[]) => void;
  category: keyof typeof categories;
  showLabel?: boolean;
}

const RangSlider = ({ showLabel, value, setValue, category }: Props) => {
  const cat = categories[category];

  const defaultValue = value && value.length ? value : undefined;
  const [sliderValue, setSliderValue] = React.useState(
    defaultValue || cat.default,
  );

  const onFinalChange = (values: number[]) => {
    if (values.every((e) => cat.default.includes(e))) setValue([]);
    else setValue(values);
  };

  return (
    <div
      className={`flow-row flex h-9 items-center ${showLabel ? "justify-between" : "justify-center"}`}
    >
      {showLabel && <span className="font-bold">{cat.label}:</span>}

      <Range
        rtl
        step={SLIDER_STEP}
        min={cat.min}
        max={cat.max}
        values={sliderValue}
        onFinalChange={onFinalChange}
        onChange={setSliderValue}
        renderTrack={(p) => (
          <Track key="track" {...p} values={sliderValue} cat={cat} />
        )}
        renderThumb={(p) => (
          <Thumb
            key={`thumb-${p.props.key}`}
            {...p}
            values={sliderValue}
            cat={cat}
          />
        )}
      />
    </div>
  );
};

export default RangSlider;
