import { useRef } from "react";
import Auth from "./components/Auth";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Online from "./components/Online";
import Search from "./components/Search";
import Statistics from "./components/Statistics";
import Stories from "./components/Stories";
import { AiFillFileText } from "react-icons/ai";
import { SiGnuprivacyguard } from "react-icons/si";
import { NavLink } from "react-router-dom";
import LandingNavbar from "./Navbar";

const links = [
  { Icon: AiFillFileText, text: "الشروط والأحكام", href: "/terms" },
  { Icon: SiGnuprivacyguard, text: "سياسة الخصوصية", href: "/privacy" },
];

const Landing = () => {
  const authRef = useRef<HTMLDivElement>(null);

  function scrollToAuth() {
    if (authRef.current) {
      authRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  const Legals = () => (
    <div className="mt-12 flex flex-1 flex-row items-center justify-center gap-x-8 md:mt-16 md:gap-x-12">
      {links.map(({ Icon, href, text }, index) => {
        return (
          <NavLink
            target="_blank"
            to={href}
            key={index.toString()}
            className={() =>
              `link-hover link flex h-full items-center text-base font-bold text-primary hover:no-underline max-lg:py-1`
            }
          >
            <Icon className="mx-1" />
            <span className="truncate">{text}</span>
          </NavLink>
        );
      })}
    </div>
  );

  return (
    <>
      <LandingNavbar scrollToAuth={scrollToAuth} />
      <div className="no-scrollbar overflow-auto">
        <Features />

        {/* app success stories */}
        <Stories />

        {/* app search */}
        <Search />

        {/* app statistics  */}
        <Statistics />

        {/* online users */}
        <Online />

        {/* app auth */}
        <Auth reference={authRef} />

        <Legals />

        {/* footer */}
        <Footer />
      </div>
    </>
  );
};

export default Landing;
