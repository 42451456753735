import TPreference from "types/TPreference";

const cleanPreferences = (previous: TPreference, current: TPreference) => {
  const props: Partial<TPreference> = { ...current };
  type Key = keyof typeof props;

  Object.entries(previous).forEach(([key, previous]) => {
    const value = current[key as Key];

    if (!!previous && Array.isArray(value) && value.length === 0)
      (props[key as Key] as any) = null;

    if (
      (typeof value === "string" && value === previous) ||
      (Array.isArray(value) &&
        Array.isArray(previous) &&
        value.length === previous.length &&
        value.every((e) => (previous as string[]).includes(e)))
    )
      delete props[key as Key];
  });

  Object.entries(props).forEach(([key, value]) => {
    if ((Array.isArray(value) && value.length === 0) || value === undefined)
      delete props[key as Key];
    else if (["age", "height", "weight"].includes(key) && value)
      props[key as Key] = (props[key as Key] as any).join("_");
  });

  return props;
};

export default cleanPreferences;
