import api from "configs/api-v2";
import TPreference from "types/TPreference";
import TProfile from "types/TProfile";
import TUser from "types/TUser";
import User from "types/User";
import UserItem from "types/UserItem";
import apiClient from "./client-v2";
import { UpdateUser } from "./types/users";

const endpoints = api.endpoints;

export type UserInfo = TUser & {
  profile: TProfile;
  preferences: TPreference;
  //
  national_number?: string;
  birthday?: string;
  users?: string[];
  createErrors?: string[];
  pending_name?: string;
};

const getByIdForGuest = (id: string) =>
  apiClient.get<User>(`${endpoints.guest_users}/${id}`);

const get = () => apiClient.get<UserInfo>(endpoints.me);
const getById = (id: string) => apiClient.get<User>(`${endpoints.users}/${id}`);

const update = (props: UpdateUser) => apiClient.patch(endpoints.me, props);

const permanentDeletion = () =>
  apiClient.delete(`${endpoints.users}/delete-request`);

const changePassword = (props: {
  current_password: string;
  new_password: string;
}) => apiClient.patch<unknown, Error>(endpoints.change_password, props);

const getLikes = () => apiClient.get<UserItem[]>(endpoints.likes);

const getVisits = () => apiClient.get<UserItem[]>(endpoints.visits);
const addVisitor = (id: string) => apiClient.post(`${endpoints.visits}/${id}`);

const resetVLBadge = (type: "likes" | "visits") =>
  apiClient.get(`${endpoints.users}/${type}/seen`);

export default {
  getByIdForGuest,

  get,
  getById,
  update,
  changePassword,

  getLikes,
  getVisits,
  addVisitor,
  resetVLBadge,

  permanentDeletion,
};
