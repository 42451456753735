import constants from "configs/constants";
import girl from "assets/girl.png";
import youngGuardian from "assets/man_.png";
import young from "assets/young.png";
import Avatar from "./Avatar";
import classNames from "classnames";

interface Props {
  onClick: (v: (typeof genders)[0]) => void;
  is_guardian: boolean;
  gender: string;
  withoutGuardian?: boolean;
  error?: boolean;
}

const genders = [
  {
    id: "1",
    src: young,
    label: constants.YOUNG,
    gender: "male",
    is_guardian: false,
  },
  {
    id: "2",
    src: girl,
    label: constants.GIRL,
    gender: "female",
    is_guardian: false,
  },
  {
    id: "3",
    src: youngGuardian,
    label: constants.GUARDIAN_USER,
    gender: "male|female",
    is_guardian: true,
  },
];

const Genders = ({
  withoutGuardian,
  error,
  is_guardian,
  gender,
  onClick,
}: Props) => {
  const items = withoutGuardian ? genders.slice(0, -1) : genders;

  return (
    <div className="flex justify-center gap-x-3">
      {items.map((item) => {
        const isSelected =
          item.is_guardian === is_guardian && item.gender === gender;

        return (
          <div
            key={item.id}
            onClick={() => onClick(item)}
            className={classNames({
              "mx-1 flex cursor-pointer flex-col overflow-hidden rounded-xl hover:text-primary":
                true,
              "animate-jump animate-duration-[2000ms] animate-infinite": error,
            })}
          >
            <Avatar
              className={classNames({
                "hover:bg-primary": true,
                "bg-primary": isSelected,
                "bg-error": error,
              })}
              src={item.src}
              size="sm"
            />
            <span
              className={classNames({
                "mt-1 cursor-pointer text-center text-xs font-semibold": true,
                "text-error": error,
                "text-primary": isSelected,
              })}
            >
              {item.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Genders;
