import users from "api/users";
import EmptyPage from "components/Empty";
import List from "components/lists/List";
import UserListItem from "components/lists/UserListItem";
import UserSkeletons from "components/theme/skeleton/users";
import constants from "configs/constants";
import useBadges from "queries/badges";
import useVisits from "queries/visits";
import { useEffect } from "react";
import { CgEye } from "react-icons/cg";
import sort from "utils/sort";

const VisitsPage = () => {
  const { visits, isLoading, isError, error } = useVisits();
  const { badges, setBadges } = useBadges();

  useEffect(() => {
    if (badges?.visits) {
      users.resetVLBadge("visits");
      setBadges((b) => ({ ...b, visits: 0 }));
    }
  }, []);

  if (isLoading) return <UserSkeletons />;
  if (isError) throw new Error(error?.name);
  if (!visits.length)
    return <EmptyPage content={constants.EMPTY_VISITS_LIST} Icon={CgEye} />;

  return (
    <div>
      <List
        data={sort.withCreatedAtProp(visits)}
        title={constants.VISITED_USERS}
        keyExtractor={(item) => item.uid}
        renderItem={(item) => {
          return (
            <UserListItem
              age={item.age}
              city={item.city}
              country={item.country}
              guardian={item.is_guardian}
              iconFor="visits"
              image={item.profile_picture}
              status={item.relationship_status}
              time={item.createdAt}
              to={`profile/${item.uid}`}
              username={item.name}
            />
          );
        }}
      />
    </div>
  );
};

export default VisitsPage;
