import classNames from "classnames";
import Avatar from "components/Avatar";
import ButtonGroup from "components/core/ButtonGroup";
import CheckBox from "components/core/CheckBox";
import SelectPicker from "components/picker/Select";
import RSlider from "components/slider";
import RangSlider from "components/slider/Rang";
import constants from "configs/constants";
import useStorage from "hooks/useStorage";
import { useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import TProfile from "types/TProfile";
import TPreference from "types/TPreference";
import { Country } from "types/TCountry";

export type TUserBuilder =
  | TPreference
  | (TProfile & { age: number; profile_picture: string })
  | TPreference;

export type BuildContext = "profile" | "preferences";
export type BuildMode = "build" | "update";

interface Value {
  male: string[];
  female: string[];
}

interface Props {
  step: number;
  next: () => void;
  previous: () => void;
  user: TUserBuilder;
  setUser: React.Dispatch<React.SetStateAction<TUserBuilder>>;
  gender: string;
  context: BuildContext;
  mode: BuildMode;
  onPersist?: (user: TUserBuilder, step: number) => void;
}

export const getStepsBuilder = (as: BuildContext, mode: BuildMode) => ({
  relationship_status: {
    label: constants.RELATIONSHIP_STATUS,
    type: "group",
  },
  nationality: {
    label: constants.NATIONALITY,
    type: "picker",
  },
  country: {
    label: constants.COUNTRY,
    type: "picker",
  },
  city: {
    label: constants.CITY,
    type: "picker",
  },
  ...((as !== "profile" || mode !== "update") && {
    age: {
      label: constants.AGE,
      type: "slider",
    },
  }),
  clan: {
    label: constants.CLAN,
    type: "group",
  },
  origin: {
    label: constants.ORIGIN,
    type: "group",
  },
  ...(as === "profile" && {
    children: {
      label: constants.CHILDREN,
      type: "slider",
    },
  }),
  education: {
    label: constants.EDUCATION,
    type: "group",
  },
  beauty: {
    label: constants.BEAUTY,
    type: "group",
  },
  ...(as === "profile" &&
    mode === "build" && {
      profile_picture: {
        label: constants.PROFILE_PIC,
        type: "image",
      },
    }),
  occupation: {
    label: constants.OCCUPATION,
    type: "picker",
  },
  financial_status: {
    label: constants.FINANCIAL_STATUS,
    type: "group",
  },
  prayers: {
    label: constants.PRAYERS,
    type: "group",
  },
  health: {
    label: constants.HEALTH,
    type: "group",
  },
  skin_color: {
    label: constants.SKIN_COLOR,
    type: "group",
  },
  height: {
    label: constants.HEIGHT,
    type: "slider",
  },
  weight: {
    label: constants.WEIGHT,
    type: "slider",
  },
});

interface City {
  countries: Country[];
  country: string | string[];
}

const getCites = ({ countries, country }: City) => {
  if (!Array.isArray(country))
    return countries.find((e) => e.label === country)?.cities || [];

  return country
    .map((c: any) => countries.find((e: any) => c === e.label)?.cities || [])
    .flat();
};

const StepsBuilder = (params: Props) => {
  const { step, next, previous, user, setUser, gender } = params;
  const { mode, context, onPersist } = params;
  const { options, countries, images } = useStorage();
  const pictures = images[gender as keyof typeof images];

  const [direction, setDirection] = useState<"next" | "back">("next");
  const [auto, setAuto] = useState(true);
  const [cities, SetCities] = useState(
    getCites({ countries, country: user.country as string }),
  );

  const steps = getStepsBuilder(context, mode);
  const isProfile = context === "profile";
  const Slider = isProfile ? RSlider : RangSlider;

  const currentStep = Object.values(steps)[step - 1];
  const keys = Object.keys(steps);
  const currentKey = keys[step - 1] as keyof typeof user;
  const currentStepValue = user[currentKey as keyof typeof user];
  const isLastStep = step === keys.length + 1;
  const isPictureStep = (currentKey as string) !== "profile_picture";
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const optionKey = `${currentKey}${currentKey === "occupation" ? "s" : ""}_options`;
  const currentOptions = (
    (options[optionKey as keyof typeof options] || []) as Value
  )[gender as keyof Value];

  const handleNext = () => {
    if (step < keys.length + 1) {
      setDirection("next");
      next();
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setDirection("back");
      previous();
    }
  };

  const countrySelected = (country: string | string[]) => {
    setUser({ ...user, country: country as any });
    SetCities(getCites({ countries, country }));
  };

  const onSelectValue = (key: keyof typeof user) => {
    return (value: string | string[] | number | null) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      if (!isProfile) {
        const isNotRequired =
          value === constants.NOT_REQUIRE ||
          (Array.isArray(value) && value.includes(constants.NOT_REQUIRE));

        if (isNotRequired) {
          setUser((u) => ({ ...u, [key]: [] }));
          return (timeoutRef.current = setTimeout(handleNext, 350));
        }
      }

      key === "country"
        ? countrySelected(value as string | string[])
        : setUser((u) => ({ ...u, [key]: value as string }));

      if (onPersist) onPersist({ ...user, [key]: value as string }, step + 1);
      if (auto && isProfile && !!value)
        timeoutRef.current = setTimeout(handleNext, 350);
    };
  };

  const renderCurrentStep = ({ label, type }: typeof steps.clan) => {
    const pickerOptions =
      currentKey === "country"
        ? countries.map((e) => e.label)
        : currentKey === "city"
          ? cities
          : currentOptions;

    const addNotRequired = (arr: string[]) => {
      if (!isProfile) return [constants.NOT_REQUIRE, ...arr];
      return arr;
    };

    switch (type) {
      case "group":
        return (
          <ButtonGroup
            multiSelect={!isProfile}
            options={addNotRequired(currentOptions)}
            value={user[currentKey] as any}
            setValue={onSelectValue(currentKey)}
          />
        );
      case "slider":
        return (
          <Slider
            value={user[currentKey] as any}
            setValue={onSelectValue(currentKey) as any}
            category={currentKey as any}
          />
        );
      case "picker":
        return (
          <SelectPicker
            withSearch
            maxH={250}
            error={false}
            multiple={!isProfile}
            options={addNotRequired(pickerOptions)}
            label={label}
            value={(user[currentKey] || null) as any}
            setValue={onSelectValue(currentKey)}
          />
        );
      case "image":
        return (
          <div className="mb-10 flex w-full justify-center md:mb-4">
            <Avatar
              size="md"
              images={pictures}
              onEdit={onSelectValue(currentKey)}
              src={(user[currentKey] || undefined) as any}
            />
          </div>
        );
    }
  };

  return (
    <>
      {!isLastStep && (
        <>
          <div className="absolute right-2 top-2 md:right-16">
            {isProfile ? (
              <CheckBox
                color="checkbox-primary"
                title={constants.AUTO_INCREMENT}
                size="checkbox-sm"
                checked={auto}
                toggle={() => setAuto(!auto)}
              />
            ) : (
              <span className="text-xs font-bold text-info">
                {`${constants.MULTI_SELECT}`}
              </span>
            )}
          </div>
        </>
      )}

      <div className="mx-auto mt-12 flex w-[95%] flex-col items-center gap-y-5 md:w-[80%] lg:w-[60%]">
        <span
          key={step.toString()}
          className="animate-fade-down text-2xl font-bold text-primary animate-once"
        >
          {currentStep?.label}
        </span>
        <div className="flex w-full flex-row items-center gap-x-4 md:gap-x-5">
          <button
            onClick={handleBack}
            className={classNames({
              "btn btn-circle btn-primary btn-outline btn-sm": true,
              "btn-disabled": step === 1,
            })}
          >
            <IoIosArrowForward />
          </button>

          <div
            key={step.toString()}
            className={classNames("w-full transition-all duration-700", {
              "z-10 animate-fade-left": direction === "next" && isPictureStep,
              "z-10 animate-fade-right": direction === "back" && isPictureStep,
            })}
          >
            {!!currentStep && renderCurrentStep(currentStep)}
          </div>

          <button
            onClick={handleNext}
            className={classNames({
              "btn btn-circle btn-primary btn-outline btn-sm": true,
              "btn-disabled":
                (mode === "update" && step === keys.length) ||
                (isProfile ? currentStepValue == undefined : false),
            })}
          >
            <IoIosArrowBack />
          </button>
        </div>
      </div>
    </>
  );
};

export default StepsBuilder;
