import Modal from "components/core/Modal";
import constants from "configs/constants";
import { useCallback, useMemo, useReducer, useRef } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import auth from "api/auth";
import guardians from "api/guardians";
import Genders from "components/Genders";
import AccountBuilder from "components/builder/Account";
import { TUserBuilder } from "components/builder/utils/Steps";
import Button from "components/core/Button";
import FormFiled from "components/forms/FormFiled";
import schema from "components/forms/schema";
import Loading from "components/theme/Loading";
import useForm from "hooks/useForm";
import useToast from "hooks/useToast";
import queryClient from "queries/queryClient";
import { RiUser3Line } from "react-icons/ri";
import TPreference from "types/TPreference";
import User from "types/User";
import UserItem from "types/UserItem";
import cleanPreferences from "utils/cleanPreferences";
import * as Yup from "yup";

interface Props {
  setVisibility: (v: boolean) => void;
}

interface State {
  name: string;
  gender: string;
  loading: boolean;
  uploadError: string;
  currentStep: string;
  gender_error: boolean;
}

type Action =
  | { type: "SET_NAME"; payload: string }
  | { type: "SET_GENDER"; payload: string }
  | { type: "SET_GENDER_ERROR"; payload: boolean }
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_UPLOAD_ERROR"; payload: string }
  | {
      type: "SET_CURRENT_STEP";
      payload: "account" | "profile" | "preferences";
    };

const initialState: State = {
  name: "",
  gender: "",
  gender_error: false,
  loading: false,
  uploadError: "",
  currentStep: "account",
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_GENDER":
      return { ...state, gender: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_UPLOAD_ERROR":
      return { ...state, uploadError: action.payload };
    case "SET_CURRENT_STEP":
      return { ...state, currentStep: action.payload };
    case "SET_GENDER_ERROR":
      return { ...state, gender_error: action.payload };
    default:
      return state;
  }
};

const CreateUser = ({ setVisibility }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userProfile = useRef<TUserBuilder>({} as any);
  const toast = useToast();

  const { control, errors, handleSubmit } = useForm<
    Yup.InferType<typeof schema.name>
  >({ resolver: yupResolver(schema.name), defaultValues: { name: "" } });

  const setGender = useCallback((item: any) => {
    dispatch({ type: "SET_GENDER", payload: item.gender as string });
    dispatch({ type: "SET_GENDER_ERROR", payload: false });
  }, []);

  const onSetName = async (name: string) => {
    const isValidName = await auth.checkUserExistence({ username: name });
    if (isValidName.ok) {
      dispatch({ type: "SET_NAME", payload: name });
      if (!state.gender) {
        dispatch({ type: "SET_GENDER_ERROR", payload: true });
        return setTimeout(
          () => dispatch({ type: "SET_GENDER_ERROR", payload: false }),
          4000,
        );
      } else dispatch({ type: "SET_CURRENT_STEP", payload: "profile" });
    } else {
      dispatch({
        type: "SET_UPLOAD_ERROR",
        payload: isValidName.data?.message || constants.ERRORS.UNEXPECTED_ERROR,
      });
      setTimeout(
        () => dispatch({ type: "SET_UPLOAD_ERROR", payload: "" }),
        2000,
      );
    }
  };

  const onSetProfile = (p: TUserBuilder) => {
    userProfile.current = p;
    dispatch({ type: "SET_CURRENT_STEP", payload: "preferences" });
  };

  const onSubmit = async (values: TUserBuilder) => {
    const { age, profile_picture, ...profile } =
      userProfile.current as TUserBuilder & { profile_picture: string };
    const preferences = cleanPreferences({} as any, values as TPreference);

    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const response = await guardians.createUser({
        account: {
          age,
          profile_picture,
          name: state.name,
          gender: state.gender,
          is_guardian: true,
          completed_profile: 1,
        },
        profile,
        preferences,
      } as User);

      if (response.ok) {
        queryClient.setQueryData<UserItem[]>(["guardians"], (guardians) => [
          ...(guardians || []),
          response.data!,
        ]);
        toast.success(constants.SUCCESS.CREATE, "bottom");
        setVisibility(false);
      } else {
        toast.error(constants.ERRORS.INFO_UPLOAD + ".");
        return dispatch({ type: "SET_LOADING", payload: false });
      }
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const Steps = useMemo(
    () => ({
      account: {
        Step: () => (
          <div className="mx-auto mt-10 flex h-full w-[60%] flex-col gap-y-10">
            <p className="mb-8 text-center font-bold md:text-2xl">
              بيانات الاولية للعضو
            </p>
            <Genders
              withoutGuardian
              error={state.gender_error}
              is_guardian={false}
              gender={state.gender}
              onClick={setGender}
            />
            <div>
              <FormFiled
                type="text"
                name="name"
                alphabetic
                withNameChecker
                maxLength={20}
                Icon={RiUser3Line}
                control={control}
                is_guardian={false}
                error={errors.name}
                placeholder={constants.NAME}
                containerClassName="border-primary"
              />
              {!errors.name && (
                <span className="mr-2 text-xs text-success">
                  {constants.NAME_IN_ARABIC_ONLY}
                </span>
              )}
            </div>

            {!!state.uploadError && (
              <span className={`-mt-6 text-sm text-error`}>
                {state.uploadError}
              </span>
            )}

            <Button
              outline
              color="btn-primary"
              onClick={handleSubmit(({ name = "" }) => onSetName(name))}
              className="mx-auto mt-16 border-primary"
            >
              {constants.NEXT}
            </Button>
          </div>
        ),
      },
      profile: {
        Step: () => (
          <AccountBuilder
            context="profile"
            gender={state.gender}
            mode="build"
            onSubmit={onSetProfile}
          />
        ),
      },
      preferences: {
        Step: () => (
          <AccountBuilder
            context="preferences"
            gender={state.gender === "male" ? "female" : "male"}
            mode="build"
            onSubmit={onSubmit}
          />
        ),
      },
    }),
    [errors, state.name, state.gender_error, state.gender, state.uploadError],
  );

  const Step = Steps[state.currentStep as keyof typeof Steps].Step;

  return (
    <Modal
      visible
      setVisibility={setVisibility}
      className="no-scrollbar h-[95vh] !max-h-[95vh] !max-w-screen-lg overflow-hidden"
    >
      <div className="h-[100%] overflow-hidden pl-[2px]">
        <Loading visible={state.loading} />
        <Step />
      </div>
    </Modal>
  );
};

export default CreateUser;
