import constants from "configs/constants";
import { FC, ReactNode } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1280 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1280, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

interface CarouselProps {
  slides: {
    id: number;
    icon: ReactNode;
    title: string;
  }[];
  title: string;
}

interface IDot {
  onClick: () => void;
  index: number;
  active: boolean;
}

interface IconProps {
  className?: string;
}

export const TrustIcon: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    {/* Define the gradient */}
    <defs>
      <linearGradient id="trustGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: "#4F46E5", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "#7C3AED", stopOpacity: 1 }} />
      </linearGradient>
    </defs>

    {/* Apply the gradient to the paths */}
    <path
      d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"
      stroke="url(#trustGradient)"
    />
    <path d="M9 12l2 2 4-4" stroke="url(#trustGradient)" />
  </svg>
);

export const SearchIcon: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    {/* Define the gradient */}
    <defs>
      <linearGradient id="searchGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: "#059669", stopOpacity: 1 }} />{" "}
        {/* Emerald */}
        <stop
          offset="100%"
          style={{ stopColor: "#0EA5E9", stopOpacity: 1 }}
        />{" "}
        {/* Sky */}
      </linearGradient>
    </defs>

    {/* Apply the gradient to the paths */}
    <circle cx="11" cy="11" r="8" stroke="url(#searchGradient)" />
    <path d="m21 21-4.3-4.3" stroke="url(#searchGradient)" />
  </svg>
);

export const ChatIcon: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    {/* Define the gradient */}
    <defs>
      <linearGradient id="chatGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: "#EAB308", stopOpacity: 1 }} />{" "}
        {/* Pink */}
        <stop
          offset="100%"
          style={{ stopColor: "#F97316", stopOpacity: 1 }}
        />{" "}
        {/* Purple */}
      </linearGradient>
    </defs>

    {/* Apply the gradient to the path */}
    <path
      d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"
      stroke="url(#chatGradient)"
    />
  </svg>
);

export const PrivacyIcon: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    {/* Define the gradient */}
    <defs>
      <linearGradient id="privacyGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: "#EA580C", stopOpacity: 1 }} />{" "}
        {/* Orange */}
        <stop
          offset="100%"
          style={{ stopColor: "#DB2777", stopOpacity: 1 }}
        />{" "}
        {/* Pink */}
      </linearGradient>
    </defs>

    {/* Apply the gradient to the rect and path */}
    <rect
      width="18"
      height="11"
      x="3"
      y="11"
      rx="2"
      ry="2"
      stroke="url(#privacyGradient)"
    />
    <path d="M7 11V7a5 5 0 0 1 10 0v4" stroke="url(#privacyGradient)" />
  </svg>
);

const gradientColors = [
  "bg-gradient-to-r from-blue-500 to-purple-500",
  "bg-gradient-to-r from-green-500 to-teal-500",
  "bg-gradient-to-r from-yellow-500 to-orange-500",
  "bg-gradient-to-r from-pink-500 to-red-500",
];

const FeatureCarousel: FC<CarouselProps> = ({ slides }) => {
  const CustomDot = ({ onClick, active, index }: Partial<IDot>) => {
    return (
      <button
        onClick={onClick}
        className={`mx-[2px] mb-5 h-3 w-3 rounded-full transition-all ${
          active ? `${gradientColors[index!]}` : "bg-gray-300"
        }`}
      />
    );
  };

  return (
    <div className="rounded-box mx-auto w-[90%] overflow-hidden bg-white p-1 px-4 transition-all md:w-[80%] xl:w-[70%]">
      <Carousel
        rtl
        infinite
        autoPlay
        showDots
        customDot={<CustomDot />}
        arrows={false}
        autoPlaySpeed={4000}
        responsive={responsive}
      >
        {slides.map((slide, index) => {
          const gradient = gradientColors[index % gradientColors.length]; // Get gradient for the current slide
          return (
            <div
              key={slide.id}
              className="m-3 flex !min-h-72 flex-col items-center gap-y-10 rounded-3xl border-[0.1rem] border-gray-100 bg-white p-1 shadow-lg transition-all md:mx-auto md:h-72 md:w-72 lg:h-80 lg:w-80"
            >
              {slide.icon}
              <h3
                className={`${gradient} bg-clip-text text-center text-xl font-semibold text-transparent`}
              >
                {slide.title}
              </h3>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

const Features = () => {
  const slides = [
    {
      id: 1,
      icon: <TrustIcon className="h-44 w-44 max-md:h-32 max-md:w-32" />,
      title: "مصداقية 20 عاما",
    },
    {
      id: 2,
      icon: <SearchIcon className="h-44 w-44 max-md:h-32 max-md:w-32" />,
      title: "التسجيل والبحث مجانا",
    },
    {
      id: 3,
      icon: <ChatIcon className="h-44 w-44 max-md:h-32 max-md:w-32" />,
      title: "الدردشة مجانا",
    },
    {
      id: 4,
      icon: <PrivacyIcon className="h-44 w-44 max-md:h-32 max-md:w-32" />,
      title: "الحفاظ على الخصوصية",
    },
  ];

  return (
    <div className="mx-auto mb-16 mt-5 flex max-w-6xl flex-col overflow-hidden">
      <p className="mt-5 text-center text-4xl font-bold text-primary">
        {constants.FEATURES}
      </p>
      <div className="divider divider-vertical mx-auto my-0 mb-5 w-20 before:bg-primary after:bg-primary"></div>

      <FeatureCarousel slides={slides} title={constants.ONLINE_FEMALE_USERS} />
    </div>
  );
};

export default Features;
