import info from "configs/info";

const detectCountryCode = async () => {
  try {
    const response = await fetch(`https://ipinfo.io/json?token=b034250fe1168a`);
    const data = await response.json();
    return data.country || info.SA_CODE;
  } catch (error) {
    return info.SA_CODE;
  }
};

export default detectCountryCode;
