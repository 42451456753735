import { useQuery } from "@tanstack/react-query";
import apiClient from "api/client-v2";
import api from "configs/api-v2";

interface Statistic {
  male: number;
  female: number;
  male_online: number;
  female_online: number;
  stories: number;
}

const useStatistics = () => {
  const query = useQuery({
    queryKey: ["statistics"],
    queryFn: () =>
      apiClient
        .get<Statistic>(api.endpoints.statistics)
        .then((res) => res.data),
  });

  return {
    statistics: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
  };
};

export default useStatistics;
