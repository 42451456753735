import { FC } from "react";

type TTabOption = string;

interface TabProps {
  tabs: TTabOption[];
  activeTab: TTabOption;
  onTabChange: (tab: TTabOption) => void;
  tabLabels?: Record<TTabOption, string>;
}

const Tabs: FC<TabProps> = ({ tabs, activeTab, onTabChange, tabLabels }) => {
  return (
    <div className="tabs-xs sm:tabs-sm md:tabs-md tabs-boxed w-fit !rounded-3xl border-[0.1rem] bg-white">
      {tabs.map((tab) => (
        <div
          key={tab}
          onClick={() => onTabChange(tab)}
          className={`tab ${
            activeTab === tab &&
            "tab-active !rounded-2xl font-semibold !text-white"
          }`}
        >
          {tabLabels?.[tab] || tab}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
