import EmailVerify from "components/auth/EmailVerify";
import useAuth from "hooks/useAuth";
import SupportThread from "pages/support/Thread";
import ChatThread from "pages/thread";
import useUser from "queries/user";
import { Navigate, Outlet } from "react-router-dom";
import useThread from "stores/thread";
import BottomBar from "../BottomBar";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import useIsMobile from "hooks/useIsMobile";

export default function Main() {
  const { isAuth, isGuardian } = useAuth();
  const { engaged, unAccepted } = useUser();
  const th = useThread((s) => s.thread);
  const isMobile = useIsMobile();

  if (!isAuth) return <Navigate to="/login" />;
  else if (engaged) return <Navigate to="engaged" />;
  else if (unAccepted) return <Navigate to="/pending" />;
  else if (!unAccepted && isGuardian) return <Navigate to="/guardian" />;

  return (
    <div className="h-screen overflow-hidden">
      <Navbar />
      {!isMobile && <EmailVerify />}
      <div className="relative grid grid-cols-1 grid-rows-1 justify-center gap-8 md:my-4 md:grid-cols-[25%,65%] xl:md:grid-cols-[20%,65%]">
        <Sidebar />

        <div className="no-scrollbar h-screen overflow-auto border-gray-200 max-md:pb-10 md:h-[85vh] md:rounded-3xl md:border-[0.1rem] md:shadow-xl">
          {isMobile && <EmailVerify />}
          {th.visible && !th.fromSupport && <ChatThread />}
          {th.visible && th.fromSupport && <SupportThread />}
          <Outlet />
        </div>
      </div>
      <div className="md:hidden">
        <BottomBar />
      </div>
    </div>
  );
}
