import classNames from "classnames";
import Bio from "components/builder/utils/Bio";
import Paragraph from "components/builder/utils/Paragraph";
import StepsBuilder, { getStepsBuilder } from "components/builder/utils/Steps";
import constants from "configs/constants";
import { useState } from "react";
import { BuildContext, BuildMode, TUserBuilder } from "./utils/Steps";

interface Props {
  initUser?: TUserBuilder;
  gender: string;
  context: BuildContext;
  mode: BuildMode;
  onSubmit: (user: TUserBuilder) => void;
  loading?: boolean;
  initStep?: number;
  onPersist?: (user: TUserBuilder, step: number) => void;
}

const AccountBuilder = (params: Props) => {
  const { initUser, gender, loading, context, mode, onSubmit } = params;
  const stepsKeys = Object.keys(getStepsBuilder(context, mode));

  const [step, setStep] = useState(params.initStep || 1);
  const [user, setUser] = useState<TUserBuilder>(
    (initUser || {}) as TUserBuilder,
  );
  const isLastStep = step === stepsKeys.length + 1;
  const isProfile = context === "profile";
  const isUpdateMode = mode === "update";

  const bioKey = context === "profile" ? "bio" : "description";
  const pendingBioKey =
    context === "profile" ? "pending_bio" : "pending_description";

  const handleNext = () => {
    if (stepsKeys[step] === "children") setUser((u) => ({ ...u, children: 0 }));
    if (stepsKeys[step] === "profile_picture")
      setUser((u) => ({ ...u, profile_picture: "" }));

    if (isUpdateMode) {
      if (step < stepsKeys.length) setStep((s) => s + 1);
    } else setStep((s) => s + 1);
  };

  const handleBack = () => {
    setStep((s) => s - 1);
  };

  const submit = () => {
    onSubmit(user);
  };

  return (
    <div className="relative mx-auto flex min-h-screen max-w-2xl flex-col overflow-y-auto max-sm:mb-16">
      <div
        className={classNames({
          "ease transition-all duration-700": true,
          "max-h-0 overflow-hidden": isLastStep && !isUpdateMode,
          "max-h-max": !isLastStep && isUpdateMode,
        })}
      >
        <StepsBuilder
          context={context}
          gender={gender}
          mode={mode}
          next={handleNext}
          onPersist={params.onPersist}
          previous={handleBack}
          setUser={setUser as any}
          step={step}
          user={user}
        />
        <div className="m-10 mb-0 max-md:mx-2">
          <div className="flex flex-col">
            <span className="text-lg font-bold text-primary">
              {isProfile ? constants.MY_INFO : constants.MY_CONDITIONS}
              {mode === "update" && (
                <span className="text-xs text-info">
                  {` (${constants.EDIT_INFO_BY_PRESS})`}
                </span>
              )}
            </span>
            <div className="mx-2 mt-4 leading-7">
              <Paragraph
                mode={mode}
                as={context}
                step={step}
                setStep={setStep}
                user={user}
                gender={gender}
              />
            </div>
          </div>
        </div>
      </div>

      {(!!isLastStep || isUpdateMode) && (
        <Bio
          context={context}
          mode={mode}
          loading={loading}
          onSubmit={submit}
          goBack={!isUpdateMode ? handleBack : undefined}
          bio={(user[bioKey as keyof typeof user] as string) || ""}
          setBio={(text) => setUser({ ...user, [bioKey]: text })}
          pending={!!user[pendingBioKey as keyof typeof user]}
        />
      )}
    </div>
  );
};

export default AccountBuilder;
