const VERSION = 2;

const keys = [
  "about",
  "avatars",
  "contacts",
  "countries",
  "options",
  "privacy",
  "terms",
  "guide",
];

const versionChecker = () => {
  const version = localStorage.getItem("version");
  if (version !== VERSION.toString()) {
    keys.forEach((key) => localStorage.removeItem(key));
    localStorage.setItem("version", VERSION.toString());
  }
};

export default versionChecker;
